import React, { FC } from "react";
import { Member } from "../../types/member";
import Card from "../../components/Card";

interface MemberCardComponentProps {
  member: Member;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

const MemberCardComponent: FC<MemberCardComponentProps> = ({
  member,
  onClick,
}) => {
  const { first_name, last_name, profile_pic, write_up, teams } = member;

  return (
    <Card
      onClick={onClick}
      image={profile_pic?.url}
      imageAlt={`${first_name} ${last_name}`}
      title={`${first_name} ${last_name}`}
      subTitle={`Team: ${teams
        .map((teamMembership) => teamMembership.team.name)
        .join(", ")}`}
      description={write_up}
      imageBackground="bg-[#07B3CB]"
    />
  );
};

export default MemberCardComponent;
