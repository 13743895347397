import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { SelectOption } from "../../types/shared";
import { Member } from "../../types/member";
import MemberCardComponent from "./MemberCardComponent";
import MemberViewComponent from "./MemberViewComponent";
import { SingleValue } from "react-select";
import SingleSelect from "../../components/SingleSelect";
import NavigationContentContainer from "../../components/NavigationContentContainer";

interface MemberPageComponentProps {
  members: Member[];
  alias?: string;
  filter?: {
    subteam: {
      value: SingleValue<SelectOption<number, string>>;
      setValue: React.Dispatch<
        React.SetStateAction<SingleValue<SelectOption<number, string>>>
      >;
      options: SelectOption<number, string>[];
    };
    name: {
      value: string;
      setValue: React.Dispatch<React.SetStateAction<string>>;
    };
  };
  isPageReady: boolean;
}

const MemberPageComponent: FC<MemberPageComponentProps> = ({
  members,
  alias,
  filter,
  isPageReady,
}) => {
  const [selectedMember, setSelectedMember] = useState<Member | null>(null);
  const [keyword, setKeyword] = useState("");

  const debounceSearch = useMemo(() => {
    let timer: ReturnType<typeof setTimeout>;

    return (keyword: string) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        filter?.name.setValue(keyword);
      }, 500);
    };
  }, [filter]);

  useEffect(() => {
    debounceSearch(keyword);
  }, [debounceSearch, keyword]);

  return (
    <NavigationContentContainer
      header={alias || ""}
      isContentReady={isPageReady}
    >
      {filter && (
        <div className="flex items-center mt-4">
          <div className="mr-6 md:block hidden relative">
            <input
              placeholder="Search"
              type="text"
              className="rounded-[6px] border border-boxBorder focus:border-rose-500 w-[221px] h-[41px] px-6 pr-10"
              value={keyword}
              onChange={(e) => setKeyword(e.currentTarget.value)}
            />
            {filter.name.value ? (
              <FontAwesomeIcon
                icon={["fas", "x"]}
                className="absolute right-5 top-0 bottom-0 mx-0 my-auto text-red clickable"
                onClick={() => setKeyword("")}
              />
            ) : (
              <FontAwesomeIcon
                icon={["fas", "magnifying-glass"]}
                className="absolute right-5 top-0 bottom-0 mx-0 my-auto"
              />
            )}
          </div>
          <FontAwesomeIcon
            icon={["fas", "magnifying-glass"]}
            className="md:hidden mr-10"
          />
          <div className="mr-6 md:block hidden font-bold">
            {filter?.subteam && (
              <SingleSelect
                options={filter?.subteam.options}
                value={filter?.subteam.value}
                onChange={filter?.subteam.setValue}
              ></SingleSelect>
            )}
          </div>
        </div>
      )}
      <div className="grid gap-6 grid-cols-[repeat(auto-fit,_minmax(400px,_1fr))]">
        {isPageReady ? (
          members?.map((member) => (
            <MemberCardComponent
              key={member.id}
              member={member}
              onClick={() => setSelectedMember(member)}
            />
          ))
        ) : (
          <Skeleton count={5} width="80vw" />
        )}
        {selectedMember && (
          <MemberViewComponent
            member={selectedMember}
            onClose={() => setSelectedMember(null)}
          />
        )}
      </div>
    </NavigationContentContainer>
  );
};

MemberPageComponent.defaultProps = {
  alias: "Team Alias Placeholder",
};

export default MemberPageComponent;
