import React from "react";
import { useSearchParams } from "react-router-dom";
import { useGetPreviewQuery } from "../../services";
import { Project } from "../../types/project";
import { PagePreviewQueryParams } from "../../types/shared";
import ProjectPageComponent from "./ProjectPageComponent";

const ProjectPagePreview = () => {
  const [searchParams] = useSearchParams();
  let queryparams: PagePreviewQueryParams = {
    content_type: searchParams.get("content_type"),
    token: searchParams.get("token"),
  };

  const { data, isLoading, isFetching, isError } =
    useGetPreviewQuery(queryparams);

  return (
    <ProjectPageComponent
      project={data as Project}
      isPageReady={!isLoading && !isFetching}
      isError={isError}
    />
  );
};

export default ProjectPagePreview;
