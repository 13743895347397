import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  type: null,
  id: null,
};

const formSlice = createSlice({
  name: "form",
  initialState: initialState,
  reducers: {
    setForm: (state, action) => {
      state.type = action.payload;
    },
    setFormParams: (state, action) => {
      return (state = { ...state, id: action.payload.id });
    },
    purgeState: () => {
      return initialState;
    },
  },
});

const { setForm, setFormParams, purgeState } = formSlice.actions;

const formReducer = formSlice.reducer;

export { formReducer, setForm, setFormParams, purgeState };
