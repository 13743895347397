import React, { FunctionComponent } from "react";

import { useGetPagesQuery } from "../../services";
import {
  PageProps,
  PageResult,
  PagesQueryParams,
  PageTypes,
} from "../../types/shared";
import GroupsComponent from "./GroupsComponent";
import { Entity } from "../../types/entity";

const Groups: FunctionComponent<PageProps> = ({ entityId, preContent }) => {
  let params: PagesQueryParams = {
    url: "pages/",
    filter: { type: PageTypes.Entity, child_of: entityId },
  };

  const { data, error, isLoading, isSuccess, isFetching, isError } =
    useGetPagesQuery(params);

  return (
    <GroupsComponent
      data={data as PageResult<Entity>}
      error={error}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isFetching={isFetching}
      isError={isError}
      preContent={preContent}
    />
  );
};

export default Groups;
