import { Goal, GoalStatus } from "../../types/goal";
import { PageTypes } from "../../types/shared";

export const generateGoalModel: () => Goal = () => ({
  id: 0,
  meta: {
    type: PageTypes.Goal,
    detail_url: "",
    html_url: "",
    slug: "",
    show_in_menus: false,
    seo_title: "",
    search_description: "",
    first_published_at: "",
    alias_of: "",
    locale: "",
  },
  title: "",
  objectives: "",
  strategy: "",
  completion_rate: 0,
  subgoals: [],
  status: GoalStatus.NotStarted,
});
