import { Menu } from "@mantine/core";
import React, { FC, ReactNode } from "react";

interface MenuDropDownProps {
  Target: ReactNode;
  menuItems: {
    id: string;
    title: string;
    icon?: ReactNode;
    rightSection?: ReactNode;
    onClick: () => void;
    disabled?: boolean;
  }[];
}

const MenuDropDown: FC<MenuDropDownProps> = ({ Target, menuItems }) => (
  <Menu
    width={200}
    classNames={{
      item: "text-textPrimary font-nunitosans",
      divider: "border-[#E6F5F0]",
    }}
  >
    <Menu.Target>{Target}</Menu.Target>

    <Menu.Dropdown>
      {menuItems.map((menuItem, index) => {
        return (
          <React.Fragment key={menuItem.id}>
            <Menu.Item
              rightSection={menuItem.rightSection}
              icon={menuItem.icon}
              onClick={menuItem.onClick}
              disabled={menuItem.disabled}
            >
              {menuItem.title}
            </Menu.Item>
            {menuItems.length - 1 > index && <Menu.Divider />}
          </React.Fragment>
        );
      })}
    </Menu.Dropdown>
  </Menu>
);

export default MenuDropDown;
