import React, { Dispatch, SetStateAction, useState } from "react";
import NavigationContentContainer from "../../components/NavigationContentContainer";

import SingleSelect from "../../components/SingleSelect";
import { SingleValue } from "react-select";
import { QueryResult } from "../../types/shared";
import { Goal, GoalStatus, GoalSelectOption } from "../../types/goal";
import Skeleton from "react-loading-skeleton";
import { ErrorCode, ErrorResponse } from "../../types/error";
import GoalModal from "./GoalModal";
import ProgressBar from "../../components/ProgressBar";
import { generateGoalModel } from "./helper";

interface GoalsPageComponentProps extends QueryResult<Goal[]> {
  alias?: string;
  showFilter: boolean;
  statusFilter?: SingleValue<GoalSelectOption>;
  setStatusFilter?: Dispatch<SetStateAction<SingleValue<GoalSelectOption>>>;
}

const GoalsPageComponent = ({
  data: goals,
  error,
  isLoading,
  isSuccess,
  isFetching,
  isError,
  alias,
  statusFilter,
  setStatusFilter,
  showFilter,
}: GoalsPageComponentProps) => {
  const retrieving = isLoading || isFetching;
  const isPageReady = !retrieving && !isError && isSuccess;
  const [selectedGoal, setSelectedGoal] = useState<{
    open: boolean;
    goal: Goal;
  }>({
    open: false,
    goal: generateGoalModel(),
  });

  if (isError && !retrieving) {
    throw new ErrorResponse(JSON.stringify(error), ErrorCode.InternalError, "");
  }

  const options: GoalSelectOption[] = [
    { value: GoalStatus.All, label: GoalStatus.All },
    { value: GoalStatus.NotStarted, label: GoalStatus.NotStarted },
    { value: GoalStatus.InProgress, label: GoalStatus.InProgress },
    { value: GoalStatus.Completed, label: GoalStatus.Completed },
    { value: GoalStatus.Delayed, label: GoalStatus.Delayed },
  ];

  return (
    <>
      <NavigationContentContainer
        isContentReady={isPageReady}
        header={`${alias} Goals`}
      >
        <div className="mt-4 flex font-bold gap-5">
          {showFilter && (
            <div>
              <SingleSelect
                options={options}
                value={statusFilter}
                onChange={setStatusFilter}
              />
            </div>
          )}
          {/* <div className="flex items-center">Print {`${alias} Goals`}</div> */}
        </div>
        <div className="grid md:grid-cols-[repeat(auto-fill,_minmax(466px,_1fr))] grid-cols-1 gap-6 mt-10">
          {isPageReady ? (
            goals?.map((goal) => (
              <GoalComponent
                key={goal.id}
                goal={goal}
                onClick={() => {
                  setSelectedGoal({ goal, open: true });
                }}
              />
            ))
          ) : (
            <Skeleton />
          )}
        </div>
        {isPageReady && goals && goals.length === 0 && (
          <h1 className="flex justify-center items-center">No Data</h1>
        )}
      </NavigationContentContainer>
      <GoalModal
        goal={selectedGoal.goal}
        open={selectedGoal.open}
        onClose={(close) =>
          setSelectedGoal({ open: close, goal: generateGoalModel() })
        }
      />
    </>
  );
};

function GoalComponent({ goal, onClick }: { goal: Goal; onClick: () => void }) {
  return (
    <div
      key={goal.id}
      className="shadow-teamShadow rounded-[10px] h-[164px] flex clickable-scale"
      onClick={() => onClick()}
    >
      <div className="flex items-center w-[135px] justify-center">
        {goal.thumbnail && (
          <img
            className=""
            src={goal.thumbnail.meta.download_url}
            alt={goal.thumbnail.title}
          />
        )}
      </div>
      <div className="grid grid-rows-3 w-[336px]">
        <p className="font-bold flex width-[331px] row-span-2 line-clamp-3 p-4 overflow-hidden">
          {goal.title}
        </p>
        <div className="items-end pb-4 pl-4 gap-6 text-sm pt-4">
          <div className="grid md:grid-cols-8 grid-cols-3 md:gap-6">
            <div className="md:col-span-2 col-span-1">Completion:</div>
            <div className="md:col-span-2 col-span-1 flex justify-center">
              {`${goal.completion_rate} %`}
            </div>
            <div className="md:col-span-4 col-span-1 flex items-center pr-4">
              <ProgressBar completionRate={goal.completion_rate} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
GoalsPageComponent.defaultProps = {
  alias: "Team Alias Placeholder",
};
export default GoalsPageComponent;
