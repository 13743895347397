import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useGetProjectQuery } from "../../services";
import { ErrorCode, ErrorResponse } from "../../types/error";
import ProjectPageComponent from "./ProjectPageComponent";
import { pageReady } from "../../helper";
import { isErrorNotFound } from "../../helper";

const ProjectPage = () => {
  const params = useParams();
  const isDigit = /^\d+$/i;

  if (!isDigit.test(params.project || "")) {
    throw new ErrorResponse("Not Found", ErrorCode.NotFound, "Not Found");
  }

  const { data, ...projectQueryStatus } = useGetProjectQuery(
    parseInt(params.project as string)
  );

  if (isErrorNotFound(projectQueryStatus)) {
    throw new ErrorResponse("Not Found", ErrorCode.NotFound, "Not Found");
  }
  const isPageReady = useMemo(
    () => pageReady(projectQueryStatus),
    [projectQueryStatus]
  );

  const breadCrumbs = useMemo(
    () => ({
      data: [{ title: "Home", link: "/" }],
      ready: isPageReady,
    }),
    [isPageReady]
  );

  return (
    <ProjectPageComponent
      breadCrumbs={breadCrumbs}
      project={data}
      isPageReady={isPageReady}
      isError={projectQueryStatus.isError}
    />
  );
};

export default ProjectPage;
