import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: null,
};

const commentSlice = createSlice({
  name: "comment",
  initialState: initialState,
  reducers: {
    setEditing: (state, action) => {
      state.id = action.payload;
    },
  },
});

const { setEditing } = commentSlice.actions;

const commentReducer = commentSlice.reducer;

export { commentReducer, setEditing };
