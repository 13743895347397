import React from "react";
import { useSearchParams } from "react-router-dom";
import { PagePreviewQueryParams } from "../../types/shared";
import { useGetPreviewQuery } from "../../services";
import CustomPageComponent from "./CustomPageComponent";
import { CustomPageType } from "../../types/custom";

const CustomPagePreview = () => {
  const [searchParams] = useSearchParams();

  let queryParams: PagePreviewQueryParams = {
    content_type: searchParams.get("content_type"),
    token: searchParams.get("token"),
  };

  const { data, error, isFetching, isLoading, isSuccess, isError } =
    useGetPreviewQuery(queryParams);

  return (
    <CustomPageComponent
      data={data as CustomPageType | undefined}
      error={error}
      isFetching={isFetching}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
    />
  );
};

export default CustomPagePreview;
