import React, { useMemo, useState } from "react";
import { SingleValue } from "react-select";
import { PagePreviewQueryParams } from "../../types/shared";
import { useGetPreviewQuery } from "../../services";
import { Goal, GoalSelectOption, GoalStatus } from "../../types/goal";

import GoalsPageComponent from "./GoalsPageComponent";
import { useSearchParams } from "react-router-dom";

const GoalsPagePreview = () => {
  const [searchParams] = useSearchParams();
  let queryparams: PagePreviewQueryParams = {
    content_type: searchParams.get("content_type"),
    token: searchParams.get("token"),
  };

  const { data, error, isLoading, isSuccess, isFetching, isError } =
    useGetPreviewQuery(queryparams);

  const goals = useMemo(() => [data as Goal], [data]);

  const [statusFilter, setStatusFilter] = useState<
    SingleValue<GoalSelectOption>
  >({
    value: GoalStatus.NotStarted,
    label: GoalStatus.NotStarted,
  });

  return (
    <GoalsPageComponent
      data={goals}
      error={error}
      isFetching={isFetching}
      isLoading={isLoading}
      isError={isError}
      isSuccess={isSuccess}
      showFilter
      setStatusFilter={setStatusFilter}
      statusFilter={statusFilter}
    />
  );
};

export default GoalsPagePreview;
