import React, { ReactElement } from "react";
import Header from "./header";

interface LayoutProps {
  children: ReactElement;
}

const Layout = (props: LayoutProps) => (
  <>
    <Header />

    {props.children}
  </>
);

export default Layout;
