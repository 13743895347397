import React from "react";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import Button from "../../components/Button";
import useAuthenticate from "../PrivateRoute/useAuthenticate";
import NoProfile from "../../assets/images/no_profile.png";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { FormTypes } from "../../constants";
import { useDispatch } from "react-redux";
import { setForm } from "../../slices/form";
import { Link } from "react-router-dom";
import { Member } from "../../types/member";
import { useMemo } from "react";

interface ProfilePageComponentProps {
  members: Member[];
}

const ProfilePageComponent = ({ members }: ProfilePageComponentProps) => {
  const today = moment();
  const { data } = useAuthenticate();
  const dispatch = useDispatch();
  const quickActions = [
    {
      icon: "calendar-plus",
      link: "https://vela.opswerks.com/meetings/",
      label: "Schedule Meeting",
      shown: true,
    },
    {
      icon: "life-ring",
      link: "support-center/",
      label: "Support Center",
      shown: true,
    },
    {
      icon: "bars-progress",
      link: "https://vela.opswerks.com/home/",
      label: "Create Task",
      shown: true,
    },
    {
      icon: "plane-departure",
      link: "https://opswerks.bamboohr.com/time_off/requests/create",
      label: "File a Leave",
      shown: true,
    },
  ];
  const actions = [
    {
      label: "Contact IT Helpdesk",
      form: FormTypes.service,
    },
    {
      label: "Project Timelines",
      link: "/entities/4/timeline/",
    },
    {
      label: "File a Bug",
      form: FormTypes.report,
    },
  ];

  const profilePic = useMemo(() => {
    if (members.length && members[0].profile_pic) {
      return members[0].profile_pic.url;
    }

    return NoProfile;
  }, [members]);

  return (
    <div>
      <img
        src={profilePic}
        alt="profile pic"
        className="rounded-full border border-white h-36"
      />
      <div className="my-3">
        <div className="text-[40px]">Hi {data?.first_name}!</div>
        <div className="text-[16px]">Welcome to your WerkStation!</div>
      </div>
      <div className="flex my-5 items-start">
        {quickActions
          .filter((qa) => qa.shown)
          .map((qa) => (
            <Link
              key={qa.icon}
              to={qa.link}
              target="_blank"
              className="m-auto quick-action"
              data-tooltip-content={qa.label}
            >
              <Button styleProp="bg-white rounded-xl px-3 py-2 text-[#52A3EF] w-fit">
                <FontAwesomeIcon
                  icon={["fas", qa.icon as IconName]}
                  size="2x"
                />
              </Button>
            </Link>
          ))}
      </div>
      <div className="text-[16px] text-[#52A3EF] my-3">
        {actions.map((action) =>
          action.link ? (
            <Link key={action.label} to={action.link}>
              <Button styleProp="px-3 py-2 rounded-xl bg-white w-full my-2">
                <span className="float-left">{action.label}</span>
                <span className="float-right">
                  <FontAwesomeIcon icon={["fas", "arrow-right"]} />
                </span>
              </Button>
            </Link>
          ) : (
            <Button
              key={action.label}
              styleProp="px-3 py-2 rounded-xl bg-white w-full my-2"
              onClick={() => dispatch(setForm(action.form))}
            >
              <span className="float-left">{action.label}</span>
              <span className="float-right">
                <FontAwesomeIcon icon={["fas", "arrow-right"]} />
              </span>
            </Button>
          )
        )}
      </div>
      <div className="text-sm">
        Today is {today.format("MMMM D, YYYY")},{" "}
        <strong>{today.format("dddd")}</strong>
      </div>
      <Tooltip anchorSelect=".quick-action" />
    </div>
  );
};

export default ProfilePageComponent;
