import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import NavigationContentContainer from "../../components/NavigationContentContainer";
import ProgressBar from "../../components/ProgressBar";
import { ErrorCode, ErrorResponse } from "../../types/error";
import {
  Project,
  ProjectPhase,
  RoadMap,
  RoadmapStatus,
  ProjectRoadMapColumns,
} from "../../types/project";
import { Helmet } from "react-helmet-async";
import { BreadCrumbs, SelectOption } from "../../types/shared";
import Skeleton from "react-loading-skeleton";
import { SingleValue } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SingleSelect from "../../components/SingleSelect";
import { defaultHelmetTitle, months, quarters } from "../../constants";
import SanitizeHTML from "../../components/SanitizeHTML";
import { generateId } from "../../helper";
import MembersAvatar from "../../components/MembersAvatar";
import { Tooltip } from "@mantine/core";
import Comments from "../../components/Comments";
import YearQuarterButton from "../../components/YearQuarterButton";
import useScrollIntoYearQuarter from "../../hooks/useScrollIntoYearQuarter";

interface ProjectPageComponentProps {
  breadCrumbs?: BreadCrumbs;
  isPageReady: boolean;
  project: Project | undefined;
  isError: boolean;
}

const ProjectPageComponent = ({
  breadCrumbs,
  isPageReady,
  project,
  isError,
}: ProjectPageComponentProps) => {
  const projectTeams = useMemo(
    () => project?.project_teams.map((team) => team.entity.name).join(", "),
    [project?.project_teams]
  );

  const stakeholders = useMemo(
    () =>
      project?.stakeholders.map((stakeholder) => stakeholder.name).join(", "),
    [project?.stakeholders]
  );

  const sponsors = useMemo(
    () => project?.sponsors.map((sponsor) => sponsor.name).join(", "),
    [project?.sponsors]
  );

  const years: SelectOption<number, number>[] = useMemo(() => {
    if (project && isPageReady) {
      const minYr = Math.min(
        ...project.project_phases.map((phase) => phase.start_year)
      );
      const maxYr = Math.max(
        ...project.project_phases.map((phase) => phase.end_year)
      );

      return Array.from(
        { length: maxYr - minYr + 1 },
        (_, index) => minYr + index
      ).map((yr) => ({
        value: yr,
        label: yr,
      }));
    }

    return [];
  }, [project, isPageReady]);

  const [yearFilter, setYearFilter] = useState<
    SingleValue<SelectOption<number, number>>
  >(years[0]);
  const [keyword, setKeyword] = useState("");
  const roadmapStatus: ProjectRoadMapColumns[] = [
    { status: RoadmapStatus.Now, color: "nowColor" },
    { status: RoadmapStatus.Next, color: "nextColor" },
    { status: RoadmapStatus.Later, color: "laterColor" },
    { status: RoadmapStatus.Released, color: "releaseColor" },
  ];

  const filterProjectPhases = (
    phases: ProjectPhase[]
  ): ProjectPhase[] | void => {
    if (yearFilter) {
      const filteredPhases = phases.filter((phase) => {
        let inclusion =
          phase.start_year <= yearFilter.value &&
          phase.end_year >= yearFilter.value;

        if (keyword) {
          inclusion =
            inclusion &&
            (phase.name.includes(keyword) ||
              phase.features.some((feature) => feature.name.includes(keyword)));
        }

        return inclusion;
      });

      return filteredPhases;
    }
  };

  const filterRoadmaps = (
    roadmaps: RoadMap[],
    status: RoadmapStatus
  ): RoadMap[] | void => {
    const filtered = roadmaps.filter((roadmap) => roadmap.status === status);
    return filtered;
  };

  const {
    activeYearQuarter,
    setActiveYearQuarter,
    assignScrollRefs,
    scrollTargetRefs,
  } = useScrollIntoYearQuarter();

  useEffect(() => {
    if (years.length) {
      const currentYear = new Date().getFullYear();
      setYearFilter({ label: currentYear, value: currentYear });
    }
  }, [years]);

  if (isError) {
    throw new ErrorResponse("", ErrorCode.InternalError, "");
  }

  const breadCrumbsData = useMemo(
    () =>
      breadCrumbs && (
        <div className="lg:mx-44 md:mx-28 mx-4 px-2 mt-4">
          {!breadCrumbs.ready && <Skeleton width="30%" />}
          {breadCrumbs.ready && (
            <div className="flex gap-4">
              {breadCrumbs.data.map((elem) => (
                <div className="flex gap-4" key={generateId()}>
                  <Link className="clickable" to={elem.link}>
                    {elem.title}
                  </Link>
                  <div className="select-none">/</div>
                </div>
              ))}
              <div className="select-none">{project?.name}</div>
            </div>
          )}
        </div>
      ),
    [breadCrumbs, project?.name]
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{project?.title ?? defaultHelmetTitle}</title>
      </Helmet>
      {breadCrumbsData}
      <NavigationContentContainer isContentReady={isPageReady}>
        {project && (
          <div className="mx-auto">
            <div className="md:flex md:justify-between">
              <h1 className="md:w-[495px]">{project.name}</h1>
            </div>
            <div className="mt-10">
              <ProjectInfoComponent title="Completion:">
                <div className="grid md:grid-cols-8 grid-cols-4">
                  <div className="md:col-span-2 lg:col-span-1 col-span-1">
                    {project.completion_rate} %
                  </div>
                  <div className="flex items-center col-span-2">
                    <ProgressBar completionRate={project.completion_rate} />
                  </div>
                </div>
              </ProjectInfoComponent>
              <ProjectInfoComponent title="Tech Lead(s):">
                <MembersAvatar members={project.project_leads} />
              </ProjectInfoComponent>
              <ProjectInfoComponent title="Project Manager(s):">
                <MembersAvatar members={project.project_managers} />
              </ProjectInfoComponent>
              <ProjectInfoComponent title="Project Team:">
                {projectTeams}
              </ProjectInfoComponent>
              <ProjectInfoComponent title="Stakeholder:">
                {stakeholders}
              </ProjectInfoComponent>
              <ProjectInfoComponent title="Sponsor:">
                {sponsors}
              </ProjectInfoComponent>
            </div>
            <ProjectDescriptionComponent title="Objectives">
              <div className="wysiwyg-content">
                <SanitizeHTML html={project.objectives} />
              </div>
            </ProjectDescriptionComponent>
            <ProjectDescriptionComponent title="Strategy">
              {project.strategy}
            </ProjectDescriptionComponent>
            <ProjectDescriptionComponent
              className="hidden md:block"
              title="Project Timeline"
            >
              <div className="flex items-center mt-4">
                <div className="mr-6 md:block hidden relative">
                  <input
                    placeholder="Search"
                    type="text"
                    className="rounded-[6px] border border-boxBorder focus:border-rose-500 w-[221px] h-[41px] px-6 pr-10"
                    onKeyDown={(evt) => {
                      if (evt.code === "Enter") {
                        setKeyword((evt.target as HTMLInputElement).value);
                      }
                    }}
                  />
                  <FontAwesomeIcon
                    icon={["fas", "magnifying-glass"]}
                    className="absolute right-5 top-0 bottom-0 mx-0 my-auto"
                  />
                </div>
                <FontAwesomeIcon
                  icon={["fas", "magnifying-glass"]}
                  className="md:hidden mr-10"
                />

                {/* year quarter dropdown */}
                <div className="mr-[10px] md:block hidden font-bold z-[1000]">
                  {years && (
                    <SingleSelect
                      options={years}
                      value={yearFilter}
                      onChange={setYearFilter}
                    />
                  )}
                </div>

                {/* year quarter buttons */}
                <div className="flex gap-[10px]">
                  <YearQuarterButton
                    text="Q1"
                    isActive={activeYearQuarter === "Q1"}
                    onClick={() => setActiveYearQuarter("Q1")}
                  />
                  <YearQuarterButton
                    text="Q2"
                    isActive={activeYearQuarter === "Q2"}
                    onClick={() => setActiveYearQuarter("Q2")}
                  />
                  <YearQuarterButton
                    text="Q3"
                    isActive={activeYearQuarter === "Q3"}
                    onClick={() => setActiveYearQuarter("Q3")}
                  />
                  <YearQuarterButton
                    text="Q4"
                    isActive={activeYearQuarter === "Q4"}
                    onClick={() => setActiveYearQuarter("Q4")}
                  />
                </div>
              </div>

              {yearFilter && (
                <div
                  ref={assignScrollRefs}
                  className="grid grid-cols-5 grid-rows-[auto] mt-4 overflow-scroll bg-[#C1E7DC] max-h-[80vh]"
                >
                  <h6 className="bg-[white] sticky top-0 left-0 border-black border-dashed border-r-2 text-[#005F3E] text-center row-span-1 z-[100]">
                    Project Phases
                  </h6>

                  {/* quarter and months */}
                  <div className="col-span-4 sticky top-0">
                    <div className="grid grid-cols-[repeat(4,100%)]">
                      {quarters.map((q, idx) => (
                        <h6
                          key={q}
                          ref={scrollTargetRefs[idx]}
                          className="border-black border-dashed border-r-2 text-[#005F3E] text-center bg-white"
                        >
                          Q{q}-{yearFilter.value}
                        </h6>
                      ))}
                    </div>
                    <div className="grid grid-cols-[repeat(12,33.33%)]">
                      {months.map((month, idx) => (
                        <div
                          key={month}
                          className={`${
                            (idx + 1) % 3 === 0
                              ? "border-black border-dashed border-r-2"
                              : ""
                          } text-center w-full bg-white`}
                        >
                          {month}
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* project phase names */}
                  {filterProjectPhases(project.project_phases)?.map(
                    (phase, idx) => (
                      <div
                        key={phase.id}
                        className={`sticky left-0 z-10 items-center col-start-[1]`}
                        style={{ gridRowStart: 2 + idx }}
                      >
                        <div
                          className={`bg-white border border-l-4 border-l-[${
                            idx % 2 === 0 ? "#ACCEC4" : "#C1E7DC"
                          }] flex h-full items-center overflow-auto px-3 py-2`}
                        >
                          <div>
                            <div className="font-bold">{phase.name}</div>
                            <div className="flex">
                              <div className="whitespace-nowrap mr-2">
                                {phase.completion_rate}%
                              </div>
                              <div className="flex items-center w-[50px]">
                                <ProgressBar
                                  completionRate={phase.completion_rate}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}

                  {/* project phase feature names */}
                  {filterProjectPhases(project.project_phases)?.map(
                    (phase, idx) => (
                      <div
                        key={phase.id}
                        className={"col-span-4 items-center col-start-2"}
                        style={{ gridRowStart: 2 + idx }}
                      >
                        <div
                          className={`col-span-4 grid grid-cols-[repeat(12,33.33%)] gap-2 h-full items-center px-3 py-2`}
                        >
                          {phase.features
                            .filter(
                              (feature) =>
                                feature.start_year <= yearFilter.value &&
                                feature.end_year >= yearFilter.value
                            )
                            .map((feature) => (
                              <Tooltip
                                key={feature.id}
                                label={`${feature.name} - ${feature.completion_rate} %`}
                                withArrow
                                zIndex={0}
                                position="top-end"
                              >
                                <div
                                  className={`bg-white border col-start-${
                                    feature.start_year === yearFilter.value
                                      ? feature.start_month
                                      : 1
                                  } col-end-${
                                    feature.end_year === yearFilter.value
                                      ? feature.end_month + 1
                                      : 13
                                  } h-fit px-3 py-2 rounded-2xl overflow-auto`}
                                >
                                  <div className="font-bold line-clamp-1">
                                    {feature.name}
                                  </div>
                                  <div className="flex">
                                    <div className="mr-2">
                                      {feature.completion_rate}%
                                    </div>
                                    <div className="flex items-center w-[50px]">
                                      <ProgressBar
                                        completionRate={feature.completion_rate}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Tooltip>
                            ))}
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
            </ProjectDescriptionComponent>
            {project.roadmap.length > 0 && (
              <ProjectDescriptionComponent title="Product Roadmap">
                <div className="grid grid-cols-4 gap-[10px]">
                  {roadmapStatus.map((column: ProjectRoadMapColumns) => (
                    <div
                      key={column.status}
                      className="flex flex-col items-center gap-[10px]"
                    >
                      <div
                        className={`font-bold underline underline-offset-8 decoration-${column.color} mb-6`}
                      >
                        {column.status}
                      </div>
                      {filterRoadmaps(project.roadmap, column.status)?.map(
                        (item: RoadMap) => (
                          <div
                            key={item.feature_name}
                            className={`w-full items-center rounded-[6px] shadow-teamShadow clickable-scale border border-${column.color} p-6`}
                          >
                            {item.feature_name}
                          </div>
                        )
                      )}
                    </div>
                  ))}
                </div>
              </ProjectDescriptionComponent>
            )}
            <ProjectDescriptionComponent title="Tell us what you think. Leave a message.">
              <Comments type="project" id={project.id} />
            </ProjectDescriptionComponent>
            {/* <div className="mt-20">
              <h4>
                List of features
              </h4>
              <div className=" overflow-x-auto whitespace-nowrap scrollbar-thin scrollbar-thumb-textPrimary scrollbar-track-secondary scrollbar-thumb-rounded-[6px]">
                <div className="w-[890px] mb-2">
                  <h6 className="grid grid-cols-7 mt-4 bg-[#F3F3F3] py-[8px] px-[20px]">
                    <div>Feature</div>
                    <div>Status</div>
                    <div>Schedule</div>
                  </h6>
                  {project.features?.map((feature) => (
                    <div
                      key={feature.id}
                      className="grid grid-cols-7 mt-2 bg-[#F8F8F8] py-[8px] px-[20px] font-[16px]"
                    >
                      <div>{feature.name}</div>
                      <div>{feature.status}</div>
                      <div>{feature.schedule}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div> */}

            {/* <div className="mt-20">
              <div className="header text-[22px] text-textPrimary">
                Other Stats
              </div>
              <div className="mt-2">
                <img src={StatPlaceholder} alt="statistics" />
              </div>
            </div> */}
          </div>
        )}
      </NavigationContentContainer>
    </>
  );
};

const ProjectInfoComponent = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => (
  <div className="grid grid-cols-5 items-center">
    <div className="font-extralight md:col-span-1 col-span-2">{title}</div>
    <div className="md:col-span-3 col-span-3">{children}</div>
  </div>
);

const ProjectDescriptionComponent = ({
  title,
  children,
  className,
}: {
  title: string;
  children: ReactNode;
  className?: string;
}) => (
  <div className={`mt-6 ${className}`}>
    <h4>{title}</h4>
    <div className="font-montserrat mt-4">{children}</div>
  </div>
);

export default ProjectPageComponent;
