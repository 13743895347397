import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";

import ApplicationPage from "../ApplicationPage";
import ProfilePage from "../ProfilePage";

import ProfileBG from "../../assets/images/profile_bg.png";

import FaveAppsPage from "../FaveAppsPage";
import PopularServicesPage from "../PopularServicesPage";
import DashboardTabsSection from "./DashboardTabsSection";
import { defaultHelmetTitle } from "../../constants";

interface DashboardPageComponentProps {
  title?: string;
}

const DashboardPageComponent = (props: DashboardPageComponentProps) => {
  const allAppsRef = useRef<HTMLDivElement | null>(null);
  const [showApps, setShowApps] = useState(false);

  const scrollToAllApps = () => {
    allAppsRef.current?.scrollIntoView();
    setShowApps(true);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{props?.title || defaultHelmetTitle}</title>
      </Helmet>
      <div className="flex flex-col lg:mx-44 md:mx-28 mx-11 px-2 mt-8 gap-6 cursor-default">
        <div className="grid grid-cols-5 gap-6">
          <div
            className="bg-secondary rounded-lg p-10 lg:col-span-2 col-span-5"
            style={{ backgroundImage: `url(${ProfileBG})` }}
          >
            <ProfilePage />
          </div>
          <div className="bg-secondary rounded-lg p-10 lg:col-span-3 col-span-5 flex">
            <FaveAppsPage scrollToAllApps={scrollToAllApps} />
            <PopularServicesPage />
          </div>
        </div>
        <div className="grid p-10 grid-cols-1 bg-secondary rounded-lg">
          <DashboardTabsSection />
        </div>
        <div
          className="grid p-10 grid-cols-1 bg-secondary rounded-lg"
          ref={allAppsRef}
        >
          <ApplicationPage showApps={showApps} setShowApps={setShowApps} />
        </div>
      </div>
    </>
  );
};

export default DashboardPageComponent;
