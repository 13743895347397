import React, { FunctionComponent, useMemo, useState } from "react";
import { SingleValue } from "react-select";
import { PageProps, PagesQueryParams, PageTypes } from "../../types/shared";
import { useGetPagesQuery } from "../../services";
import { Goal, GoalSelectOption, GoalStatus } from "../../types/goal";

import GoalsPageComponent from "./GoalsPageComponent";

const GoalsPage: FunctionComponent<PageProps> = ({ entityId, alias }) => {
  const [statusFilter, setStatusFilter] = useState<
    SingleValue<GoalSelectOption>
  >({
    value: GoalStatus.All,
    label: GoalStatus.All,
  });

  let params: PagesQueryParams = {
    url: "pages/",
    filter: {
      type: PageTypes.Goal,
      child_of: entityId,
      ...(statusFilter &&
        statusFilter.value !== GoalStatus.All && {
          status: statusFilter.value,
        }),
    },
  };

  const { data, error, isLoading, isSuccess, isFetching, isError } =
    useGetPagesQuery(params);

  const goals = useMemo(() => data?.items as Goal[], [data]);

  return (
    <GoalsPageComponent
      data={goals}
      error={error}
      isFetching={isFetching}
      isLoading={isLoading}
      isError={isError}
      isSuccess={isSuccess}
      showFilter
      setStatusFilter={setStatusFilter}
      statusFilter={statusFilter}
      alias={alias}
    />
  );
};

export default GoalsPage;
