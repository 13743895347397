import moment from "moment";
import React, { useMemo } from "react";
import NoPostImage from "../../assets/images/no_post.png";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Post, PostCategory } from "../../types/post";

interface PostsCardProps {
  title: string;
  posts: Post[];
  className?: string;
  showThumbnail: boolean;
  isPageReady: boolean;
  isLoading: boolean;
  post_category: PostCategory;
}

const PostContent = ({
  post,
  showThumbnail,
}: {
  post: Post;
  showThumbnail: boolean;
}) => {
  const dateFormat = "MM/DD/YYYY";
  const publishedDate = useMemo(
    () => moment(post.meta.first_published_at).format(dateFormat),
    [post]
  );
  const modifiedDate = useMemo(() => {
    const lastPublishedDate = moment(post?.last_published_at);

    return (
      (post &&
        !moment(post.meta.first_published_at).isSame(lastPublishedDate) &&
        lastPublishedDate.format(dateFormat)) ||
      ""
    );
  }, [post]);

  return (
    <Link
      to={`posts/${post.id}`}
      className="flex gap-4 py-5 border-b-[#E6F5F0] border-b-2 mx-4 clickable-scale"
    >
      {showThumbnail && (
        <div className="hidden xl:block shrink-0">
          <img
            alt="post"
            src={post.thumbnail?.meta.download_url || NoPostImage}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = NoPostImage;
            }}
            className="w-[260px] h-[116px] object-contain"
          />
        </div>
      )}
      <div>
        <span className="text-base line-clamp-2 h-[52px]">{post.headline}</span>
        <div className="text-[#9EC7B9] text-sm flex gap-4 mt-2">
          <div>By: {post.author.first_name}</div>
          <div>
            {modifiedDate ? `Modified: ${modifiedDate}` : publishedDate}
          </div>
        </div>
        <div className="flex gap-2 mt-2">
          {post.tags.map((tag) => (
            <div
              key={tag}
              className="bg-secondary rounded-[6px] text-xs py-[6px] px-[12px]"
            >
              {tag}
            </div>
          ))}
        </div>
      </div>
    </Link>
  );
};

const PostsCard = ({
  title,
  posts,
  className,
  showThumbnail,
  isPageReady,
  isLoading,
  post_category,
}: PostsCardProps) => {
  return (
    <div
      className={`rounded-[10px] bg-white p-6 min-h-[527px] max-h-[527px] overflow-y-scroll ${className}`}
    >
      <h4 className="mx-4">{title}</h4>
      <div>
        {posts.map((post) => (
          <PostContent
            key={post.id}
            post={post}
            showThumbnail={showThumbnail}
          />
        ))}
      </div>
      {isPageReady && posts.length < 1 && (
        <h4 className="flex p-20 items-center justify-center">
          {`Sorry, there are currently no ${post_category.toLowerCase()} to display.`}
        </h4>
      )}
      {isLoading && <Skeleton count={10} />}
    </div>
  );
};

PostsCard.defaultProps = {
  showThumbnail: true,
};
export default PostsCard;
