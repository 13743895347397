import React, { ReactNode } from "react";

interface HeroSectionProps {
  header: ReactNode;
  body: ReactNode;
  footer?: ReactNode;
  image: { src?: string; title?: string };
}

const HeroSection = ({ header, body, image, footer }: HeroSectionProps) => (
  <div
    className={`grid ${
      image.src && "lg:grid-cols-10"
    } grid-cols-1 bg-secondary`}
  >
    <div className="grid lg:pl-44 md:pl-28 pl-11 lg:pr-14 md:pr-20 gap-8 pt-16 mr-11 md:col-span-7">
      <h1>{header}</h1>
      <div className="font-montserrat">{body}</div>
      <div className="mb-9">{footer}</div>
    </div>
    {image.src && (
      <div className="flex align-center col-span-3">
        <img
          className="lg:w-[auto] w-full lg:h-full h-[242px] object-cover  shadow-teamShadow"
          src={image.src}
          alt={image.title}
        />
      </div>
    )}
  </div>
);

export default HeroSection;
