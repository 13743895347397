import React, { useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  PageResult,
  PreContent,
  QueryResult,
  SelectOption,
} from "../../types/shared";
import NavigationContentContainer from "../../components/NavigationContentContainer";
import SanitizeHTML from "../../components/SanitizeHTML";
import { Project } from "../../types/project";
import SingleSelect from "../../components/SingleSelect";
import { SingleValue } from "react-select";
import ProgressBar from "../../components/ProgressBar";
import { months, quarters } from "../../constants";
import YearQuarterButton from "../../components/YearQuarterButton";
import useScrollIntoYearQuarter from "../../hooks/useScrollIntoYearQuarter";

const TimelinePageComponent = ({
  data,
  isLoading,
  isFetching,
  isSuccess,
  isError,
  preContent,
}: QueryResult<PageResult<Project & { link?: string }>> & {
  preContent?: PreContent;
}) => {
  const projects = data?.items;
  const isReady = !isError && !isLoading && !isFetching && isSuccess;

  const years: SelectOption<number, number>[] = useMemo(() => {
    if (projects && isReady) {
      const minYr = Math.min(
        ...projects
          .map((proj) => proj.project_phases.map((phase) => phase.start_year))
          .flat()
      );
      const maxYr = Math.max(
        ...projects
          .map((proj) => proj.project_phases.map((phase) => phase.end_year))
          .flat()
      );

      return Array.from(
        { length: maxYr - minYr + 1 },
        (_, index) => minYr + index
      ).map((yr) => ({ value: yr, label: yr }));
    }

    return [];
  }, [projects, isReady]);

  const [yearFilter, setYearFilter] = useState<
    SingleValue<SelectOption<number, number>>
  >(years[0]);
  const [keyword, setKeyword] = useState("");

  const {
    activeYearQuarter,
    setActiveYearQuarter,
    assignScrollRefs,
    scrollTargetRefs,
  } = useScrollIntoYearQuarter();

  const filterProjects = (projects: Project[]): Project[] | void => {
    if (yearFilter) {
      const filteredProjects = projects.filter((proj) =>
        proj.project_phases.some((phase) => {
          let inclusion =
            phase.start_year <= yearFilter.value &&
            phase.end_year >= yearFilter.value;

          if (keyword) {
            inclusion =
              inclusion &&
              (phase.name.includes(keyword) ||
                phase.features.some((feature) =>
                  feature.name.includes(keyword)
                ));
          }

          return inclusion;
        })
      );

      return filteredProjects;
    }
  };

  useEffect(() => {
    if (years.length) {
      const currentYear = new Date().getFullYear();
      setYearFilter({ label: currentYear, value: currentYear });
    }
  }, [years]);

  return (
    <NavigationContentContainer
      isContentReady={isReady}
      header={preContent ? preContent.header || "" : "Timeline"}
    >
      {preContent && (
        <>
          {isReady ? (
            <>
              <div className="my-16 text-justify font-montserrat wysiwyg-content">
                <SanitizeHTML html={preContent.description || ""} />
              </div>
              <h1>Timeline</h1>
            </>
          ) : (
            <Skeleton count={5} width="80vw" />
          )}
        </>
      )}
      <div className="flex items-center mt-4">
        <div className="mr-6 md:block hidden relative">
          <input
            placeholder="Search"
            type="text"
            className="rounded-[6px] border border-boxBorder focus:border-rose-500 w-[221px] h-[41px] px-6 pr-10"
            onKeyDown={(evt) => {
              if (evt.code === "Enter") {
                setKeyword((evt.target as HTMLInputElement).value);
              }
            }}
          />
          <FontAwesomeIcon
            icon={["fas", "magnifying-glass"]}
            className="absolute right-5 top-0 bottom-0 mx-0 my-auto"
          />
        </div>
        <FontAwesomeIcon
          icon={["fas", "magnifying-glass"]}
          className="md:hidden mr-10"
        />

        {/* year quarter dropdown */}
        <div className="mr-6 md:block hidden font-bold z-[1000]">
          {years && (
            <SingleSelect
              options={years}
              value={yearFilter}
              onChange={setYearFilter}
            />
          )}
        </div>

        {/* year quarter buttons */}
        <div className="flex gap-[10px]">
          <YearQuarterButton
            text="Q1"
            isActive={activeYearQuarter === "Q1"}
            onClick={() => setActiveYearQuarter("Q1")}
          />
          <YearQuarterButton
            text="Q2"
            isActive={activeYearQuarter === "Q2"}
            onClick={() => setActiveYearQuarter("Q2")}
          />
          <YearQuarterButton
            text="Q3"
            isActive={activeYearQuarter === "Q3"}
            onClick={() => setActiveYearQuarter("Q3")}
          />
          <YearQuarterButton
            text="Q4"
            isActive={activeYearQuarter === "Q4"}
            onClick={() => setActiveYearQuarter("Q4")}
          />
        </div>
      </div>

      {yearFilter && (
        <div
          ref={assignScrollRefs}
          className="grid grid-cols-5 grid-rows-[auto] mt-4 overflow-scroll bg-[#C1E7DC] max-h-[80vh]"
        >
          <h6 className="bg-[white] sticky top-0 left-0 border-black border-dashed border-r-2 text-[#005F3E] text-center row-span-1 z-[100]">
            Projects
          </h6>

          {/* quarter and months */}
          <div className="col-span-4 sticky top-0">
            <div className="grid grid-cols-[repeat(4,100%)]">
              {quarters.map((q, idx) => (
                <h6
                  key={q}
                  ref={scrollTargetRefs[idx]}
                  className="border-black border-dashed border-r-2 text-[#005F3E] text-center bg-white"
                >
                  Q{q}-{yearFilter.value}
                </h6>
              ))}
            </div>
            <div className="grid grid-cols-[repeat(12,33.33%)]">
              {months.map((month, idx) => (
                <div
                  key={month}
                  className={`${
                    (idx + 1) % 3 === 0
                      ? "border-black border-dashed border-r-2"
                      : ""
                  } text-center w-full bg-white`}
                >
                  {month}
                </div>
              ))}
            </div>
          </div>

          {projects && (
            <>
              {/* project names */}
              {filterProjects(projects)?.map((project, idx) => (
                <div
                  key={project.id}
                  className={"sticky left-0 z-10 items-center col-start-[1]"}
                  style={{ gridRowStart: 2 + idx }}
                >
                  <div
                    className={`bg-white border border-l-4 border-l-[${
                      idx % 2 === 0 ? "#ACCEC4" : "#C1E7DC"
                    }] flex h-full items-center overflow-auto px-3 py-2`}
                  >
                    <div>
                      <div className="font-bold">{project.name}</div>
                      <div className="flex">
                        <div className="whitespace-nowrap mr-2">
                          Completion: {project.completion_rate}%
                        </div>
                        <div className="flex items-center w-[50px]">
                          <ProgressBar
                            completionRate={project.completion_rate}
                          />
                        </div>
                      </div>
                      <div>
                        <span className="mr-2">Project Manager(s):</span>
                        {project.project_managers
                          .map(
                            (pm) =>
                              `${pm.member.first_name} ${pm.member.last_name}`
                          )
                          .join(", ")}
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {/* project phase names */}
              {filterProjects(projects)?.map((project, idx) => (
                <div
                  key={project.id}
                  className={"col-span-4 items-center col-start-2"}
                  style={{ gridRowStart: 2 + idx }}
                >
                  <div
                    className={`col-span-4 grid grid-cols-[repeat(12,33.33%)] gap-2 h-full items-center px-3 py-2`}
                  >
                    {project.project_phases
                      .filter(
                        (phase) =>
                          phase.start_year <= yearFilter.value &&
                          phase.end_year >= yearFilter.value
                      )
                      .map((phase) => (
                        <div
                          key={phase.id}
                          className={`bg-white border col-start-${
                            phase.start_year === yearFilter.value
                              ? phase.start_month
                              : 1
                          } col-end-${
                            phase.end_year === yearFilter.value
                              ? phase.end_month + 1
                              : 13
                          } h-fit px-3 py-2 rounded-2xl overflow-auto`}
                        >
                          <div className="font-bold  line-clamp-1">
                            {phase.name}
                          </div>
                          <div className="flex">
                            <div className="mr-2">{phase.completion_rate}%</div>
                            <div className="flex items-center w-[50px]">
                              <ProgressBar
                                completionRate={phase.completion_rate}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      )}
    </NavigationContentContainer>
  );
};

export default TimelinePageComponent;
