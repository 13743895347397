import React, { useEffect, useState } from "react";
import { FormTypes } from "../../constants";
import { useDispatch } from "react-redux";
import Button from "../../components/Button";
import { setForm } from "../../slices/form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CustomFilterType,
  FilterAssignee,
  FilterButtons,
  FilterStatus,
  MemberFilter,
} from "../../types/serviceRequest";
import { Menu } from "@mantine/core";

interface ServiceRequestFiltersProps {
  all: number;
  requests: number;
  bug: number;
  mentions: number;
  membersFilter: MemberFilter[];
  onButtonFilter: (serviceType: FilterButtons) => void;
  onCustomFilter: (filter: CustomFilterType) => void;
  assigness: string[];
}

const ServiceRequestFilters: React.FC<ServiceRequestFiltersProps> = ({
  all,
  requests,
  bug,
  mentions,
  membersFilter,
  onButtonFilter,
  onCustomFilter,
  assigness = [],
}) => {
  const dispatch = useDispatch();
  const [opened, setOpened] = useState(false);
  const [appliedFilter, setAppliedFilter] = useState<FilterButtons>(
    FilterButtons.All
  );
  const [members, setMembers] = useState<MemberFilter[]>([]);

  const initialFilters = {
    filedBy: "All",
    serviceType: FilterButtons.All,
    assignedTo: FilterAssignee.All,
    status: FilterStatus.Open,
  };

  const [customFilters, setCustomFilters] =
    useState<CustomFilterType>(initialFilters);

  const [customFilterText, setCustomFilterText] =
    useState<CustomFilterType>(initialFilters);

  const handleFilter = (filter: FilterButtons) => {
    setAppliedFilter(filter);
    onButtonFilter(filter);
    setCustomFilters({ ...customFilters, serviceType: filter });
    setCustomFilterText({ ...customFilterText, serviceType: filter });
  };
  const filterButtons = [
    { id: FilterButtons.All, text: "All Activities", count: all },
    {
      id: FilterButtons.Requests,
      text: "Requests",
      count: requests,
    },
    {
      id: FilterButtons.BugReports,
      text: "Bug Reports",
      count: bug,
    },
  ];

  useEffect(() => {
    if (membersFilter) {
      membersFilter.sort((a, b) => {
        if (a.filed_by === undefined) return -1;
        if (b.filed_by === undefined) return 1;
        return a.filed_by.localeCompare(b.filed_by);
      });
      setMembers(membersFilter);
    }
  }, [membersFilter]);

  const Members = () => (
    <Menu.Dropdown className="w-[160px] max-h-[280px] overflow-y-auto">
      <Menu.Item
        className="w-[150px]"
        onClick={() =>
          setCustomFilterText((prevFilter) => ({
            ...prevFilter,
            filedBy: "All",
            user: undefined,
          }))
        }
      >
        All
      </Menu.Item>
      {members
        ? members.map((member) => {
            if (!member.id) return null;
            return (
              <Menu.Item
                key={member.id}
                className="w-[150px]"
                onClick={() =>
                  setCustomFilterText((prevFilter) => ({
                    ...prevFilter,
                    filedBy: member.filed_by,
                    user: member.id,
                  }))
                }
              >
                {member.filed_by}
              </Menu.Item>
            );
          })
        : null}
    </Menu.Dropdown>
  );
  const FILTERS_TO_EXCLUDE = [
    FilterStatus.Closed,
    FilterStatus.Cancelled,
    FilterStatus.Shipped,
  ];
  const Filters = ({
    entries,
    name,
  }: {
    entries: Record<string, FilterButtons | FilterStatus> | string[];
    name: string;
  }) => (
    <Menu.Dropdown>
      {Object.entries(entries)
        .filter(
          ([key, value]) => !FILTERS_TO_EXCLUDE.includes(value as FilterStatus)
        )
        .map(([key, filter]) => (
          <Menu.Item
            className="w-[150px]"
            key={key}
            onClick={() =>
              setCustomFilterText((prevFilter) => ({
                ...prevFilter,
                [name]: filter,
              }))
            }
          >
            {filter}
          </Menu.Item>
        ))}
    </Menu.Dropdown>
  );

  const CustomFilter = () => (
    <Menu
      classNames={{
        item: "text-textPrimary font-nunitosans",
        divider: "border-[#E6F5F0]",
      }}
      opened={opened}
      onChange={setOpened}
    >
      <Menu.Target>
        <div className="flex items-center gap-x-3 border-[1px] border-[#F4F4F4] py-3 px-4 rounded-[6px] cursor-pointer ml-7">
          <FontAwesomeIcon className="text-[14px]" icon={["fas", "sliders"]} />
          <div className="rounded-[6px] bg-[#F4F4F4] fw-300 text-xs py-1 px-2">
            Status:{" "}
            <span className="text-blueJeans">{customFilters.status}</span>
          </div>
          <div className="rounded-[6px] bg-[#F4F4F4] fw-300 text-xs py-1 px-2">
            Filed by:{" "}
            <span className="text-blueJeans">{customFilters.filedBy}</span>
          </div>
          <div className="rounded-[6px] bg-[#F4F4F4] fw-300 text-xs py-1 px-2">
            Assignee:{" "}
            <span className="text-blueJeans">{customFilters.assignedTo}</span>
          </div>
          <div className="rounded-[6px] bg-[#F4F4F4] fw-300 text-xs py-1 px-2">
            Service Type:{" "}
            <span className="text-blueJeans">{customFilters.serviceType}</span>
          </div>
        </div>
      </Menu.Target>

      <Menu.Dropdown>
        <div className="p-4 flex-row">
          <p className="font-bold text-xs text-[#333333] mb-2">
            Select Filters
          </p>
          <div className="border-[1px] border-[#EFEFEF] flex rounded-[6px] mb-2">
            <span className=" w-[120px] font-thin border-r-[1px] border-r-[1px] p-3 py-2 text-[14px]">
              Status
            </span>
            <Menu>
              <Menu.Target>
                <div className="w-[160px] flex justify-between items-center px-3 text-[14px] cursor-pointer">
                  <span>{customFilterText.status}</span>
                  <FontAwesomeIcon
                    className="pt-[3px] text-[14px]"
                    icon={["fas", "chevron-down"]}
                  />
                </div>
              </Menu.Target>
              <Filters entries={FilterStatus} name={"status"} />
            </Menu>
          </div>
          <div className="border-[1px] border-[#EFEFEF] flex rounded-[6px] mb-2">
            <span className=" w-[120px] font-thin border-r-[1px] border-r-[1px] p-3 py-2 text-[14px]">
              Filed by
            </span>
            <Menu>
              <Menu.Target>
                <div className="w-[160px] flex justify-between items-center px-3 text-[14px] cursor-pointer">
                  <span>{customFilterText.filedBy}</span>
                  <FontAwesomeIcon
                    className="pt-[3px] text-[14px]"
                    icon={["fas", "chevron-down"]}
                  />
                </div>
              </Menu.Target>
              <Members />
            </Menu>
          </div>
          <div className="border-[1px] border-[#EFEFEF] flex rounded-[6px] mb-2">
            <span className=" w-[120px] font-thin border-r-[1px] border-r-[1px] p-3 py-2 text-[14px]">
              Assigned to
            </span>
            <Menu>
              <Menu.Target>
                <div className="w-[160px] flex justify-between items-center px-3 text-[14px] cursor-pointer">
                  <span>{customFilterText.assignedTo}</span>
                  <FontAwesomeIcon
                    className="pt-[3px] text-[14px]"
                    icon={["fas", "chevron-down"]}
                  />
                </div>
              </Menu.Target>
              <Filters entries={assigness} name={"assignedTo"} />
            </Menu>
          </div>
          <div className="border-[1px] border-[#EFEFEF] flex rounded-[6px] mb-2">
            <span className=" w-[120px] font-thin border-r-[1px] border-r-[1px] p-3 py-2 text-[14px]">
              Service Type
            </span>
            <Menu>
              <Menu.Target>
                <div className="w-[160px] flex justify-between items-center px-3 text-[14px] cursor-pointer">
                  <span>{customFilterText.serviceType}</span>
                  <FontAwesomeIcon
                    className="pt-[3px] text-[14px]"
                    icon={["fas", "chevron-down"]}
                  />
                </div>
              </Menu.Target>
              <Filters entries={FilterButtons} name={"serviceType"} />
            </Menu>
          </div>
          <div className="flex flex-row-reverse">
            <Button
              styleProp="rounded-md px-6 py-3 bg-primary text-[12px] text-white disabled:text-[#B5B5B5] disabled:bg-[#D9D9D9] flex text-center gap-x-3"
              onClick={() => {
                onCustomFilter(customFilterText);
                setAppliedFilter(customFilterText.serviceType);
                setCustomFilters(customFilterText);
                setOpened(false);
              }}
            >
              Apply
            </Button>
            <Button
              styleProp="rounded-md px-6 py-3 text-[12px] text-[#DC2863] font-bold"
              onClick={() => {
                onCustomFilter(initialFilters);
                setCustomFilters(initialFilters);
                setAppliedFilter(initialFilters.serviceType);
                setCustomFilterText(initialFilters);
                setOpened(false);
              }}
            >
              Reset to default
            </Button>
          </div>
        </div>
      </Menu.Dropdown>
    </Menu>
  );

  const CreateButton = () => (
    <Menu
      position="bottom-end"
      classNames={{
        dropdown: "bg-none shadow-none border-none p-0",
      }}
    >
      <Menu.Target>
        <button className="rounded-md px-6 py-3 bg-primary text-[14px] text-white font-bold flex item-center gap-x-3">
          Create
          <FontAwesomeIcon
            className="pt-[5px] text-[14px]"
            icon={["fas", "chevron-down"]}
          />
        </button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          rightSection={
            <FontAwesomeIcon
              className="pt-[3px] ml-2 text-[14px]"
              icon={["fas", "receipt"]}
            />
          }
          className="rounded-md bg-primary text-white hover:bg-primary"
          onClick={() => dispatch(setForm(FormTypes.idea))}
        >
          Service Request
        </Menu.Item>
        <Menu.Item
          rightSection={
            <FontAwesomeIcon
              className="pt-[3px] ml-2 text-[14px]"
              icon={["fas", "bug"]}
            />
          }
          className="rounded-md mt-2 bg-primary text-white hover:bg-primary "
          onClick={() => dispatch(setForm(FormTypes.report))}
        >
          Bug Report
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
  return (
    <div>
      <div className="flex justify-between">
        <div className="flex items-center">
          {filterButtons.map((filter) => (
            <Button
              key={filter.id}
              onClick={() => handleFilter(filter.id)}
              styleProp={`flex items-center py-3 px-4 justify-center gap-x-3  clickable-scale rounded-[6px] ${
                appliedFilter === filter.id ? "bg-[#333333] text-white" : ""
              }`}
            >
              <span>{filter.text}</span>
              <span>{filter.count}</span>
            </Button>
          ))}

          <CustomFilter />
        </div>

        <div className="flex items-center">
          <CreateButton />
        </div>
      </div>
    </div>
  );
};

export default ServiceRequestFilters;
