import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import { FormTypes } from "../../constants";
import { setForm } from "../../slices/form";

const PopularServicesPageComponent = () => {
  const dispatch = useDispatch();
  const popularServices = [
    {
      title: "Avail of our Services",
      description:
        "Let us know what you think will improve our products and services",
      form: FormTypes.idea,
    },
    {
      title: "Report a Bug",
      description:
        "Found a show-stopper? Let’s Werk together immediately to resolve it!",
      form: FormTypes.report,
    },
    {
      title: "Read our blogs",
      description:
        "Interested in expanding your skillset? Have fun learning with us!",
      link: "/",
    },
  ];

  return (
    <div className="w-3/5">
      <div className="font-normal text-[16px] w-full mb-5">
        Popular Services
      </div>
      <div className="grid grid-cols-1 gap-4 float-left my-2">
        {popularServices.map((svc) =>
          svc.link ? (
            <Link to={svc.link} key={svc.title}>
              <Button styleProp="bg-white rounded-xl text-left w-full min-h-[7rem]">
                <div className="text-[16px]">{svc.title}</div>
                <div className="text-[14px]">{svc.description}</div>
              </Button>
            </Link>
          ) : (
            <Button
              styleProp="bg-white rounded-xl text-left w-full min-h-[7rem]"
              onClick={() => dispatch(setForm(svc.form))}
              key={svc.title}
            >
              <div className="text-[16px]">{svc.title}</div>
              <div className="text-[14px]">{svc.description}</div>
            </Button>
          )
        )}
      </div>
    </div>
  );
};

export default PopularServicesPageComponent;
