import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { CustomPageType } from "./custom";
import { Entity } from "./entity";
import { Goal } from "./goal";
import { Member } from "./member";
import { Project } from "./project";
import { Application } from "./application";
import React from "react";
import { ViewModes } from "./dashboard";
import { Post, PostCategory } from "./post";
import { CustomCardPageType } from "./customCardPage";

export type ValueOf<T> = T[keyof T];

export interface FilterPageQueryParam {
  type?: PageTypes | PostCategory;
  [key: string]: any;
}

export interface PagePreviewQueryParams {
  content_type: string | null;
  token: string | null;
}

export interface PagesQueryParams {
  url: string;
  filter?: FilterPageQueryParam;
}

export interface PageResult<T> {
  meta: {
    total_count: number;
  };
  items: T[];
}
//Abstracted Type of Page
export type Page =
  | Member
  | Entity
  | Goal
  | Project
  | CustomPageType
  | Application
  | Post
  | CustomCardPageType;

export enum PageTypes {
  Entity = "entity.entity",
  Goal = "entity.goal",
  Project = "entity.project",
  Custom = "entity.custompage",
  Application = "entity.application",
  CustomCardPage = "entity.CustomCardPage",
  Cards = "entity.Cards",
}

export type QueryResult<T> = {
  data: T | undefined;
  error?: FetchBaseQueryError | SerializedError;
  isLoading: boolean;
  isFetching: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errors?: FetchBaseQueryError[] | SerializedError[];
};

export enum MenuPath {
  Teams = "teams",
  Members = "members",
  Goals = "goals",
  Projects = "projects",
  Timeline = "timeline",
}

export enum MenuItemType {
  Teams = "Teams",
  Members = "Members",
  Projects = "Projects",
  Goals = "Goals",
  Custom = "Custom",
  Timeline = "Timeline",
  CustomCard = "CustomCard",
}

export interface WagtailImage {
  id: number;
  meta: {
    type: "wagtailimages.Image";
    detail_url: string;
    download_url: string;
  };
  title: string;
}

export enum GroupType {
  Department = "Department",
  Division = "Division",
  Group = "Group",
  Subgroup = "Subgroup",
}

export interface UserInfo {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  warn_after: number;
  expire_after: number;
}

export interface SelectOption<V, L> {
  value: V;
  label: L;
}

export interface SerializedImage {
  url?: string;
  title?: string;
  width: number;
  height: number;
}

export const YesNo = {
  Yes: true,
  No: false,
} as const;

export type YesNoSelectOption = SelectOption<boolean, string>;

export const YesNoOption: YesNoSelectOption[] = [
  { value: YesNo.Yes, label: "Yes" },
  { value: YesNo.No, label: "No" },
];

export interface PreContent {
  header?: string | null;
  description?: string | null;
}

export interface PageProps {
  entityId?: number;
  alias?: string;
  preContent?: PreContent;
  url_path?: string;
}

export interface BreadCrumb {
  title: string;
  link: string;
}

export interface BreadCrumbs {
  data: BreadCrumb[];
  ready: boolean;
}

export interface FormProps {
  close: Function;
}

export interface ConsentProps {
  agree: boolean;
}
export interface TabComponentProps {
  viewMode: ViewModes;
}

export interface DashboardTabs {
  title: string;
  id: string;
  TabComponent: React.FunctionComponent<TabComponentProps>;
  defaultViewMode: ViewModes;
  show: boolean;
  link?: string;
}
