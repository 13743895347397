import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetPreviewQuery } from "../../services";
import { Project } from "../../types/project";
import { PagePreviewQueryParams } from "../../types/shared";
import ProjectsPageComponent from "./ProjectsPageComponent";

const ProjectsPreviewPage = () => {
  const [searchParams] = useSearchParams();

  let queryparams: PagePreviewQueryParams = useMemo(
    () => ({
      content_type: searchParams.get("content_type"),
      token: searchParams.get("token"),
    }),
    [searchParams]
  );

  const { data, isLoading, isSuccess, isFetching, isError } =
    useGetPreviewQuery(queryparams);

  const previewedProject = data as Project;

  const projects = useMemo(
    () =>
      (previewedProject && [
        {
          ...previewedProject,
          link: `/draft/preview/project/?content_type=${queryparams.content_type}&token=${queryparams.token}`,
        },
      ]) ||
      [],
    [previewedProject, queryparams]
  );

  return (
    <ProjectsPageComponent
      isLoading={isLoading}
      isSuccess={isSuccess}
      isFetching={isFetching}
      isError={isError}
      data={projects}
    />
  );
};

export default ProjectsPreviewPage;
