import React from "react";
import { ViewModes } from "../../types/dashboard";

export default function TabSectionContentComponent<T>({
  components,
  viewMode,
}: {
  components: { Component: React.FunctionComponent<T>; props: T }[];
  viewMode: ViewModes;
}) {
  // we need to set the base grid then set the grid per child
  let parentClasses = {
    [ViewModes.OneTwo]: "md:grid-cols-3",
    [ViewModes.OneTwoStacked]: "md:grid-rows-2 md:grid-cols-3",
    [ViewModes.Even]: "md:grid-cols-3",
  };
  let parentClass = components.length === 1 ? "" : parentClasses[viewMode];

  const getChildClass = (index: number, viewMode?: ViewModes | null) => {
    const incrementedIndex = index + 1;
    switch (viewMode) {
      case ViewModes.OneTwo:
        return incrementedIndex % 2 === 0 ? "md:col-span-1" : "md:col-span-2";

      case ViewModes.OneTwoStacked:
        return incrementedIndex % 3 === 1
          ? "md:row-span-2 md:col-span-1 md:min-h-[100%] md-max-h-[100%]"
          : "md:row-span-1 md:col-span-2  md:min-h-[100%] md-max-h-[100%]";
      default:
        return "";
    }
  };

  return (
    <div className={`grid grid-cols-1 gap-4 ${parentClass}`}>
      {components.map((component, index) => {
        let childClassName = getChildClass(
          index,
          components.length > 1 ? viewMode : null
        );

        return (
          <component.Component
            key={index}
            {...component.props}
            className={childClassName}
          />
        );
      })}
    </div>
  );
}
