import React from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import SanitizeHTML from "../../components/SanitizeHTML";

interface ConsolidatedPage {
  id: number;
  title: string;
  description?: string;
  objectives?: string;
  content?: string;
}

interface GlobalSearchComponentProps {
  data?: any;
  isReady: boolean;
}

const GlobalSearchComponent = ({
  data,
  isReady,
}: GlobalSearchComponentProps) => {
  const { keyword } = useSelector((state: RootState) => state.search);

  const generate_link = (hit: any) => {
    if (hit.content_type && hit.content_type === "customcardpage") {
      return `/entities/${hit.entity}/${hit.url_path_name}`;
    } else if (hit.content_type && hit.content_type === "custompage") {
      return `/entities/${hit.entity}/${hit.url_path_name}`;
    } else if (hit.content_type && hit.content_type === "entity") {
      return `/entities/${hit.id}`;
    } else if (hit.content_type && hit.content_type === "goal") {
      return `/entities/${hit.entity}/goals`;
    } else if (hit.content_type && hit.content_type === "project") {
      return `/projects/${hit.id}`;
    } else if (hit.content_type && hit.content_type === "post") {
      return `/posts/${hit.id}`;
    }

    return hit.link;
  };

  return (
    <div>
      <div className="py-4 shadow-md w-full">
        <div className="flex gap-2 lg:mx-44 md:mx-28 mx-11 px-2">
          <div className="flex gap-2">
            <a className="clickable" href="/">
              Home
            </a>
            <div className="select-none">/</div>
          </div>
          <div>Search</div>
        </div>
      </div>
      <div className="flex flex-col lg:mx-44 md:mx-28 mx-11 px-2 mt-8 gap-6 cursor-default">
        {isReady ? (
          data?.length ? (
            <div>
              <div className="text-[22px] font-bold mb-5">{`Search Results for "${keyword}"`}</div>
              {data.map((hit: ConsolidatedPage) => (
                <div className="mb-5" key={hit.id}>
                  <a href={generate_link(hit)}>
                    <div className="underline text-[22px] font-bold">
                      {hit.title}
                    </div>
                    <SanitizeHTML
                      html={
                        hit.content || hit.objectives || hit.description || ""
                      }
                    />
                  </a>
                </div>
              ))}
            </div>
          ) : (
            <div>
              <div className="text-[22px] font-bold mb-5">{`No Search Results for "${keyword}" please try a different keyword`}</div>
              <div className="mb-5">
                Make sure that you are typing your keywords correctly.
              </div>
              <div className="mb-5">
                Or you may go back to the{" "}
                <a href="/">
                  <span className="underline">Homepage</span>
                </a>
                .
              </div>
            </div>
          )
        ) : (
          <Skeleton count={5} width="80vw" />
        )}
      </div>
    </div>
  );
};

export default GlobalSearchComponent;
