import React from "react";
import { Avatar, Tooltip } from "@mantine/core";
import MemberAvatar from "./MemberAvatar";
import { Member } from "../types/member";
interface MembersAvatarProps {
  members: { member: Member; id: number }[];
}

const MembersAvatar = ({ members }: MembersAvatarProps) => {
  return members.length > 0 && members.length === 1 ? (
    members.map(
      (memberObj) =>
        memberObj.member && (
          <MemberAvatar
            key={`${memberObj.member.first_name}_${memberObj.member.last_name}`}
            member={memberObj.member}
          />
        )
    )[0]
  ) : (
    <Tooltip.Group openDelay={300} closeDelay={100}>
      <Avatar.Group spacing="sm">
        {members.map((memberObj) => (
          <Tooltip
            label={`${memberObj.member.first_name} ${memberObj.member.last_name}`}
            withArrow
            key={memberObj.id}
          >
            <Avatar
              imageProps={{
                className: "object-contain",
              }}
              size="sm"
              radius="xl"
              src={memberObj.member.profile_pic?.url}
              alt={memberObj.member.profile_pic?.title}
            />
          </Tooltip>
        ))}
      </Avatar.Group>
    </Tooltip.Group>
  );
};

export default MembersAvatar;
