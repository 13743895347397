import React from "react";
import { useSearchParams } from "react-router-dom";
import { PagePreviewQueryParams } from "../../types/shared";
import { useGetPreviewQuery } from "../../services";
import CustomCardPageComponent from "./CustomCardPageComponent";
import { isErrorNotFound, pageReady } from "../../helper";
import { ErrorCode, ErrorResponse } from "../../types/error";
import { CustomCardPageType } from "../../types/customCardPage";

const CustomCardPagePreview = () => {
  const [searchParams] = useSearchParams();

  let queryParams: PagePreviewQueryParams = {
    content_type: searchParams.get("content_type"),
    token: searchParams.get("token"),
  };

  const { data, ...pagesStatus } = useGetPreviewQuery(queryParams);

  const isPageReady = pageReady(pagesStatus);
  const basePage = data as CustomCardPageType;

  if (isPageReady && isErrorNotFound(pagesStatus)) {
    throw new ErrorResponse("", ErrorCode.NotFound, "");
  }

  const cards = basePage?.cards.map(
    ({ id, title, description, link, thumbnail }) => ({
      id,
      title,
      description,
      link,
      thumbnail: {
        url: thumbnail?.meta?.download_url,
        title: thumbnail?.title,
        width: 0,
        height: 0,
      },
      custom_page: basePage?.id,
    })
  );
  return (
    <CustomCardPageComponent
      basePage={basePage}
      cards={cards}
      isPageReady={isPageReady}
      isError={pagesStatus.isError}
      shouldShowFilter={false}
    />
  );
};

export default CustomCardPagePreview;
