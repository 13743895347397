import React from "react";
import { Member } from "../types/member";
import { Avatar } from "@mantine/core";
interface MemberAvatarProps {
  member: Member;
}

const MemberAvatar = ({ member }: MemberAvatarProps) => {
  return (
    <div className="flex gap-2 items-center" key={member.id}>
      <Avatar
        imageProps={{
          className: "object-contain",
        }}
        size="sm"
        radius="xl"
        src={member.profile_pic?.url}
        alt={member.profile_pic?.title}
      />
      <div className="whitespace-nowrap overflow-hidden text-ellipsis">
        {member.first_name} {member.last_name}
      </div>
    </div>
  );
};

export default MemberAvatar;
