import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  keyword: null,
  page: 1,
  size: 10,
};

const searchSlice = createSlice({
  name: "search",
  initialState: initialState,
  reducers: {
    setKeyword: (state, action) => {
      state.keyword = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSize: (state, action) => {
      state.size = action.payload;
    },
  },
});

const { setKeyword, setPage, setSize } = searchSlice.actions;

const searchReducer = searchSlice.reducer;

export { searchReducer, setKeyword, setPage, setSize };
