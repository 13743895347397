import { PageTypes, WagtailImage, SelectOption } from "./shared";

export enum GoalStatus {
  NotStarted = "Not Started",
  InProgress = "In Progress",
  Completed = "Completed",
  Delayed = "Delayed",
  All = "All Goals",
}

export interface Goal {
  id: number;
  meta: {
    type: PageTypes.Goal;
    detail_url: string;
    html_url: string;
    slug: string;
    show_in_menus: false;
    seo_title: string;
    search_description: string;
    first_published_at: string;
    alias_of?: string;
    locale: string;
  };
  title: string;
  objectives: string;
  strategy: string;
  thumbnail?: WagtailImage;
  completion_rate: number;
  subgoals: SubGoal[];
  status: GoalStatus;
}

export interface SubGoal {
  id: number;
  title: string;
  owners: {
    id: number;
    name: string;
  }[];
  completion_rate: number;
  requirements: {
    id: number;
    is_done: boolean;
    description: string;
  }[];
}

export type GoalSelectOption = SelectOption<GoalStatus, GoalStatus>;
