import { getMonthName } from "./helper";

export const defaultHelmetTitle = "WerkStation";

export enum FormTypes {
  idea = "idea",
  report = "report",
  service = "service",
}

export const months = Array.from({ length: 12 }, (_, index) =>
  getMonthName(index, "short")
);
export const quarters = Array.from({ length: 4 }, (_, index) => index + 1);
