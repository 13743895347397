import React, { useEffect, useMemo } from "react";
import { Outlet } from "react-router-dom";
import PageLoading from "../../components/PageLoading";
import Layout from "../../layout";
import useAuthenticate from "./useAuthenticate";
import useIdleTimeout from "./useIdleTimeout";

const PrivateRoute = () => {
  const { data, retrieving, authenticated } = useAuthenticate();
  const warnAfter = useMemo(() => data?.warn_after || 30, [data?.warn_after]);
  const expireAfter = useMemo(
    () => data?.expire_after || 40,
    [data?.expire_after]
  );

  const { showWarning } = useIdleTimeout({
    warnAfter,
    expireAfter,
  });

  const dataLayer = window.dataLayer;

  useEffect(() => {
    if (
      !dataLayer ||
      !dataLayer.some((layer: Object) => Object.values(layer).includes("js"))
    ) {
      window.gtag("js", new Date());
    }
  }, [dataLayer]);

  useEffect(() => {
    if (data && data.email) {
      window.gtag("config", "G-4EZQD4DF39", {
        user_id: data.email,
        email: data.email,
      });
    }
  }, [data]);

  return (
    <>
      {retrieving && <PageLoading />}
      {authenticated && (
        <Layout>
          <Outlet />
        </Layout>
      )}
      {showWarning && (
        <div className="fixed h-full w-full bg-gray-900 bg-opacity-80 flex top-0 right-0 bottom-0 left-0 justify-center items-center">
          <div className=" bg-white p-20 rounded-[10px] flex flex-col justify-center items-center gap-6">
            <h1>Your session is about to expire</h1>
            <p>Move or click the mouse, or type to extend your session.</p>
          </div>
        </div>
      )}
    </>
  );
};

export default PrivateRoute;
