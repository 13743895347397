import { IdeaDataType } from "../types/idea";
import { ReportDataType } from "../types/report";

export const getCurrentYearQuarter = () => {
  const currentMonth = new Date().getMonth() + 1;
  if (currentMonth <= 3) {
    return "Q1";
  } else if (currentMonth <= 6) {
    return "Q2";
  } else if (currentMonth <= 9) {
    return "Q3";
  } else {
    return "Q4";
  }
};

export const instanceOfIdeaData = (idea: any): idea is IdeaDataType => {
  // Checking if interface is instance of IdeaDataType by using field "idea"
  return "idea" in idea;
};

export const instanceOfReportData = (report: ReportDataType): boolean => {
  // Checking if interface is instance of ReportDataType using "summary"
  return "summary" in report;
};
