import React, { FC, useState } from "react";
import Popup from "reactjs-popup";
import { Member } from "../../types/member";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface MemberViewComponentProps {
  member: Member;
  onClose: Function;
}

const MemberViewComponent: FC<MemberViewComponentProps> = ({
  member,
  onClose,
}) => {
  const {
    first_name,
    last_name,
    profile_pic,
    skillset,
    certifications,
    hobbies,
    teams,
  } = member;
  const [open, setOpen] = useState(true);

  return (
    <Popup className="modal" open={open} onClose={() => onClose()}>
      <div className="flex bg-white rounded-[10px] shadow-teamShadow cursor-default relative border">
        <FontAwesomeIcon
          icon={["fas", "xmark"]}
          className="cursor-pointer right-5 top-5 absolute"
          onClick={() => setOpen(false)}
        />
        <div className="w-48 h-full items-center my-8">
          {profile_pic && (
            <img
              src={profile_pic.url}
              alt={`${first_name} ${last_name}`}
              className="mx-auto rounded-full"
            />
          )}
        </div>
        <div className="bg-white rounded-[10px] mr-8 my-8 cursor-default">
          <h1>{`${first_name} ${last_name}`}</h1>
          <div className="grid grid-cols-[100px_1fr] mt-5 gap-y-4">
            <div className={skillset?.length ? "mr-4" : "hidden"}>
              Skillset:
            </div>
            <div className={skillset?.length ? "mr-4" : "hidden"}>
              {skillset?.map((item) => item.name).join(", ")}
            </div>
            <div className="mr-4">Team:</div>
            <div>
              {teams
                .map((teamMembership) => teamMembership.team.name)
                .join(", ")}
            </div>
          </div>
          {certifications && certifications.length ? (
            <div className="mt-5">
              <h6 className="mr-4 my-2 ">Certifications & Trainings:</h6>
              <ul className="list-disc ml-5">
                {certifications.map((item) => (
                  <li key={item.id}>{item.title}</li>
                ))}
              </ul>
            </div>
          ) : null}
          <div className={hobbies.length ? "mt-5" : "hidden"}>
            <h6 className="mr-4 my-2">Hobbies:</h6>
            <ul className="list-disc ml-5">
              {hobbies?.map((item) => (
                <li key={item.id}>{item.name}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default MemberViewComponent;
