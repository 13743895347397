import { FunctionComponent, useCallback, useMemo } from "react";
import { Params } from "react-router-dom";
import { useBasePath } from "../../hooks";
import { Entity, MenuItem } from "../../types/entity";
import { ErrorCode, ErrorResponse } from "../../types/error";
import {
  MenuItemType,
  MenuPath,
  PagePreviewQueryParams,
  PageProps,
} from "../../types/shared";
import GoalsPage from "../GoalsPage";
import GroupsPage from "../GroupsPage";
import MembersPage from "../MembersPage";
import ProjectsPage from "../ProjectsPage";
import CustomPage from "../CustomPage";
import TimelinePage from "../TimelinePage";
import CustomCardPage from "../CustomCardPage";

interface UseEntityPageProps {
  params: Readonly<Params<string>>;
  entity?: Entity;
  queryParams?: PagePreviewQueryParams;
  isPageReady: boolean;
}

export const useEntityPage = ({
  params,
  entity,
  queryParams,
  isPageReady,
}: UseEntityPageProps) => {
  const determineComponent = useCallback((menuType: MenuItemType) => {
    switch (menuType) {
      case MenuItemType.Teams:
        return GroupsPage;
      case MenuItemType.Members:
        return MembersPage;
      case MenuItemType.Goals:
        return GoalsPage;
      case MenuItemType.Projects:
        return ProjectsPage;
      case MenuItemType.Timeline:
        return TimelinePage;
      case MenuItemType.CustomCard:
        return CustomCardPage;
      default:
        return CustomPage;
    }
  }, []);

  const getMenuPath = useCallback((menuItem?: MenuItem) => {
    const knownMenuPaths: Record<string, string> = {
      [MenuItemType.Goals]: MenuPath.Goals,
      [MenuItemType.Members]: MenuPath.Members,
      [MenuItemType.Projects]: MenuPath.Projects,
      [MenuItemType.Teams]: MenuPath.Teams,
      [MenuItemType.Timeline]: MenuPath.Timeline,
    };

    if (!menuItem) {
      return "";
    }

    return knownMenuPaths[menuItem.menu_type] || menuItem.url_path;
  }, []);

  /**
   * The route that will be used by the entity page
   * returns an object that contains the menu path as key and the component to be rendered as the value
   */
  const routes = useMemo(() => {
    const result: Record<string, FunctionComponent<PageProps>> = {};

    entity?.menu_items?.forEach((menuItem) => {
      result[getMenuPath(menuItem)] = determineComponent(menuItem.menu_type);
    });
    return result;
  }, [determineComponent, entity, getMenuPath]);

  const basePath = useBasePath(["path"]);

  /**
   * Contains the menu items to be rendered (The tabs in the page)
   */
  const menuItems = useMemo(() => {
    const homePageMenuType =
      entity?.menu_items[0].menu_type || MenuItemType.Teams;
    return (
      entity?.menu_items.map((item) => {
        const menuPath = getMenuPath(item);

        return {
          id: menuPath,
          title: item.label,
          isActive:
            params.path === menuPath ||
            (homePageMenuType === item.menu_type && !params.path),
          link: `${basePath}/${menuPath}/${
            queryParams
              ? `?content_type=${queryParams.content_type}&token=${queryParams.token}`
              : ""
          }`,
        };
      }) || []
    );
  }, [entity, params.path, basePath, queryParams, getMenuPath]);

  const currentMenuPath = useMemo(() => {
    let menuPath = getMenuPath(entity?.menu_items[0]);
    if (params.path) {
      menuPath = params.path;
    }
    return menuPath;
  }, [entity, getMenuPath, params]);

  const CurrentActiveComponent = useMemo(() => {
    return routes[currentMenuPath];
  }, [routes, currentMenuPath]);

  if (isPageReady && CurrentActiveComponent === window.undefined) {
    throw new ErrorResponse("", ErrorCode.NotFound, "");
  }

  return { menuItems, CurrentActiveComponent, currentMenuPath };
};
