import { configureStore } from "@reduxjs/toolkit";
import { baseApi } from "./services";

import { setupListeners } from "@reduxjs/toolkit/query";
import { commentReducer, formReducer, searchReducer } from "./slices";

const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    comment: commentReducer,
    form: formReducer,
    search: searchReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(baseApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export default store;
