import { User } from "./application";
import { SelectOption } from "./shared";

export interface IdeaDataType {
  id?: number;
  idea: string;
  avail_service: OurServicesSelectOption | null;
  request_priority: RequestPrioritySelectOption | null;
  our_products: OurProductsSelectOption | null;
  optics_requests: Array<string>;
  onboard_request: OnboardingRequestsSelectOption | null;
  optics_radar_link?: string;
  more_idea: string;
  team_help: string;
  date_selection: string;
  explain_change: string;
  quip_links: string;
  radar_component: string;
  screener: string;
  webpage_title: string;
  website_details: string;
  contact_person: string;
  python_dev: string;
  related_to: SelectOption<string, string> | null;
  status?: string;
  integration_id?: string;
  date_created?: string;
  assigned_to?: string;
  updated_at?: string;
  user?: User;
}

export interface IdeaFileDataType {
  id?: number | undefined;
  idea_id?: number | undefined;
  file: string | undefined;
  user_id: number | undefined;
}

export enum OurServices {
  ChangeRequests = "Change Requests",
  FeatureRequests = "Feature Requests",
  ProductOnboarding = "Product Onboarding",
  DataRequests = "Data Requests",
  WebsiteContent = "Update Content for Website",
  UIDesign = "UI Design Requests",
  DataAnalysis = "Data Analysis and Consulting",
  OtherRequests = "New Ideas", //Changing this to "New Ideas" from "Others"
}

export type OurServicesSelectOption = SelectOption<OurServices, OurServices>;

export enum RequestPriority {
  Regular_Request = "Regular Request",
  Urgent_Request = "Urgent Request",
}

export type RequestPrioritySelectOption = SelectOption<
  RequestPriority,
  RequestPriority
>;

export const OurServicesSelect: OurServicesSelectOption[] = [
  { value: OurServices.OtherRequests, label: OurServices.OtherRequests },
  { value: OurServices.ChangeRequests, label: OurServices.ChangeRequests },
  { value: OurServices.FeatureRequests, label: OurServices.FeatureRequests },
  {
    value: OurServices.ProductOnboarding,
    label: OurServices.ProductOnboarding,
  },
  {
    value: OurServices.DataRequests,
    label: OurServices.DataRequests,
  },
  { value: OurServices.WebsiteContent, label: OurServices.WebsiteContent },
  { value: OurServices.UIDesign, label: OurServices.UIDesign },
  { value: OurServices.DataAnalysis, label: OurServices.DataAnalysis },
];

export enum OurProducts {
  Clever = "Clever",
  GreenSightv2 = "GreenSightv2",
  OperationsAnalytics = "Optics (Operations Analytics)",
  Slackbot = "Slackbot",
  Others = "Others",
}

export type OurProductsSelectOption = SelectOption<OurProducts, OurProducts>;

export const OurProductsSelect: OurProductsSelectOption[] = [
  { value: OurProducts.Clever, label: OurProducts.Clever },
  {
    value: OurProducts.OperationsAnalytics,
    label: OurProducts.OperationsAnalytics,
  },
  {
    value: OurProducts.GreenSightv2,
    label: OurProducts.GreenSightv2,
  },
  {
    value: OurProducts.Slackbot,
    label: OurProducts.Slackbot,
  },
];

export const ChangeProductSelect: OurProductsSelectOption[] = [
  {
    value: OurProducts.OperationsAnalytics,
    label: OurProducts.OperationsAnalytics,
  },
];

export enum OpticsChangeRequests {
  RadarTermsAndDefinitions = "Radar Terms and Definitions",
}

export interface OpticsRequestType {
  name: string;
  value: string;
  checked: boolean;
}

export const OpticsRequests: OpticsRequestType[] = [
  {
    name: "RadarTermsAndDefinitions",
    value: OpticsChangeRequests.RadarTermsAndDefinitions,
    checked: false,
  },
];

export enum OnboardingRequests {
  AdditionalProperty = "Additional Property/Service",
  NewProperty = "New Property/Service",
}

export type OnboardingRequestsSelectOption = SelectOption<
  OnboardingRequests,
  OnboardingRequests
>;

export const OnboardingRequestsSelect: OnboardingRequestsSelectOption[] = [
  {
    value: OnboardingRequests.AdditionalProperty,
    label: OnboardingRequests.AdditionalProperty,
  },
  {
    value: OnboardingRequests.NewProperty,
    label: OnboardingRequests.NewProperty,
  },
];

export interface QuipLinksType {
  link: string;
  text: string;
}
export const QuipLinks: QuipLinksType[] = [
  {
    link: "https://quip-apple.com/aE9kA411LYS7",
    text: "Optics Radar: Onboarding Checklist",
  },
];
