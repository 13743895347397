import React from "react";
import { useNavigate, useRouteError } from "react-router-dom";
import notFoundImage from "../assets/images/404.svg";
import internalError from "../assets/images/500.svg";
import { ErrorCode, ErrorConfigs, ErrorConfig } from "../types/error";
import Header from "../layout/header";
import { useGetUserInfoQuery } from "../services";

const ErrorPage = () => {
  const error = useRouteError() as Response;
  const navigate = useNavigate();
  const { isLoading, isFetching, isSuccess } = useGetUserInfoQuery(null);

  const errorConfig: ErrorConfigs = {
    [ErrorCode.InternalError]: {
      image: internalError,
      description: "Sorry, something went wrong.",
    },
    [ErrorCode.NotFound]: {
      image: notFoundImage,
      description: "Sorry, this page does not exist .",
    },
  };
  const errorCode = `${error.status}` as ErrorCode;
  const currentConfig: ErrorConfig =
    errorConfig[errorCode] || errorConfig[ErrorCode.InternalError];

  return (
    <>
      <Header loggedIn={!isLoading && !isFetching && isSuccess} />
      <div className="grid grid-cols-1 gap-y-10 mt-28 mb-28">
        <div className="flex justify-center">
          <img src={currentConfig.image} alt={currentConfig.description} />
        </div>
        <div className="flex justify-center mx-3">
          <h1 className="mt-10 text-center">{currentConfig["description"]}</h1>
        </div>
        {Boolean(error?.status) && (
          <div className="flex justify-center text-2xl ">
            Error code: {errorCode}
          </div>
        )}
        <div className="flex justify-center text-xl">
          <span
            className="underline mr-2 clickable"
            onClick={() => navigate(-1)}
          >
            Go back to previous page
          </span>
        </div>
        <div className="flex justify-center text-xl">
          <span>or go to</span>
          <a className="underline ml-2" href={window.location.origin}>
            Werkstation.com
          </a>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
