import React from "react";
import ProfilePageComponent from "./ProfilePageComponent";
import { useGetMembersQuery } from "../../services";
import useAuthenticate from "../PrivateRoute/useAuthenticate";

const ProfilePage = () => {
  const user = useAuthenticate();
  const entityParams = {
    filter: { email: user?.data?.email },
  };
  const { data } = useGetMembersQuery(entityParams);

  return <ProfilePageComponent members={data || []} />;
};

export default ProfilePage;
