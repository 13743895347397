import { WagtailImage } from "./shared";

export enum PostCategory {
  Announcement = "Announcement",
  Blog = "Blog",
}

export interface Post {
  id: number;
  meta: {
    type: "entity.Post";
    detail_url: string;
    html_url: string;
    slug: string;
    show_in_menus: boolean;
    seo_title: string;
    search_description: string;
    first_published_at: string;
    alias_of: string | null;
    locale: string;
  };
  title: string;
  headline: string;
  author: {
    id: number;
    first_name: string;
    last_name: string;
  };
  content: string;
  tags: string[];
  last_published_at: string;
  thumbnail: WagtailImage;
  category: PostCategory;
}
