import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import Modal from "../../components/Modal";
import ProgressBar from "../../components/ProgressBar";
import SanitizeHTML from "../../components/SanitizeHTML";
import SingleAccordion from "../../components/SingleAccordion";
import { Goal } from "../../types/goal";

interface GoalModalProps {
  goal: Goal;
  open: boolean;
  onClose: (open: false) => void;
}

const GoalModal = ({ goal, onClose, open }: GoalModalProps) => {
  const parentGoalOwner = useMemo(() => {
    let owners: { [key: string]: boolean } = {};

    for (let subgoal of goal.subgoals) {
      for (let owner of subgoal.owners) {
        owners[owner.name] = true;
      }
    }

    return Object.keys(owners).join(", ");
  }, [goal]);
  return (
    <Modal header={goal.title} category="Goal" onClose={onClose} open={open}>
      <div className="mb-8">
        <div className="grid md:grid-cols-4">
          <div>Completion:</div>
          <div className="flex col-span-2 items-center gap-2 font-bold">
            {goal.completion_rate}%
            <div className="w-32">
              <ProgressBar completionRate={goal.completion_rate} />
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-4">
          <div className="col-span-1">Teams Involved:</div>
          <div className="col-span-3 font-bold">{parentGoalOwner}</div>
        </div>
      </div>
      <div className="mb-8">
        <h4>Sub Goals</h4>
        <div className="mt-4">
          {goal.subgoals.map((subgoal) => (
            <SingleAccordion
              key={subgoal.id}
              header={subgoal.title}
              className="mt-2"
            >
              <div className="bg-white p-6">
                <div className="md:flex items-center gap-6">
                  <div>Responsible:</div>
                  <div className="flex items-center gap-4">
                    <span className="font-bold">
                      {subgoal.owners.map((subgoal) => subgoal.name).join(", ")}
                    </span>
                  </div>
                </div>
                <div className="md:flex items-center gap-6">
                  <div>Completion:</div>
                  <div className="flex items-center gap-4">
                    <span className="font-bold">
                      {subgoal.completion_rate}%
                    </span>
                    <ProgressBar
                      className="w-32"
                      completionRate={subgoal.completion_rate}
                    />
                  </div>
                </div>
                <div className="mt-4 flex flex-col gap-2 ">
                  {subgoal.requirements.map((req) => (
                    <div key={req.id} className="flex gap-2 items-center">
                      <FontAwesomeIcon
                        className={
                          req.is_done ? "text-primary" : "text-[#E2E2E2]"
                        }
                        icon={["fas", req.is_done ? "square-check" : "square"]}
                      />
                      {req.description}
                    </div>
                  ))}
                </div>
              </div>
            </SingleAccordion>
          ))}
        </div>
      </div>

      <div className="mb-8">
        <h5 className="mb-2">Objectives</h5>
        <div className="wysiwyg-content mt-14 font-extralight w-[90%]">
          <SanitizeHTML html={goal.objectives} />
        </div>
      </div>

      <div className="mb-8">
        <h5 className="mb-2">Strategy</h5>
        <div className="w-[90%]">
          <SanitizeHTML html={goal.strategy} />
        </div>
      </div>
    </Modal>
  );
};

export default GoalModal;
