import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetPagesQuery } from "../../services";
import { PagesQueryParams } from "../../types/shared";
import GlobalSearchComponent from "./GlobalSearchComponent";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import ReactPaginate from "react-paginate";
import { setPage } from "../../slices/search";

const GlobalSearchPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { keyword, page, size } = useSelector(
    (state: RootState) => state.search
  );
  const params: PagesQueryParams = {
    url: "search/",
    filter: {
      query: keyword,
      page: page,
      size: size,
    },
  };
  const { data, isLoading, isSuccess, isFetching, isError } =
    useGetPagesQuery(params);
  const isReady = !isError && !isLoading && !isFetching && isSuccess;

  useEffect(() => {
    if (!keyword) navigate("/");
  }, [keyword, navigate]);

  return (
    <>
      <GlobalSearchComponent data={data?.items} isReady={isReady} />
      {isReady && (
        <div className="w-full mb-5">
          <div className="w-fit mx-auto">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              previousLabel="<"
              pageCount={(data as any)?.total}
              pageRangeDisplayed={5}
              className="flex gap-5"
              activeClassName="underline"
              forcePage={page - 1}
              onPageChange={(evt) => dispatch(setPage(evt.selected + 1))}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default GlobalSearchPage;
