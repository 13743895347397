import React, { FunctionComponent } from "react";
import { useGetProjectsQuery } from "../../services";
import { Project } from "../../types/project";

import { PageProps, PageResult } from "../../types/shared";
import TimelinePageComponent from "./TimelinePageComponent";

const TimelinePage: FunctionComponent<PageProps> = ({
  entityId,
  preContent,
}) => {
  const { data, error, isLoading, isSuccess, isFetching, isError } =
    useGetProjectsQuery({ filter: { project_team: entityId } });

  return (
    <TimelinePageComponent
      data={data as PageResult<Project>}
      error={error}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isFetching={isFetching}
      isError={isError}
      preContent={preContent}
    />
  );
};

export default TimelinePage;
