import React, { FunctionComponent, useMemo, useState } from "react";

import { useGetCustomPageCardsQuery, useGetPagesQuery } from "../../services";
import { ErrorCode, ErrorResponse } from "../../types/error";
import { PageProps, PagesQueryParams, PageTypes } from "../../types/shared";
import CustomCardPageComponent from "./CustomCardPageComponent";
import { CustomCardPageType } from "../../types/customCardPage";
import { isErrorNotFound, pageReady } from "../../helper";

const CustomCardPage: FunctionComponent<PageProps> = ({
  url_path,
  entityId,
}) => {
  let params: PagesQueryParams = {
    url: "pages/",
    filter: {
      type: PageTypes.CustomCardPage,
      url_path_name: url_path,
      child_of: entityId,
    },
  };
  const [keyword, setKeyword] = useState("");

  const { data, ...pagesStatus } = useGetPagesQuery(params);

  const customCardPage = useMemo(() => {
    return data?.items[0] as CustomCardPageType;
  }, [data]);

  const { data: cards, ...customPageCardStatus } = useGetCustomPageCardsQuery(
    {
      filter: {
        custom_page__id: customCardPage?.id || 0,
        keyword,
      },
    },
    {
      skip: !customCardPage,
    }
  );

  const basePageNotFound = pageReady(pagesStatus) && data?.items.length === 0;
  const cardsNotFound =
    pageReady(customPageCardStatus) && isErrorNotFound(customPageCardStatus);

  if (basePageNotFound || cardsNotFound || !url_path) {
    throw new ErrorResponse("", ErrorCode.NotFound, "");
  }

  return (
    <CustomCardPageComponent
      basePage={customCardPage}
      cards={cards}
      isPageReady={pageReady(pagesStatus) && pageReady(customPageCardStatus)}
      isError={pagesStatus.isError || customPageCardStatus.isError}
      shouldShowFilter
      setSearchKeyword={setKeyword}
    />
  );
};

export default CustomCardPage;
