import { useGetUserInfoQuery } from "../../services";
import { ErrorCode, ErrorResponse } from "../../types/error";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

const useAuthenticate = () => {
  const { data, error, isLoading, isFetching, isError } =
    useGetUserInfoQuery(null);

  const retrieving = isLoading || isFetching;

  const unauthenticated =
    !retrieving && (error as FetchBaseQueryError)?.status === 403;

  const authenticated = !unauthenticated && data?.email;

  if (unauthenticated) {
    const pathName = window.location.pathname;
    window.location.href = `/login/?next=${pathName}`;
  }

  if (
    isError &&
    !retrieving &&
    (error as FetchBaseQueryError)?.status !== 403
  ) {
    throw new ErrorResponse("", ErrorCode.InternalError, "");
  }

  return { data, retrieving, authenticated };
};

export default useAuthenticate;
