import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { SingleValue } from "react-select";
import NavigationContentContainer from "../../components/NavigationContentContainer";
import ProgressBar from "../../components/ProgressBar";
import SingleSelect from "../../components/SingleSelect";
import { Project, ProjectSelectOption } from "../../types/project";
import { QueryResult, SelectOption } from "../../types/shared";
import MembersAvatar from "../../components/MembersAvatar";

type ProjectsPageComponentProps = QueryResult<
  (Project & { link?: string })[]
> & {
  alias?: string;
  entityId?: number | null;
  statusFilter?: {
    value: SingleValue<ProjectSelectOption>;
    setValue: React.Dispatch<
      React.SetStateAction<SingleValue<ProjectSelectOption>>
    >;
    options: ProjectSelectOption[];
  };
  subteamsFilter?: {
    value: SingleValue<SelectOption<number, string>>;
    setValue: React.Dispatch<
      React.SetStateAction<SingleValue<SelectOption<number, string>>>
    >;
    options: SelectOption<number, string>[];
  };
};

const ProjectsPageComponent = ({
  isLoading,
  isSuccess,
  isFetching,
  isError,
  data,
  entityId,
  statusFilter,
  subteamsFilter,
}: ProjectsPageComponentProps) => {
  const isPageReady = !isError && !isLoading && !isFetching && isSuccess;

  return (
    <NavigationContentContainer
      isContentReady={isPageReady}
      header={"Projects"}
    >
      <div className="mt-4 flex font-bold gap-5">
        {statusFilter && (
          <div>
            <SingleSelect
              options={statusFilter.options}
              value={statusFilter.value}
              onChange={statusFilter.setValue}
            />
          </div>
        )}
        {subteamsFilter && (
          <div>
            <SingleSelect
              options={subteamsFilter.options}
              value={subteamsFilter.value}
              onChange={subteamsFilter.setValue}
            />
          </div>
        )}
        {/* <div className="flex items-center">Download {`${alias} Goals`}</div> */}
      </div>
      <div className="grid md:grid-cols-[repeat(auto-fill,_minmax(466px,_1fr))] grid-cols-1 gap-6 mt-10">
        {isPageReady &&
          data &&
          data.length > 0 &&
          data.map((project) => (
            <ProjectCardComponent
              link={project.link || `/${entityId}/projects/${project.id}`}
              key={project.id}
              project={project}
            />
          ))}
      </div>
      {isPageReady && data && data.length === 0 && (
        <h1 className="flex  justify-center items-center">No Data</h1>
      )}
    </NavigationContentContainer>
  );
};

function ProjectCardComponent({
  link,
  project,
}: {
  link: string;
  project: Project;
}) {
  return (
    <Link to={link}>
      <div className="shadow-teamShadow rounded-[10px] min-h-[168px] flex clickable-scale">
        <div className="flex items-center w-[135px] p-5 justify-center">
          <img
            className="float-left w-[100px] h-[100px] object-contain"
            src={project.thumbnail?.meta.download_url}
            alt={project.thumbnail?.title}
          />
        </div>
        <div className="grid grid-rows-3 w-[336px] px-2">
          <h4 className="flex width-[331px] row-span-2 line-clamp-3 p-4 overflow-hidden">
            {project.name}
          </h4>
          <ProjectCardEntryComponent title="Completion:">
            <div className="flex gap-2">
              {project.completion_rate}%
              <ProgressBar
                className="flex self-center"
                completionRate={project.completion_rate}
              />
            </div>
          </ProjectCardEntryComponent>
          <ProjectCardEntryComponent title="Tech Lead(s):">
            <MembersAvatar members={project.project_leads} />
          </ProjectCardEntryComponent>
          <ProjectCardEntryComponent title="Project Manager(s):">
            <MembersAvatar members={project.project_managers} />
          </ProjectCardEntryComponent>
        </div>
      </div>
    </Link>
  );
}

ProjectsPageComponent.defaultProps = {
  alias: "Team Alias Placeholder",
};

const ProjectCardEntryComponent = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => (
  <div className="items-end pb-4 pl-4 gap-2 text-sm grid md:grid-cols-9 grid-cols-5">
    <div className="font-extralight md:col-span-3 col-span-2">{title}</div>
    <div className="font-bold md:col-span-6 col-span-3">{children}</div>
  </div>
);
export default ProjectsPageComponent;
