import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import Popup from "reactjs-popup";

interface ModalProps {
  children: ReactNode;
  header: string;
  category: string;
  open: boolean;
  onClose: (open: false) => void;
}
const Modal = ({ children, header, category, open, onClose }: ModalProps) => {
  return (
    <Popup
      className="modal"
      open={open}
      closeOnDocumentClick={false}
      position="left center"
    >
      <div className="md:h-[791px] md:w-[640px] w-[80vw] h-[80vh] rounded-[20px] bg-white pr-[20px] pl-[40px] pb-6 flex flex-col gap-y-4">
        <div className="grid  grid-cols-10 pt-[20px]">
          <div className="md:col-span-5 col-span-3 self-center">
            <div>
              <span className="border-b-2 border-textPrimary pb-2 font-bold">
                {category}
              </span>
            </div>
          </div>

          <div className="md:col-span-5 col-span-6 md:col-start-7 flex justify-end">
            <FontAwesomeIcon
              onClick={() => {
                onClose(false);
              }}
              icon={["fas", "xmark"]}
              className="text-[22px] text-[#585A5A] self-start clickable-scale"
            />
          </div>
        </div>
        <div className="grid grid-cols-5">
          <div className="col-span-4">
            <h2>{header}</h2>
          </div>
        </div>
        <div className="h-[600px] overflow-y-scroll scrollbar-thin scrollbar-thumb-textPrimary scrollbar-track-[#F3F3F3] scrollbar-thumb-rounded-[6px] pr-6">
          {children}
        </div>
      </div>
    </Popup>
  );
};

export default Modal;
