import { Member } from "./member";
import { PageTypes, SelectOption, WagtailImage } from "./shared";
export interface EntityProject {
  id: number;
  name: string;
  group_type: string;
}

export enum ProjectStatus {
  All = "All",
  OnHold = "On Hold",
  Proposed = "Proposed",
  Scheduled = "Scheduled",
  InProgress = "In Progress",
  Completed = "Completed",
  Cancelled = "Cancelled",
}

export enum RoadmapStatus {
  Now = "Now",
  Next = "Next",
  Later = "Later",
  Released = "Released",
}

export type ProjectSelectOption = SelectOption<ProjectStatus, ProjectStatus>;

export interface ProjectMember {
  id: number;
  member: Member;
}

export interface ProjectTeam {
  id: number;
  entity: EntityProject;
}

export interface ProjectFeature {
  id: number;
  name: string;
  status: string;
  schedule: string;
  // current_phase: string;
  completion_rate: number;
  start_month: number;
  start_year: number;
  end_month: number;
  end_year: number;
}

export interface ProjectPhase {
  id: number;
  name: string;
  project: {
    id: number;
    title: string;
  };
  features: ProjectFeature[];
  completion_rate: number;
  start_month: number;
  start_year: number;
  end_month: number;
  end_year: number;
  description: string;
}

export interface RoadMap {
  id: number;
  feature_name: string;
  status: RoadmapStatus;
}

export interface ProjectRoadMapColumns {
  status: RoadmapStatus;
  color: string;
}

export interface Stakeholder {
  id: number;
  name: string;
}

export interface Sponsor {
  id: number;
  name: string;
}

export interface Project {
  meta: {
    type: PageTypes.Project;
    detail_url: string;
    html_url: string;
    slug: string;
    show_in_menus: boolean;
    seo_title: string;
    search_description: string;
    first_published_at: string;
    alias_of: null | string;
    locale: string;
  };
  id: number;
  name: string;
  project_leads: ProjectMember[];
  project_teams: ProjectTeam[];
  project_phases: ProjectPhase[];
  thumbnail: WagtailImage;
  title: string;
  status: ProjectStatus;
  project_managers: ProjectMember[];
  features: ProjectFeature[];
  stakeholders: Stakeholder[];
  sponsors: Sponsor[];
  objectives: string;
  strategy: string;
  completion_rate: number;
  roadmap: RoadMap[];
  project_roadmap_columns: ProjectRoadMapColumns[];
}

export type YearQuarter = "Q1" | "Q2" | "Q3" | "Q4";
