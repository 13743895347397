import React, { ReactElement } from "react";
import sanitizeHtml, { IOptions } from "sanitize-html";

interface SanitizeHtmlProps {
  html: string;
  options?: IOptions;
}

const SanitizeHTML = ({ html, options }: SanitizeHtmlProps): ReactElement => {
  const sanitize: (dirty: string, options?: IOptions) => any = (
    dirty,
    options
  ) => ({
    __html: sanitizeHtml(dirty, options),
  });

  return <div dangerouslySetInnerHTML={sanitize(html, options)} />; // skipcq;
};

SanitizeHTML.defaultProps = {
  options: {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img", "iframe"]),
    allowedAttributes: {
      img: ["src"],
      iframe: [
        "src",
        "width",
        "height",
        "frameborder",
        "allow",
        "autoplay",
        "clipboard-write",
        "encrypted-media",
        "gyroscope",
        "picture-in-picture",
        "web-share",
        "allowfullscreen",
        "title",
      ],
      a: ["href"],
    },
    allowedSchemes: ["data", "http", "https", "tel", "mailto"],
  },
};
export default SanitizeHTML;
