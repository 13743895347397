import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import SanitizeHTML from "../../components/SanitizeHTML";
import { Application, ApplicationProps } from "../../types/application";
import { PageResult, QueryResult } from "../../types/shared";

const ApplicationComponent = ({
  data,
  isLoading,
  isFetching,
  isSuccess,
  isError,
  showApps,
  setShowApps,
}: QueryResult<PageResult<Application>> & ApplicationProps) => {
  const applications = data?.items;
  const isReady = !isError && !isLoading && !isFetching && isSuccess;

  return (
    <>
      <div className="font-normal text-[16px]">All Apps</div>
      {isReady && applications ? (
        <div>
          <div className="grid gap-4 lg:grid-cols-3 mt-5">
            {applications
              .filter((_, idx) => showApps || idx < 3)
              .map((app) => (
                <Link key={app.id} to={app.link} target="_blank">
                  <div className="flex flex-col md:flex-row rounded-lg p-5 h-full w-full bg-white">
                    <div className="max-w-[120px] h-1/3 md:w-1/3 md:h-auto flex items-center md:mr-5">
                      <img
                        src={app.logo.meta.download_url}
                        alt={app.name}
                        className="float-left w-full h-full object-contain p-2 lg:p-0"
                      />
                    </div>
                    <div className="md:w-2/3 md:h-auto overflow-y-scroll md:overflow-auto">
                      <div className="font-bold text-[16px] mb-5">
                        {app.name}
                      </div>
                      <SanitizeHTML html={app.description} />
                    </div>
                  </div>
                </Link>
              ))}
          </div>
          {applications.length > 3 && (
            <div className="text-center">
              <Button
                styleProp="mt-5 rounded-md bg-white"
                onClick={() => setShowApps((prev: boolean) => !prev)}
              >
                {showApps ? "Hide" : "Show all"} apps
                <FontAwesomeIcon
                  icon={["fas", showApps ? "chevron-up" : "chevron-down"]}
                  size="1x"
                  className="ml-3"
                />
              </Button>
            </div>
          )}
        </div>
      ) : (
        <Skeleton count={5} width="100%" />
      )}
    </>
  );
};

export default ApplicationComponent;
