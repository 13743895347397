import React, { useState, useEffect } from "react";
import sortBy from "lodash/sortBy";
import {
  FiledRequest,
  FilterButtons,
  ServiceRequestRowProps,
} from "../../types/serviceRequest";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createStyles } from "@mantine/core";
import { IdeaDataType } from "../../types/idea";
import { ReportDataType } from "../../types/report";
import ServiceRequestModal from "./ServiceRequestModal";

const useStyles = createStyles((theme) => ({
  root: {
    fontSize: "14px",
    "&& td": {
      fontSize: "14px",
      color: "#2C413D",
      fontWeight: 300,
      paddingBlock: "14px",
      textTransform: "capitalize",
    },
  },
  header: {
    "&& th": {
      background: "#F5F5F5",
      fontSize: "14px",
      color: "#2C413D",
      paddingBlock: "14px",
    },
  },
}));

interface ServiceRequestTableProps {
  filedRequests: FiledRequest[] | undefined;
  fetchRowData: (
    id: number | undefined,
    type: FilterButtons | undefined
  ) => IdeaDataType | ReportDataType | null | undefined;
}

const ServiceRequestTable: React.FC<ServiceRequestTableProps> = ({
  filedRequests,
  fetchRowData,
}) => {
  const { classes } = useStyles();
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "date_created",
    direction: "desc",
  });

  const [records, setRecords] = useState<FiledRequest[] | undefined>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<ServiceRequestRowProps>();

  // Update records when filedRequests change
  useEffect(() => {
    setRecords(filedRequests);
  }, [filedRequests]);

  // Update records when sortStatus changes
  useEffect(() => {
    if (filedRequests) {
      const sortedData = sortBy(filedRequests, sortStatus.columnAccessor);
      setRecords(
        sortStatus.direction === "desc" ? sortedData.reverse() : sortedData
      );
    }
  }, [sortStatus, filedRequests]);

  return (
    <>
      {modalOpen && (
        <ServiceRequestModal
          rowData={selectedRow?.rowData}
          type={selectedRow?.type}
          opened={modalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
      <div className="mt-5">
        <DataTable
          records={records}
          onRowClick={(record, event) => {
            const rowData = fetchRowData(record.id, record.type);
            setSelectedRow({
              rowData: rowData,
              type: record.type,
            });
            setModalOpen(true);
          }}
          minHeight={150}
          noRecordsText="No records to show"
          classNames={classes}
          rowBorderColor="#FFFFFF"
          columns={[
            {
              accessor: "integration_id",
              title: "ID",
              width: 100,
              sortable: true,
            },
            { accessor: "activity", width: 160, sortable: true },
            { accessor: "title", width: "30%" },
            { accessor: "date_created", title: "Date Created", sortable: true },
            { accessor: "filed_by", title: "Filed By", sortable: true },
            { accessor: "assigned_to", title: "Assigned To", sortable: true },
            { accessor: "status", sortable: true },
          ]}
          idAccessor="integration_id"
          sortStatus={sortStatus}
          onSortStatusChange={setSortStatus}
          sortIcons={{
            sorted: (
              <FontAwesomeIcon
                className="pt-[3px] text-[10px]"
                icon={["fas", "sort-up"]}
              />
            ),
            unsorted: (
              <FontAwesomeIcon className="text-[10px]" icon={["fas", "sort"]} />
            ),
          }}
        />
      </div>
    </>
  );
};

export default ServiceRequestTable;
