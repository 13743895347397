import { RefObject, useEffect } from "react";
// Hook

interface ValidRefTarget {
  contains(target: EventTarget | null): any;
}

export default function useClickOutside(
  ref: RefObject<ValidRefTarget>,
  handler: Function
) {
  useEffect(
    () => {
      const listener = (event: MouseEvent | TouchEvent) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };

      // MouseEvent
      document.addEventListener("mousedown", listener);
      document.addEventListener("mousedown", listener);
      // TouchEvent

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    [ref, handler]
  );
}
