import React, { ReactNode } from "react";
import Skeleton from "react-loading-skeleton";

interface NavigationContentContainerProps {
  isContentReady: boolean | undefined;
  header?: string;
  children: ReactNode;
}

const NavigationContentContainer = ({
  isContentReady,
  header,
  children,
}: NavigationContentContainerProps) => (
  <div className="lg:mx-44 md:mx-28 mx-4 mt-10 mb-20">
    <h1>{isContentReady ? header : <Skeleton width="30%" />}</h1>
    {children}
  </div>
);

export default NavigationContentContainer;
