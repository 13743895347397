import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../components/Button";
import Popup from "reactjs-popup";
import { PageResult, QueryResult } from "../../types/shared";
import { Application } from "../../types/application";
import Skeleton from "react-loading-skeleton";
import { FaveAppsPageProps } from ".";
import { useUpdateFaveAppsMutation } from "../../services";
import useAuthenticate from "../PrivateRoute/useAuthenticate";
import { Link } from "react-router-dom";

const FaveAppsComponent = ({
  data,
  isLoading,
  isFetching,
  isSuccess,
  isError,
  scrollToAllApps,
}: QueryResult<PageResult<Application>> & FaveAppsPageProps) => {
  const user = useAuthenticate();
  const applications = data?.items;
  const isReady = !isError && !isLoading && !isFetching && isSuccess;

  const [showAppsDialog, setShowAppsDialog] = useState(false);
  const [markedApps, setMarkedApps] = useState<Application[]>([]);
  const [updateFaveApps] = useUpdateFaveAppsMutation();

  useEffect(() => {
    if (user.data && applications && isReady) {
      setMarkedApps(
        applications?.filter((app) =>
          app.users.some((u) => u.email === user.data?.email)
        )
      );
    }
  }, [user.data, applications, isReady]);

  const applicationOnClick = (app: Application) => () => {
    if (markedApps.includes(app)) {
      setMarkedApps((prev) => prev?.filter((faveApp) => faveApp.id !== app.id));
    } else if (markedApps.length < 6) {
      setMarkedApps((prev) => prev?.concat(app));
    }
  };

  return (
    <div className="w-2/5 mr-5 relative">
      <div className="flex w-full mb-3 relative justify-between items-center">
        <div className="font-normal text-[16px] text-clip self-baseline">
          Your Favorite Apps
        </div>
        <Button
          styleProp="px-4 py-1 rounded-xl bg-white"
          onClick={() => setShowAppsDialog(true)}
        >
          <FontAwesomeIcon icon={["fas", "ellipsis-vertical"]} />
        </Button>
      </div>
      <div
        className={`grid grid-cols-1 ${
          markedApps.length ? "xl:grid-cols-2" : "xl:grid-cols-1"
        } gap-4 mx-auto`}
      >
        {markedApps.length ? (
          markedApps.map((app) => (
            <Link key={app.id} to={app.link} target="_blank">
              <Button styleProp="border bg-white rounded-xl p-4 min-w-[80px] w-full min-h-[112px] flex items-center mx-auto">
                <img
                  src={app.icon.meta.download_url}
                  alt={app.name}
                  className="min-h-[24px] max-h-[90px] mx-auto object-contain"
                />
              </Button>
            </Link>
          ))
        ) : (
          <div className="text-center w-full h-full">
            You have no Favorite Apps yet
          </div>
        )}
      </div>
      <div className="w-full bottom-0 absolute">
        <Button
          styleProp="border rounded-xl bg-white px-3 py-2 float-right text-[#52A3EF] mt-2 flex items-center"
          onClick={() => scrollToAllApps()}
        >
          <div className="mr-2 text-[16px]">Show all apps</div>
          <FontAwesomeIcon icon={["fas", "arrow-down"]} />
        </Button>
      </div>
      <Popup
        overlayStyle={{
          background: "#EEEEEE90",
        }}
        open={showAppsDialog}
        onClose={() => {
          updateFaveApps({ apps: markedApps });
          setShowAppsDialog(false);
        }}
        modal
        lockScroll
      >
        <div className="bg-white p-8 rounded-xl max-w-4xl">
          <div className="font-bold w-full my-5">
            Select 6 Favorite Apps
            <Button
              styleProp="px-4 py-1 rounded-xl bg-[#EEEEEE90] float-right mb-5"
              onClick={() => setShowAppsDialog(false)}
            >
              <FontAwesomeIcon icon={["fas", "xmark"]} />
            </Button>
          </div>
          <div
            className={`${
              isReady && applications ? "grid" : ""
            } gap-5 mb-4 w-full place-items-center`}
            style={{
              gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
            }}
          >
            {isReady && applications ? (
              applications?.map((app) => (
                <Button
                  key={app.id}
                  styleProp="border rounded-md px-3 py-2 w-full h-full min-h-[7rem]"
                  onClick={applicationOnClick(app)}
                >
                  <div className="h-[6rem] flex items-center p-4">
                    <img
                      src={app.logo?.meta.download_url}
                      alt={app.name}
                      className="float-left w-full h-full object-contain"
                    />
                  </div>
                  <div className="flex items-center w-full">
                    <div className="text-left mr-auto truncate">{app.name}</div>
                    {markedApps.some((ma) => ma.id === app.id) && (
                      <FontAwesomeIcon icon={["fas", "square-check"]} />
                    )}
                  </div>
                </Button>
              ))
            ) : (
              <Skeleton count={5} width="100%" />
            )}
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default FaveAppsComponent;
