import React, { useEffect, useRef, useState } from "react";
import {
  IdeaDataType,
  IdeaFileDataType,
  OnboardingRequests,
  OurServices,
} from "../../types/idea";
import { ReportDataType, ReportFileDataType } from "../../types/report";
import { FilterButtons, TaskType } from "../../types/serviceRequest";
import Popup from "reactjs-popup";
import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useGetIdeaFileQuery, useGetReportFileQuery } from "../../services";
import Comments from "../../components/Comments";
import { instanceOfIdeaData, instanceOfReportData } from "../../utils";
import { Comment as CommentIntfc } from "../../types/comment";
import { copyToClipboard } from "../../helper";
import { Tooltip, TooltipRefProps } from "react-tooltip";

interface ServiceRequestModalProps {
  rowData?: IdeaDataType | ReportDataType | null | undefined;
  onClose: () => void;
  opened?: boolean;
  type: FilterButtons | undefined;
}

const dateFormat = "YYYY-MM-DD hh:mm:ss";

const ServiceRequestModal: React.FC<ServiceRequestModalProps> = ({
  rowData,
  opened,
  onClose,
  type,
}) => {
  const serviceRequestType =
    type === FilterButtons.Requests ? TaskType.idea : TaskType.report;
  const [ideaFile, setIdeaFile] = useState<IdeaFileDataType[]>([]);
  const [reportFile, setReportFile] = useState<ReportFileDataType[]>([]);
  const [comments, setComments] = useState<CommentIntfc[]>([]);
  const toolTip = useRef<TooltipRefProps>(null);

  const { data: ideaFileQuery } = useGetIdeaFileQuery({ id: rowData?.id });
  const { data: reportFileQuery } = useGetReportFileQuery({ id: rowData?.id });

  const mapServiceRequestType = {
    [TaskType.idea]: { title: "Service Request" },
    [TaskType.report]: { title: "Bug Report" },
  };

  const title = type && mapServiceRequestType[serviceRequestType].title;

  useEffect(() => {
    if (ideaFileQuery) {
      setIdeaFile(ideaFileQuery);
    }
    if (reportFileQuery) {
      setReportFile(reportFileQuery);
    }
  }, [ideaFileQuery, reportFileQuery]);

  const IdeaFile = () => (
    <ul>
      {ideaFile &&
        ideaFile.map((f, i) => (
          <div
            key={f.file}
            className="flex space-x-3 font-light items-center justify-between border-solid border-[1px] border-[#EEEEEEE] rounded-md p-3 py-2 mb-1"
          >
            <span>{getFileName(f.file)}</span>
            <a
              rel="noopener noreferrer"
              target="blank"
              className="rounded-md bg-[#EFEFEF] inline-block px-2 py-1"
              href={f.file}
              download={getFileName(f.file)}
            >
              <FontAwesomeIcon
                icon={["fas", "download"]}
                color="#A63446"
                className="text-[20px] clickable-scale"
              />
            </a>
          </div>
        ))}
    </ul>
  );

  const ReportFile = () => (
    <ul>
      {reportFile &&
        reportFile.map((f, i) => (
          <div
            key={f.file}
            className="flex space-x-3 items-center justify-between border-solid border-[1px] border-[#EEEEEEE] rounded-md p-3 py-2 mb-1"
          >
            <span className="fw-300">{getFileName(f.file)}</span>
            <a
              rel="noopener noreferrer"
              target="_blank"
              className="rounded-md bg-[#EFEFEF] inline-block px-2 py-1"
              href={f.file}
              download={getFileName(f.file)}
            >
              <FontAwesomeIcon
                icon={["fas", "download"]}
                color="#A63446"
                className="text-[20px] clickable-scale"
              />
            </a>
          </div>
        ))}
    </ul>
  );
  const getIdeaTitle = () => {
    const data = rowData as unknown as IdeaDataType;
    return data.idea;
  };
  const getReportTitle = () => {
    const data = rowData as unknown as ReportDataType;
    return data.summary;
  };

  const getFileName = (url: string | undefined) => {
    if (url) {
      const cleanPath = url.split("?")[0];
      // Extract the file name
      return cleanPath.split("/").pop();
    }
    return;
  };
  const getIdeaDescription = () => {
    const data = rowData as unknown as IdeaDataType;
    return (
      <div className="text-[#919697] text-[14px]">
        <p>
          {data.user
            ? `Submitter: ${data.user.first_name} ${data.user.last_name}`
            : null}
        </p>
        <p>{data.avail_service ? `Service: ${data.avail_service}` : null}</p>
        <p>
          {data.contact_person
            ? `Main point of contact from your team for this request: ${data.contact_person}`
            : null}
        </p>
        <p>
          {data.date_selection
            ? `When do you want this request fulfilled?: ${data.date_selection}`
            : null}
        </p>
        <p>
          {data.more_idea &&
          data.more_idea !== "N/A" &&
          data.avail_service &&
          ![
            OurServices.DataRequests,
            OurServices.OtherRequests,
            OurServices.UIDesign,
          ].includes(data.avail_service.value)
            ? `How will it work: ${data.more_idea}`
            : null}
        </p>
        <p>
          {data.team_help &&
          data.team_help !== "N/A" &&
          data.avail_service &&
          ![
            OurServices.DataRequests,
            OurServices.OtherRequests,
            OurServices.UIDesign,
          ].includes(data.avail_service.value)
            ? `How will this request help you or your team: ${data.team_help}`
            : null}
        </p>
        <p>{data.our_products ? `Product: ${data.our_products}` : null}</p>
        <p>
          {data.screener && data.screener !== "N/A"
            ? `Who is the Radar screener for components mentioned?: ${data.screener}`
            : null}
        </p>
        <p>
          {data.python_dev && data.python_dev !== "N/A"
            ? `Nominated python developer: ${data.python_dev}`
            : null}
        </p>
        <p>
          {data.onboard_request &&
          [
            OnboardingRequests.AdditionalProperty,
            OnboardingRequests.NewProperty,
          ].includes(data.onboard_request.value)
            ? `Onboard Request Type: ${data.onboard_request}`
            : null}
        </p>
        <p>
          {data.optics_radar_link && data.optics_radar_link !== "N/A"
            ? `Onboarding Checklist: ${data.optics_radar_link}`
            : null}
        </p>
        <p>
          {data.radar_component && data.radar_component !== "N/A"
            ? `Radar Components: ${data.radar_component}`
            : null}
        </p>
        <p>
          {data.screener && data.screener !== "N/A"
            ? `Who is the Radar screener for components mentioned?: ${data.screener}`
            : null}
        </p>
        <p>
          {data.optics_requests
            ? `Requesting the change for?: ${data.optics_requests}`
            : null}
        </p>
        <p>
          {data.explain_change && data.explain_change !== "N/A"
            ? `Change Explanation: ${data.explain_change}`
            : null}
        </p>
        <p>
          {data.quip_links && data.quip_links !== "N/A"
            ? `Quip Document Links: ${data.quip_links}`
            : null}
        </p>
        <p>
          {data.more_idea &&
          data.more_idea !== "N/A" &&
          data.avail_service &&
          [
            OurServices.DataRequests,
            OurServices.OtherRequests,
            OurServices.UIDesign,
          ].includes(data.avail_service.value)
            ? `Tell us more about your request: ${data.more_idea}`
            : null}
        </p>
        <p>
          {data.team_help &&
          data.team_help !== "N/A" &&
          data.avail_service &&
          ![
            OurServices.DataRequests,
            OurServices.OtherRequests,
            OurServices.UIDesign,
          ].includes(data.avail_service.value)
            ? `How will this request help you or your team: ${data.team_help}`
            : null}
        </p>
        <p>
          {data.team_help &&
          data.team_help !== "N/A" &&
          data.avail_service &&
          [OurServices.WebsiteContent].includes(data.avail_service.value)
            ? `Can you brief us more about this edit request?: ${data.avail_service}`
            : null}
        </p>
        <p>
          {data.webpage_title && data.webpage_title !== "N/A"
            ? `Title of the page: ${data.webpage_title}`
            : null}
        </p>
        <p>
          {data.website_details && data.website_details !== "N/A"
            ? `How do you envision the placement of the page in the website?: ${data.website_details}`
            : null}
        </p>
      </div>
    );
  };

  const getReportDescription = () => {
    const data = rowData as unknown as ReportDataType;
    return (
      <div className="text-[#919697] text-[14px]">
        <p>{data.application ? `Application: ${data.application}` : null}</p>
        <p>{data.summary ? `Summary: ${data.summary}` : null}</p>
        <p>
          {data.user
            ? `Submitter: ${data.user.first_name} ${data.user.last_name}`
            : null}
        </p>
        <p>{data.steps ? `Steps to reproduce: ${data.steps}` : null}</p>
        <p>{data.expected ? `Expected Result: ${data.expected}` : null}</p>
        <p>{data.result ? `Actual Result: ${data.result}` : null}</p>
        <p>{data.url ? `URL: ${data.url}` : null}</p>
        <p>{data.browser ? `Browser: ${data.browser}` : null}</p>
      </div>
    );
  };

  const getTaskType = (): TaskType | null => {
    const reportData = rowData as unknown as ReportDataType;
    const ideaData = rowData as unknown as IdeaDataType;

    let type = null;

    if (ideaData && ideaData !== undefined && instanceOfIdeaData(ideaData)) {
      type = TaskType.idea;
    } else if (
      type == null &&
      reportData &&
      reportData !== undefined &&
      instanceOfReportData(reportData)
    ) {
      type = TaskType.report;
    }

    return type;
  };

  return (
    <>
      <Popup
        open={type !== null}
        onClose={onClose}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        overlayStyle={{
          background: "#EEEEEE90",
        }}
        nested
      >
        <>
          <div className="absolute top-[10px] right-[10px]">
            <Button
              onClick={() => {
                copyToClipboard(
                  `${window.location.protocol}//${window.location.hostname}/contact/${serviceRequestType}/${rowData?.id}/`
                );
              }}
              styleProp={`p-3 mr-[5px] rounded-md bg-boxGray text-black ${
                !serviceRequestType && "opacity-50 cursor-not-allowed"
              }`}
              toolTipPropId="share"
            >
              <FontAwesomeIcon icon={["fas", "share-nodes"]} size="1x" />
              <Tooltip
                ref={toolTip}
                id="share"
                content="Copied!"
                openOnClick={true}
                place="bottom"
                style={{
                  backgroundColor: "rgb(239, 239, 239)",
                  color: "#0d1b1e",
                }}
                afterShow={() => {
                  setTimeout(() => toolTip.current!.close(), 2000);
                }}
              />
            </Button>

            <Button
              onClick={onClose}
              styleProp="p-3 mr-[5px] rounded-md bg-boxGray text-black"
            >
              <FontAwesomeIcon icon={["fas", "xmark"]} size="1x" />
            </Button>
          </div>
          <div className="w-[320px] md:w-[640px] rounded-[10px] overflow-y-scroll overflow-x-hidden p-5 md:p-10 border-2 border-[#EEEEEE] bg-white max-h-[80vh] font-nunitosans text-[#0D1B1E]">
            <h4 className="font-normal">{title}</h4>
            <h4 className="font-normal mb-7 capitalize">
              Status: {rowData?.status}
            </h4>
            <div className=" px-3 py-2 text-[12px] justify-between rounded-md bg-boxGray text-black flex">
              <span>
                Filed by{" "}
                {rowData?.user
                  ? `${rowData?.user.first_name} ${rowData.user.last_name}`
                  : ""}
              </span>
              <span>
                Filed on {moment(rowData?.date_created).format(dateFormat)}
              </span>
            </div>
            <div className="mt-1 mb-5 px-3 py-2 text-[12px] justify-between rounded-md bg-boxGray text-black flex">
              <span>Assigned to {rowData?.assigned_to}</span>
              <span>
                Last edited {moment(rowData?.updated_at).format(dateFormat)}
              </span>
            </div>
            <div className="mb-5 flex flex-col">
              <label
                className="text-sm font-bold text-[#0D1B1E]"
                htmlFor="service-title"
              >
                Title
              </label>
              <div
                id="request-title"
                className="rounded-md py-2 px-3 border border-boxBorder"
              >
                <p className="text-[#919697] text-[14px] ">
                  {type === FilterButtons.Requests && getIdeaTitle()}
                  {type === FilterButtons.BugReports && getReportTitle()}
                </p>
              </div>
            </div>
            <div className="mb-5 flex flex-col">
              <label
                className="text-sm font-bold text-[#0D1B1E]"
                htmlFor="service-title"
              >
                Summary / Description
              </label>
              <div
                id="request-title"
                className="rounded-md py-2 px-3 border border-boxBorder"
              >
                {type === FilterButtons.Requests && getIdeaDescription()}
                {type === FilterButtons.BugReports && getReportDescription()}
              </div>
              {((type === FilterButtons.Requests && ideaFile.length > 0) ||
                (type === FilterButtons.BugReports &&
                  reportFile.length > 0)) && (
                <div className="mt-5">
                  <label
                    className="text-sm font-bold text-[#0D1B1E]"
                    htmlFor="attachments"
                  >
                    Attachments
                  </label>
                  {type === FilterButtons.Requests && <IdeaFile />}
                  {type === FilterButtons.BugReports && <ReportFile />}
                </div>
              )}
            </div>
            {comments && (
              <div className="mb-5 flex flex-col">
                <label
                  className="text-sm font-bold text-[#0D1B1E]"
                  htmlFor="service-title"
                >
                  {comments.length > 0 ? "Comments" : ""}
                </label>
                <div id="request-title">
                  <Comments
                    type={getTaskType()!}
                    id={rowData?.id!}
                    displayOnly={true}
                    onLoadData={(comments) => setComments(comments)}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      </Popup>
    </>
  );
};

export default ServiceRequestModal;
