import React from "react";

export interface CardComponentProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  image?: string;
  imageAlt?: string;
  title: string;
  subTitle?: string;
  description?: string;
  imageBackground?: string;
}

const Card = ({
  onClick,
  image,
  imageAlt,
  title,
  subTitle,
  description,
  imageBackground,
}: CardComponentProps) => {
  return (
    <div
      className={`flex flex-row mt-10 rounded-[10px] shadow-teamShadow lg:justify-self-start justify-self-center ${
        onClick && "cursor-pointer"
      } min-w-full`}
      {...(onClick && { onClick: onClick })}
    >
      {image && (
        <div
          className={`${imageBackground} flex min-w-[100px] max-w-[150px] h-full rounded-l-[10px] items-center shrink-0`}
        >
          <img src={image} alt={imageAlt} className="rounded-full mx-auto" />
        </div>
      )}
      <div className="flex flex-col rounded-r-[10px] px-8 py-4 lg:justify-self-start justify-self-center">
        <div className="justify-center align-middle">
          <h4>{title}</h4>
          {subTitle && (
            <small className="my-2">
              <span>{subTitle}</span>
            </small>
          )}
        </div>
        {description && (
          <div className="mt-4 text-sm break-words">{description}</div>
        )}
      </div>
    </div>
  );
};

Card.defaultProps = {
  imageBackground: "bg-white",
};

export default Card;
