import React from "react";
import { Link } from "react-router-dom";
import NoEntityImage from "../assets/images/no_post.png";
interface EntityCardProps {
  id: number;
  name: string;
  short_description: string;
  download_url?: string;
  link?: string;
  className?: string;
}

const EntityCard = ({
  id,
  name,
  download_url,
  short_description,
  link,
  className,
}: EntityCardProps) => {
  return (
    <Link
      to={link || `/entities/${id}`}
      key={id}
      className={`grid grid-rows-2 rounded-[10px] pb-20 pt-10 p-8 mt-10 shadow-teamShadow justify-self-center h-fit-content bg-white clickable-scale ${className}`}
    >
      <div className="grid md:grid-cols-2 justify-center align-middle gap-x-4">
        <div className="flex items-center break-words">
          <h4 className="line-clamp-4">{name}</h4>
        </div>
        <div className="flex self-center">
          <img
            src={download_url || NoEntityImage}
            alt=""
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = NoEntityImage;
            }}
          />
        </div>
      </div>
      <div className="mt-10 text-sm break-words">{short_description}</div>
    </Link>
  );
};

export default EntityCard;
