import React, { useEffect, useMemo, useState } from "react";

import NavigationContentContainer from "../../components/NavigationContentContainer";
import { Helmet } from "react-helmet-async";
import SanitizeHTML from "../../components/SanitizeHTML";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Skeleton from "react-loading-skeleton";
import CustomCardComponent from "./CustomCardComponent";
import { CustomCardPageType, CustomCardType } from "../../types/customCardPage";
import { ErrorCode, ErrorResponse } from "../../types/error";
import { defaultHelmetTitle } from "../../constants";

interface CustomCardPageComponentProps {
  basePage?: CustomCardPageType;
  cards?: CustomCardType[];
  isPageReady: boolean;
  isError: boolean;
  shouldShowFilter: boolean;
  setSearchKeyword?: React.Dispatch<React.SetStateAction<string>>;
}

export const CustomCardPageComponent: React.FunctionComponent<
  CustomCardPageComponentProps
> = ({
  basePage,
  cards,
  isPageReady,
  isError,
  shouldShowFilter,
  setSearchKeyword,
}) => {
  const [keyword, setKeyword] = useState("");

  const debounceSearch = useMemo(() => {
    let timer: ReturnType<typeof setTimeout>;

    return (keyword: string) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setSearchKeyword && setSearchKeyword(keyword);
      }, 500);
    };
  }, [setSearchKeyword]);

  if (isError) {
    throw new ErrorResponse("", ErrorCode.InternalError, "");
  }

  useEffect(() => {
    debounceSearch(keyword);
  }, [debounceSearch, keyword]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{basePage?.title || defaultHelmetTitle}</title>
      </Helmet>
      <NavigationContentContainer
        isContentReady={isPageReady}
        header={basePage?.header || ""}
      >
        {isPageReady && (
          <div className="my-10 text-justify font-montserrat wysiwyg-content">
            <SanitizeHTML html={basePage?.description || ""} />
          </div>
        )}
        {shouldShowFilter && (
          <div className="flex items-center mt-4">
            <div className="mr-6 md:block hidden relative">
              <input
                placeholder="Search"
                type="text"
                className="rounded-[6px] border border-boxBorder focus:border-rose-500 w-[221px] h-[41px] px-6 pr-10"
                value={keyword}
                onChange={(e) => setKeyword(e.currentTarget.value)}
              />
              {keyword.length ? (
                <FontAwesomeIcon
                  icon={["fas", "x"]}
                  className="absolute right-5 top-0 bottom-0 mx-0 my-auto text-red clickable"
                  onClick={() => setKeyword("")}
                />
              ) : (
                <FontAwesomeIcon
                  icon={["fas", "magnifying-glass"]}
                  className="absolute right-5 top-0 bottom-0 mx-0 my-auto"
                />
              )}
            </div>
          </div>
        )}
        <div className="grid gap-6 grid-cols-[repeat(auto-fit,_minmax(400px,_1fr))]">
          {isPageReady ? (
            cards?.map((card) => (
              <CustomCardComponent key={card.id} card={card} />
            ))
          ) : (
            <Skeleton count={5} width="80vw" />
          )}
        </div>
        {isPageReady && cards && cards.length === 0 && (
          <h1 className="flex  justify-center items-center">No Data</h1>
        )}
      </NavigationContentContainer>
    </>
  );
};

export default CustomCardPageComponent;
