import React from "react";
import { useGetPageQuery } from "../../services";
import { useParams } from "react-router-dom";
import EntityPageComponent from "./EntityPageComponent";
import { Entity } from "../../types/entity";
import { useEntityPage } from "./hooks";
import { useHierarchyBreadCrumbs } from "../../hooks";

const EntityPage = () => {
  const params = useParams();

  const { data, error, isFetching, isLoading, isSuccess, isError } =
    useGetPageQuery(parseInt(params.entityId || ""));

  const entity = data as Entity;

  const { menuItems, CurrentActiveComponent, currentMenuPath } = useEntityPage({
    params: params,
    entity: entity,
    isPageReady: !isFetching && !isLoading,
  });
  const breadCrumbs = useHierarchyBreadCrumbs(
    entity?.name || "",
    entity && `${entity?.id}`
  );

  return (
    <EntityPageComponent
      data={entity}
      error={error}
      isFetching={isFetching}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      menu={menuItems}
      CurrentActiveComponent={CurrentActiveComponent}
      breadCrumbs={breadCrumbs}
      url_path={currentMenuPath}
    />
  );
};

export default EntityPage;
