import React, { FunctionComponent, useMemo, useState } from "react";
import { SingleValue } from "react-select";
import { useGetPagesQuery, useGetProjectsQuery } from "../../services";
import { Entity } from "../../types/entity";
import { ProjectSelectOption, ProjectStatus } from "../../types/project";
import { PageProps, PageTypes, SelectOption } from "../../types/shared";
import ProjectsPageComponent from "./ProjectsPageComponent";

const ProjectsPage: FunctionComponent<PageProps> = ({ alias, entityId }) => {
  const {
    data: subteamsData,
    isLoading: subteamsLoading,
    isFetching: subteamsFetching,
    isError: subteamsError,
    isSuccess: subteamsSuccess,
  } = useGetPagesQuery({
    url: "pages/",
    filter: { type: PageTypes.Entity, child_of: entityId },
  });
  const subteams = subteamsData?.items;

  const [statusFilter, setStatusFilter] = useState<
    SingleValue<ProjectSelectOption>
  >({
    value: ProjectStatus.InProgress,
    label: ProjectStatus.InProgress,
  });

  const statusOptions: ProjectSelectOption[] = [
    { value: ProjectStatus.All, label: ProjectStatus.All },
    { value: ProjectStatus.OnHold, label: ProjectStatus.OnHold },
    { value: ProjectStatus.Proposed, label: ProjectStatus.Proposed },
    { value: ProjectStatus.Scheduled, label: ProjectStatus.Scheduled },
    { value: ProjectStatus.InProgress, label: ProjectStatus.InProgress },
    { value: ProjectStatus.Completed, label: ProjectStatus.Completed },
    { value: ProjectStatus.Cancelled, label: ProjectStatus.Cancelled },
  ];

  const subteamOptions: SelectOption<number, string>[] = useMemo(() => {
    const result = [{ value: entityId || -1, label: "All Teams" }];
    if (!subteams) return result;

    return result.concat(
      (subteams as Entity[]).map((subteam) => ({
        value: subteam.id,
        label: subteam.name,
      }))
    );
  }, [subteams, entityId]);

  const [subteamFilter, setSubteamFilter] = useState<
    SingleValue<SelectOption<number, string>>
  >(subteamOptions[0]);

  const { data, isLoading, isSuccess, isFetching, isError } =
    useGetProjectsQuery({
      filter: {
        project_team: subteamFilter?.value,
        ...(statusFilter?.value !== ProjectStatus.All && {
          status: statusFilter?.value,
        }),
      },
    });

  const projects = data?.items;

  return (
    <ProjectsPageComponent
      alias={alias}
      isLoading={isLoading && subteamsLoading}
      isSuccess={isSuccess && subteamsSuccess}
      isFetching={isFetching && subteamsFetching}
      isError={isError && subteamsError}
      data={projects}
      entityId={entityId}
      subteamsFilter={{
        value: subteamFilter,
        setValue: setSubteamFilter,
        options: subteamOptions,
      }}
      statusFilter={{
        value: statusFilter,
        setValue: setStatusFilter,
        options: statusOptions,
      }}
    />
  );
};

export default ProjectsPage;
