import React from "react";
import { useGetPreviewQuery } from "../../services";
import { PagePreviewQueryParams } from "../../types/shared";
import { useSearchParams } from "react-router-dom";
import { Entity } from "../../types/entity";
import GroupsComponent from "./GroupsComponent";

const GroupsPagePreview = () => {
  const [searchParams] = useSearchParams();

  let queryparams: PagePreviewQueryParams = {
    content_type: searchParams.get("content_type"),
    token: searchParams.get("token"),
  };

  const { data, error, isLoading, isSuccess, isFetching, isError } =
    useGetPreviewQuery(queryparams);

  const entity = data as Entity;
  const pageResult = {
    meta: {
      total_count: 1,
    },
    items: [
      {
        ...entity,
        link: `/draft/preview/entities/?content_type=${queryparams.content_type}&token=${queryparams.token}`,
      },
    ],
  };

  return (
    <GroupsComponent
      data={pageResult}
      error={error}
      isLoading={isLoading}
      isFetching={isFetching}
      isSuccess={isSuccess}
      isError={isError}
      preContent={{
        header: entity?.pre_teams_header,
        description: entity?.pre_teams_description,
      }}
    />
  );
};

export default GroupsPagePreview;
