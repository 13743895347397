import React, { useEffect, useMemo, useState } from "react";
import PostTabComponent from "../PostPage/PostTabComponent";
import ViewModeBtn from "../../assets/images/view_mode_btn.svg";
import OneTwoStackedIcon from "../../assets/images/one_two_stacked.svg";
import EvenIcon from "../../assets/images/even.svg";
import OneTwoIcon from "../../assets/images/one_two.svg";
import { ViewModes } from "../../types/dashboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MenuDropDown from "../../components/MenuDropdown";
import produce from "immer";
import { DashboardTabs, TabComponentProps } from "../../types/shared";
import { PostCategory } from "../../types/post";
import EntityTabComponent from "../EntityPage/EntityTabComponent";
import { Link } from "react-router-dom";
import { generateId } from "../../helper";

const DashboardTabsSection = () => {
  const [tabs, setTabs] = useState<DashboardTabs[]>([
    {
      title: "Announcements",
      id: "announcementTab",
      TabComponent: ({ viewMode }: TabComponentProps) => (
        <PostTabComponent
          viewMode={viewMode}
          post_category={PostCategory.Announcement}
        />
      ),
      defaultViewMode: ViewModes.OneTwo,
      show: true,
    },
    {
      title: "OpsWerks Teams",
      id: "departments",
      TabComponent: ({ viewMode }: TabComponentProps) => (
        <EntityTabComponent viewMode={viewMode} />
      ),
      defaultViewMode: ViewModes.Even,
      show: true,
    },
    {
      title: "Tech Blogs",
      id: "techBlogTab",
      TabComponent: ({ viewMode }: TabComponentProps) => (
        <PostTabComponent
          viewMode={viewMode}
          post_category={PostCategory.Blog}
        />
      ),
      defaultViewMode: ViewModes.OneTwo,
      show: true,
    },
    {
      title: "Project Highlights",
      id: "projects",
      TabComponent: (_: TabComponentProps) => <></>,
      defaultViewMode: ViewModes.OneTwo,
      show: true,
      link: "/entities/4/projects/",
    },
  ]);

  const numberOfTabsShown = useMemo(
    () => tabs.filter((tab) => tab.show).length,
    [tabs]
  );

  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const [currentViewMode, setCurrentViewMode] = useState<ViewModes>(
    ViewModes.Even
  );

  const viewModeMenu = useMemo(() => {
    return {
      Target: (
        <div className="icon-menu-dropdown">
          <img src={ViewModeBtn} alt="" />
        </div>
      ),
      menuItems: [
        {
          id: generateId(),
          title: "Even",
          onClick: () => setCurrentViewMode(ViewModes.Even),
          rightSection: <img src={EvenIcon} alt="even mode" />,
        },
        {
          id: generateId(),
          title: "One-Two",
          onClick: () => setCurrentViewMode(ViewModes.OneTwo),
          rightSection: <img src={OneTwoIcon} alt="one two mode" />,
        },
        {
          id: generateId(),
          title: "One-Two Stacked",
          onClick: () => setCurrentViewMode(ViewModes.OneTwoStacked),
          rightSection: (
            <img src={OneTwoStackedIcon} alt="one two stacked mode" />
          ),
        },
      ],
    };
  }, []);

  const toggleDashboardTabsMenu = useMemo(
    () => ({
      Target: (
        <div className="icon-menu-dropdown hidden md:flex">
          <FontAwesomeIcon icon={["fas", "ellipsis-vertical"]} />
        </div>
      ),
      menuItems: tabs.map((tab) => ({
        id: generateId(),
        title: tab.title,
        onClick: () => {
          setTabs((prevTabs) => {
            //Produce the new tabs
            const newTabs = produce(prevTabs, (draftTabs) => {
              const foundTab = draftTabs.find(
                (draftTab) => draftTab.id === tab.id
              );
              if (!foundTab) return;
              const showReverse = !foundTab?.show;
              if (numberOfTabsShown === 1 && !showReverse) return;
              foundTab.show = showReverse;
            });

            //Change the current tab to the closest when the toggled tab is the current one
            if (currentTab.id === tab.id) {
              const newCurrentTab = newTabs.find((newTab) => newTab.show);
              if (newCurrentTab) {
                setCurrentTab(newCurrentTab);
              }
            }

            return newTabs;
          });
        },
        icon: tab.show ? (
          <FontAwesomeIcon icon={["fas", "toggle-on"]} />
        ) : (
          <FontAwesomeIcon icon={["fas", "toggle-off"]} />
        ),
        disabled: tab.show && numberOfTabsShown === 1,
      })),
    }),
    [tabs, numberOfTabsShown, currentTab.id]
  );

  const generateTab = useMemo(
    () =>
      tabs.map((tab) => ({
        id: generateId(),
        title: tab.title,
        onClick: () => setCurrentTab(tab),
      })),
    [tabs]
  );
  useEffect(() => {
    if (currentTab) {
      setCurrentViewMode(currentTab.defaultViewMode);
    }
  }, [currentTab]);
  return (
    <>
      <div className="hidden md:flex justify-between">
        <div className="flex gap-6">
          {tabs
            .filter((tab) => tab.show)
            .map((tab) =>
              tab.link ? (
                <Link
                  key={tab.id}
                  className="rounded-[10px] px-[24px] py-[12px] shrink-0 bg-white clickable-scale mb-10"
                  to={tab.link}
                >
                  {tab.title}
                </Link>
              ) : (
                <div
                  key={tab.id}
                  className={`rounded-[10px] px-[24px] py-[12px] shrink-0  clickable-scale mb-10 ${
                    currentTab.id === tab.id
                      ? "text-white bg-textPrimary"
                      : "bg-white"
                  }`}
                  onClick={() => setCurrentTab(tab)}
                  role="button"
                >
                  {tab.title}
                </div>
              )
            )}
        </div>
        <div className="flex gap-6">
          <MenuDropDown
            Target={viewModeMenu.Target}
            menuItems={viewModeMenu.menuItems}
          />
          <MenuDropDown
            Target={toggleDashboardTabsMenu.Target}
            menuItems={toggleDashboardTabsMenu.menuItems}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4 mb-6 md:hidden">
        <div className="flex rounded-md py-2 px-4 col-span-2 bg-primary text-white justify-center items-center">
          {currentTab.title}
        </div>
        <MenuDropDown
          Target={
            <div className="flex rounded-md bg-white justify-center col-span-1 items-center clickable-scale">
              More
            </div>
          }
          menuItems={generateTab}
        />
      </div>
      <currentTab.TabComponent viewMode={currentViewMode} />
    </>
  );
};

export default DashboardTabsSection;
