import React from "react";
import Button from "./Button";

const YearQuarterButton = ({
  text,
  isActive,
  onClick,
}: {
  text: string;
  isActive: boolean;
  onClick: () => void;
}) => {
  const isActiveStyle = isActive
    ? "bg-blueJeans text-white"
    : "bg-boxGray text-blueJeans";

  return (
    <Button styleProp={`font-bold ${isActiveStyle}`} onClick={onClick}>
      {text}
    </Button>
  );
};

export default YearQuarterButton;
