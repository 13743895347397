import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useGetPagesQuery } from "./services";
import { Entity } from "./types/entity";
import { ErrorCode, ErrorResponse } from "./types/error";
import { BreadCrumb, BreadCrumbs, GroupType, PageTypes } from "./types/shared";

/**
 * Generates the base path url given the params value
 * @param {paramsKey} string[] - list of string that is a param key (in react router's useParams)
 */
export const useBasePath = (paramsKey?: string[]) => {
  const location = useLocation();
  const params = useParams<Record<string, string>>();
  const paramsValues = paramsKey
    ? paramsKey.map((key) => params[key])
    : Object.values(params);

  return paramsValues
    .reduce((path, param) => path?.replace("/" + param, ""), location.pathname)
    ?.replace(/\/$/, "");
};

export const useHierarchyBreadCrumbs = (
  currentEntityName: string,
  entityId?: string,
  lastBreadCrumb?: BreadCrumb
): BreadCrumbs => {
  const { data, isLoading, isFetching, isError, isSuccess } = useGetPagesQuery(
    {
      url: "pages/",
      filter: {
        ancestor_of: entityId,
        type: PageTypes.Entity,
      },
    },
    {
      skip: !entityId,
    }
  );
  if (isError) {
    throw new ErrorResponse("", ErrorCode.InternalError, "");
  }

  const entities = useMemo(() => (data?.items as Entity[]) || [], [data]);

  const groupsSorting = useMemo(() => {
    return {
      [GroupType.Department]: 0,
      [GroupType.Division]: 1,
      [GroupType.Group]: 2,
      [GroupType.Subgroup]: 3,
    };
  }, []);

  const result = useMemo(() => {
    const entityBreadCrumbs: BreadCrumb[] = [];
    entities.forEach((entity) => {
      const sortOrder = groupsSorting[entity.group_type];
      entityBreadCrumbs[sortOrder] = {
        title: entity.name,
        link: `/entities/${entity.id}`,
      };
    });
    const breadCrumbs = [
      {
        title: "Home",
        link: "/",
      },
      ...entityBreadCrumbs.filter((entityBreadCrumb) => entityBreadCrumb),
      {
        title: currentEntityName,
        link: `/entities/${entityId}`,
      },
    ];

    if (lastBreadCrumb) {
      breadCrumbs.push(lastBreadCrumb);
    }

    return breadCrumbs;
  }, [groupsSorting, entities, currentEntityName, entityId, lastBreadCrumb]);

  return {
    data: result,
    ready: !(isFetching || isLoading) && isSuccess,
  };
};
