export type ErrorConfigs = {
  [key in ErrorCode]: ErrorConfig;
};

export interface ErrorConfig {
  image: string;
  description: string;
}

export enum ErrorCode {
  InternalError = "500",
  NotFound = "404",
}

export class ErrorResponse {
  data: any;
  status: ErrorCode | undefined;
  statusText: string;

  constructor(data: any, status: ErrorCode | undefined, statusText: string) {
    this.data = data;
    this.status = status;
    this.statusText = statusText;
  }
}
