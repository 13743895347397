import React, { FunctionComponent, useMemo } from "react";

import { useGetPagesQuery } from "../../services";
import { CustomPageType } from "../../types/custom";
import { ErrorCode, ErrorResponse } from "../../types/error";
import { PageProps, PagesQueryParams, PageTypes } from "../../types/shared";
import CustomPageComponent from "./CustomPageComponent";

const CustomPage: FunctionComponent<PageProps> = ({ url_path, entityId }) => {
  let params: PagesQueryParams = {
    url: "pages/",
    filter: {
      type: PageTypes.Custom,
      url_path_name: url_path,
      child_of: entityId,
    },
  };

  const { data, error, isLoading, isSuccess, isFetching, isError } =
    useGetPagesQuery(params);

  if (
    (isSuccess && !isLoading && !isFetching && data.items.length === 0) ||
    !url_path
  ) {
    throw new ErrorResponse("", ErrorCode.NotFound, "");
  }

  const customPage = useMemo(() => {
    return data?.items[0] as CustomPageType;
  }, [data]);

  return (
    <CustomPageComponent
      data={customPage}
      isFetching={isFetching}
      isLoading={isLoading}
      error={error}
      isError={isError}
      isSuccess={isSuccess}
    />
  );
};

export default CustomPage;
