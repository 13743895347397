import { useCallback, useEffect, useRef, useState } from "react";
import { useLazyPingServerQuery } from "../../services";

interface UseIdleTimeoutProps {
  expireAfter: number;
  warnAfter: number;
}

//For Reference, converted django-session-security script to its react counter part
//https://github.com/yourlabs/django-session-security/blob/master/session_security/static/session_security/script.js
const useIdleTimeout = ({ expireAfter, warnAfter }: UseIdleTimeoutProps) => {
  const [showWarning, setShowWarning] = useState(false);
  const [pingServer] = useLazyPingServerQuery();
  const lastActivity = useRef(new Date());
  const currentTimeout = useRef(setTimeout(() => {}));

  // Called when there has been no activity for more than expireAfter
  // seconds.
  const expire = useCallback(() => {
    window.location.reload();
  }, []);

  const ping = useCallback(
    (apply: () => void) => {
      const idleFor = Math.floor(
        (new Date().getTime() - lastActivity.current.getTime()) / 1000
      );
      pingServer({ idleFor })
        .then((data) => {
          let serverIdleFor = data.data;
          if (serverIdleFor) {
            if (serverIdleFor === "logout") return expire();
            lastActivity.current = new Date();
            lastActivity.current.setSeconds(
              lastActivity.current.getSeconds() - serverIdleFor
            );
          }
          apply();
        })
        .catch(() => {
          apply();
        });
    },
    [pingServer, expire]
  );

  const apply = useCallback(() => {
    // Cancel timeout if any, since we're going to make our own
    clearTimeout(currentTimeout.current);

    let idleFor = Math.floor(
      (new Date().getTime() - lastActivity.current.getTime()) / 1000
    );
    let nextPing;

    if (idleFor >= expireAfter) {
      return expire();
    } else if (idleFor >= warnAfter) {
      setShowWarning(true);
      nextPing = expireAfter - idleFor;
    } else {
      setShowWarning(false);
      nextPing = warnAfter - idleFor;
    }

    // setTimeout expects the timeout value not to exceed
    // a 32-bit unsigned int, so cap the value
    let milliseconds = Math.min(nextPing * 1000, 2147483647);
    currentTimeout.current = setTimeout(() => {
      ping(apply);
    }, milliseconds);
  }, [expire, warnAfter, expireAfter, ping]);

  // Called by click, scroll, mousemove, keyup, touchstart, touchend, touchmove
  const activity = useCallback(() => {
    const now = new Date();
    if (now.getTime() - lastActivity.current.getTime() < 1000) {
      // Throttle these checks to once per second
      return;
    }

    const idleFor = Math.floor(
      (now.getTime() - lastActivity.current.getTime()) / 1000
    );
    lastActivity.current = now;

    if (idleFor >= expireAfter) {
      // Enforces checking whether a user's session is expired. This
      // ensures a user being redirected instead of waiting until nextPing.
      expire();
    }

    if (showWarning) {
      // Inform the server that the user came back manually, this should
      // block other browser tabs from expiring.

      ping(apply);
      // The hideWarning should only be called when the warning is visible
      setShowWarning(false);
    }
  }, [expire, expireAfter, showWarning, apply, ping]);

  useEffect(() => {
    const events = [
      "mousemove",
      "scroll",
      "keyup",
      "click",
      "touchstart",
      "touchend",
      "touchmove",
    ];

    for (let i = 0; i < events.length; i++) {
      document.addEventListener(events[i], activity);
    }

    return () => {
      for (let i = 0; i < events.length; i++) {
        document.removeEventListener(events[i], activity);
      }
    };
  }, [activity]);

  useEffect(() => {
    apply();
  }, [apply]);

  return { showWarning };
};

export default useIdleTimeout;
