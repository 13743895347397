import React from "react";
import { useGetPostsQuery } from "../../services";
import { isErrorNotFound, pageReady } from "../../helper";
import { ErrorCode, ErrorResponse } from "../../types/error";
import PostsCard from "./PostsCard";
import TabSectionContentComponent from "../DashboardPage/TabSectionContentComponent";
import { ViewModes } from "../../types/dashboard";
import { PostCategory } from "../../types/post";

const PostTabComponent = ({
  viewMode,
  post_category,
}: {
  viewMode: ViewModes;
  post_category: PostCategory;
}) => {
  const { data: recentPostsByType, ...mostRecentAQueryInfo } = useGetPostsQuery(
    {
      filter: {
        order: "-last_published_at",
        category: post_category,
      },
    }
  );
  const { data: mostReadPostsByType, ...mostReadAQueryInfo } = useGetPostsQuery(
    {
      filter: {
        order: "-reads",
        limit: 3,
        type: post_category,
        category: post_category,
      },
    }
  );

  if (
    isErrorNotFound(mostRecentAQueryInfo) ||
    isErrorNotFound(mostReadAQueryInfo)
  ) {
    throw new ErrorResponse("Not Found", ErrorCode.NotFound, "Not Found");
  }

  if (mostRecentAQueryInfo.isError || mostReadAQueryInfo.isError) {
    throw new ErrorResponse("", ErrorCode.InternalError, "");
  }

  const shouldShowThumbnail = (position: number) => {
    switch (viewMode) {
      case ViewModes.Even:
        return false;
      case ViewModes.OneTwo:
        return position % 2 !== 0;
      case ViewModes.OneTwoStacked:
        return position % 3 !== 1;
    }
  };

  const components = [
    {
      Component: PostsCard,
      props: {
        title: "Most Recent",
        posts: recentPostsByType?.items || [],
        showThumbnail: shouldShowThumbnail(1),
        post_category,
        isPageReady: pageReady(mostRecentAQueryInfo),
        isLoading:
          mostRecentAQueryInfo.isFetching || mostRecentAQueryInfo.isLoading,
      },
    },
    {
      Component: PostsCard,
      props: {
        title: "Most Read",
        posts: mostReadPostsByType?.items || [],
        showThumbnail: shouldShowThumbnail(2),
        post_category,
        isPageReady: pageReady(mostReadAQueryInfo),
        isLoading:
          mostReadAQueryInfo.isFetching || mostReadAQueryInfo.isLoading,
      },
    },
  ];
  return (
    <TabSectionContentComponent components={components} viewMode={viewMode} />
  );
};

export default PostTabComponent;
