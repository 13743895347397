import React from "react";
import { useGetPagesQuery } from "../../services";
import { Application, ApplicationProps } from "../../types/application";
import { PageResult, PagesQueryParams, PageTypes } from "../../types/shared";
import ApplicationComponent from "./ApplicationComponent";

const ApplicationPage = ({ showApps, setShowApps }: ApplicationProps) => {
  const params: PagesQueryParams = {
    url: "pages/",
    filter: {
      type: PageTypes.Application,
      status: "Active",
      order: "name",
    },
  };
  const { data, error, isLoading, isSuccess, isFetching, isError } =
    useGetPagesQuery(params);

  return (
    <ApplicationComponent
      data={data as PageResult<Application>}
      error={error}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isFetching={isFetching}
      isError={isError}
      showApps={showApps}
      setShowApps={setShowApps}
    />
  );
};

export default ApplicationPage;
