import React, { FunctionComponent, useMemo, useState } from "react";
import { useGetMembersQuery, useGetPagesQuery } from "../../services";
import { PageProps, PageTypes, SelectOption } from "../../types/shared";
import MemberPageComponent from "./MembersPageComponent";
import { Entity } from "../../types/entity";
import { SingleValue } from "react-select";
import { pageReady } from "../../helper";

const Members: FunctionComponent<PageProps> = ({ entityId, alias }) => {
  const childParams = {
    url: "pages/",
    filter: { type: PageTypes.Entity, child_of: entityId },
  };

  const subteams = useGetPagesQuery(childParams).data?.items;

  const subteamOptions: SelectOption<number, string>[] = useMemo(() => {
    const result = [{ value: entityId || 0, label: "All Teams" }];
    if (!subteams) return result;

    return result.concat(
      (subteams as Entity[]).map((subteam) => ({
        value: subteam.id,
        label: subteam.name,
      }))
    );
  }, [subteams, entityId]);

  const [subteamFilter, setSubteamFilter] = useState<
    SingleValue<SelectOption<number, string>>
  >(subteamOptions[0]);
  const [nameFilter, setNameFilter] = useState("");
  const entityParams = {
    filter: { teams__team: subteamFilter?.value, full_name: nameFilter },
  };
  const { data, ...membersQueryState } = useGetMembersQuery(entityParams);

  return (
    <MemberPageComponent
      members={data || []}
      alias={alias}
      filter={{
        subteam: {
          value: subteamFilter,
          setValue: setSubteamFilter,
          options: subteamOptions,
        },
        name: {
          value: nameFilter,
          setValue: setNameFilter,
        },
      }}
      isPageReady={pageReady(membersQueryState)}
    />
  );
};

export default Members;
