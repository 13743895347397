import { useScrollIntoView } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { YearQuarter } from "../types/project";
import { getCurrentYearQuarter } from "../utils";

const useScrollIntoYearQuarter = () => {
  // hooks for scroll into view of year quarters
  const {
    scrollIntoView: scrollIntoViewQ1,
    targetRef: scrollTargetRefQ1,
    scrollableRef: scrollableRefQ1,
  } = useScrollIntoView<HTMLDivElement, HTMLDivElement>({ axis: "x" });
  const {
    scrollIntoView: scrollIntoViewQ2,
    targetRef: scrollTargetRefQ2,
    scrollableRef: scrollableRefQ2,
  } = useScrollIntoView<HTMLDivElement, HTMLDivElement>({ axis: "x" });
  const {
    scrollIntoView: scrollIntoViewQ3,
    targetRef: scrollTargetRefQ3,
    scrollableRef: scrollableRefQ3,
  } = useScrollIntoView<HTMLDivElement, HTMLDivElement>({ axis: "x" });
  const {
    scrollIntoView: scrollIntoViewQ4,
    targetRef: scrollTargetRefQ4,
    scrollableRef: scrollableRefQ4,
  } = useScrollIntoView<HTMLDivElement, HTMLDivElement>({ axis: "x" });
  const scrollTargetRefs = [
    scrollTargetRefQ1,
    scrollTargetRefQ2,
    scrollTargetRefQ3,
    scrollTargetRefQ4,
  ];

  const [activeYearQuarter, setActiveYearQuarter] = useState<YearQuarter>();
  const currentYearQuarter = getCurrentYearQuarter();

  // this is to make sure that the referenced element exist even just after the initial render
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const assignScrollRefs = (el: HTMLDivElement) => {
    scrollableRefQ1.current = el;
    scrollableRefQ2.current = el;
    scrollableRefQ3.current = el;
    scrollableRefQ4.current = el;
  };

  useEffect(() => {
    if (!activeYearQuarter) {
      setActiveYearQuarter(currentYearQuarter);
    }
  }, [activeYearQuarter, currentYearQuarter]);

  useEffect(() => {
    if (activeYearQuarter) {
      switch (activeYearQuarter) {
        case "Q1":
          return scrollIntoViewQ1({ alignment: "end" });
        case "Q2":
          return scrollIntoViewQ2({ alignment: "end" });
        case "Q3":
          return scrollIntoViewQ3({ alignment: "end" });
        case "Q4":
          return scrollIntoViewQ4({ alignment: "end" });
      }
    }
  }, [
    activeYearQuarter,
    assignScrollRefs,
    currentYearQuarter,
    scrollIntoViewQ1,
    scrollIntoViewQ2,
    scrollIntoViewQ3,
    scrollIntoViewQ4,
  ]);

  return {
    activeYearQuarter,
    setActiveYearQuarter,
    assignScrollRefs,
    scrollTargetRefs,
  };
};

export default useScrollIntoYearQuarter;
