import React from "react";
import { isErrorNotFound, pageReady } from "../../helper";
import { ErrorCode, ErrorResponse } from "../../types/error";

import TabSectionContentComponent from "../DashboardPage/TabSectionContentComponent";
import {
  GroupType,
  PageTypes,
  PagesQueryParams,
  TabComponentProps,
} from "../../types/shared";
import { useGetPagesQuery } from "../../services";
import EntityCard from "../../components/EntityCard";
import { Entity } from "../../types/entity";
import Skeleton from "react-loading-skeleton";

const EntityTabComponent = ({ viewMode }: TabComponentProps) => {
  let params: PagesQueryParams = {
    url: "pages/",
    filter: { type: PageTypes.Entity, group_type: GroupType.Department },
  };
  const { data, ...entitiesQueryInfo } = useGetPagesQuery(params);

  if (isErrorNotFound(entitiesQueryInfo)) {
    throw new ErrorResponse("Not Found", ErrorCode.NotFound, "Not Found");
  }

  if (entitiesQueryInfo.isError) {
    throw new ErrorResponse("", ErrorCode.InternalError, "");
  }

  const entityCards = ((data?.items || []) as Entity[]).map((entity) => ({
    Component: EntityCard,
    props: {
      name: entity.name,
      download_url: entity.logo?.meta.download_url,
      short_description: entity.short_description,
      id: entity.id,
    },
  }));

  const components = !pageReady(entitiesQueryInfo)
    ? [
        {
          Component: () => <>{<Skeleton count={10} />}</>,
          props: {
            name: "",
            download_url: "",
            short_description: "",
            id: 0,
          },
        },
      ]
    : entityCards;
  return (
    <TabSectionContentComponent components={components} viewMode={viewMode} />
  );
};

export default EntityTabComponent;
