import React, { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import Skeleton from "react-loading-skeleton";
import { Post } from "../../types/post";
import NavigationContentContainer from "../../components/NavigationContentContainer";
import Button from "../../components/Button";
import { ErrorCode, ErrorResponse } from "../../types/error";
import moment from "moment";
import SanitizeHTML from "../../components/SanitizeHTML";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { defaultHelmetTitle } from "../../constants";
import Comments from "../../components/Comments";

interface PostPageComponentProps {
  isPageReady: boolean;
  post?: Post;
  isError: boolean;
  nextLink?: string;
}

const PostPageComponent = ({
  post,
  isPageReady,
  isError,
  nextLink,
}: PostPageComponentProps) => {
  if (isError) {
    throw new ErrorResponse("", ErrorCode.InternalError, "");
  }

  const authorName = useMemo(
    () => post && `${post.author.first_name}  ${post.author.last_name}`,
    [post]
  );
  const publishedDate = useMemo(
    () => post && moment(post.meta.first_published_at).format("MMM DD, YYYY"),
    [post]
  );
  const modifiedDate = useMemo(() => {
    const lastPublishedDate = moment(post?.last_published_at);

    return (
      (post &&
        !moment(post.meta.first_published_at).isSame(lastPublishedDate) &&
        lastPublishedDate.format("MMM DD, YYYY")) ||
      ""
    );
  }, [post]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{post?.title || defaultHelmetTitle}</title>
      </Helmet>
      <div className="lg:mx-44 md:mx-28 mx-4 px-2 mt-4">
        {!isPageReady && <Skeleton width="30%" />}
        {isPageReady && (
          <div className="flex gap-4">
            <div className="flex gap-4">
              <a className="clickable" href="/">
                Home
              </a>
              <div className="select-none">/</div>
            </div>
            <div className="select-none">{post?.headline}</div>
          </div>
        )}
      </div>
      <NavigationContentContainer isContentReady={isPageReady}>
        {post && isPageReady && (
          <div className="mx-auto max-w-4xl">
            <div>
              <div className="md:flex md:justify-between">
                <div className="font-bold flex items-end">
                  <div>{post.category}</div>
                </div>
                {nextLink && (
                  <div className="md:mt-0 mt-4 md:mb-0 mb-4">
                    <Button
                      styleProp="flex gap-2 items-center  bg-primary text-white font-medium px-6"
                      onClick={() => {
                        window.location.href = nextLink;
                      }}
                    >
                      Next
                      <FontAwesomeIcon icon={["fas", "arrow-right"]} />
                    </Button>
                  </div>
                )}
              </div>
              <h1>{post.headline}</h1>
              <div className="flex gap-14 mt-2">
                <div className="text-[#9EC7B9] font-light">
                  Author: {authorName}
                </div>
                <div className="text-[#9EC7B9] font-light">
                  Published: {publishedDate}
                </div>
                {modifiedDate && (
                  <div className="text-[#9EC7B9] font-light">
                    Modified: {modifiedDate}
                  </div>
                )}
              </div>
              <div className="wysiwyg-content mt-14 font-extralight">
                <SanitizeHTML html={post.content} />
              </div>
              {post.tags.length > 0 && <div className="mt-6">Tags:</div>}
              <div className="flex gap-6 mt-6">
                {post.tags.map((tag, index) => (
                  <div
                    className="bg-secondary rounded-lg px-[12px] py-[6px]"
                    key={`${tag}${index}`}
                  >
                    {tag}
                  </div>
                ))}
              </div>
            </div>
            <Comments type="post" id={post.id} />
          </div>
        )}
      </NavigationContentContainer>
    </>
  );
};

export default PostPageComponent;
