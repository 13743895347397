import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useHierarchyBreadCrumbs } from "../../hooks";
import { useGetEntityByProjectQuery, useGetProjectQuery } from "../../services";
import { ErrorCode, ErrorResponse } from "../../types/error";
import ProjectPageComponent from "./ProjectPageComponent";

const ProjectPageByEntity = () => {
  const params = useParams();
  const isDigit = /^\d+$/i;

  if (!isDigit.test(params.project || "")) {
    throw new ErrorResponse("Not Found", ErrorCode.NotFound, "Not Found");
  }

  if (!isDigit.test(params.entityId || "")) {
    throw new ErrorResponse("Not Found", ErrorCode.NotFound, "Not Found");
  }

  const { data, isLoading, isFetching, isError, error } = useGetProjectQuery(
    parseInt(params.project as string)
  );

  const {
    data: projectEntity,
    isLoading: projectEntityLoading,
    isFetching: projectEntityFetching,
    isError: projectEntityIsError,
    error: projectEntityError,
  } = useGetEntityByProjectQuery({
    entityId: params.entityId || "",
    projectId: params.project || "",
  });

  if (
    (isError && error && (error as FetchBaseQueryError).status === 404) ||
    (projectEntityIsError &&
      projectEntityError &&
      (projectEntityError as FetchBaseQueryError).status === 404)
  ) {
    throw new ErrorResponse("Not Found", ErrorCode.NotFound, "Not Found");
  }
  const entityConfig = useMemo(() => {
    return {
      name: projectEntity?.name || "",
      projectLink: `/entities/${projectEntity?.id}/projects`,
    };
  }, [projectEntity]);

  const breadCrumbs = useHierarchyBreadCrumbs(
    entityConfig.name,
    params.entityId,
    {
      title: `${entityConfig.name} Projects`,
      link: entityConfig.projectLink,
    }
  );
  return (
    <ProjectPageComponent
      breadCrumbs={breadCrumbs}
      project={data}
      isPageReady={
        !isLoading &&
        !isFetching &&
        !projectEntityFetching &&
        !projectEntityLoading
      }
      isError={isError}
    />
  );
};

export default ProjectPageByEntity;
