import React from "react";
import Select, { Props, GroupBase } from "react-select";

const SingleSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: Props<Option, IsMulti, Group>
) => (
  <Select
    {...props}
    components={{
      IndicatorSeparator: () => null,
    }}
    theme={(theme) => ({
      ...theme,
      borderRadius: 6,
      colors: {
        ...theme.colors,
        primary: "#005F3E",
      },
    })}
    styles={{
      singleValue: (provided, { data: _ }) => ({
        ...provided,
        color: "#005F3E",
        // specify a fallback color here for those values not accounted for in the styleMap
      }),
      placeholder: (base) => ({
        ...base,
        fontWeight: 300,
      }),
    }}
  />
);

export default SingleSelect;
