import React from "react";
import { PageResult, PreContent, QueryResult } from "../../types/shared";
import Skeleton from "react-loading-skeleton";
import NavigationContentContainer from "../../components/NavigationContentContainer";
import { Entity } from "../../types/entity";
import SanitizeHTML from "../../components/SanitizeHTML";
import EntityCard from "../../components/EntityCard";

const GroupsComponent = ({
  data,
  isLoading,
  isFetching,
  isSuccess,
  isError,
  preContent,
}: QueryResult<PageResult<Entity & { link?: string }>> & {
  preContent?: PreContent;
}) => {
  const groups = data?.items;
  const isGroupsReady = !isError && !isLoading && !isFetching && isSuccess;

  return (
    <NavigationContentContainer
      isContentReady={isGroupsReady}
      header={preContent ? preContent.header || "" : "Meet the teams"}
    >
      {preContent && (
        <>
          {isGroupsReady ? (
            <>
              <div className="my-16 text-justify font-montserrat wysiwyg-content">
                <SanitizeHTML html={preContent.description || ""} />
              </div>
              <h1>Meet the teams</h1>
            </>
          ) : (
            <Skeleton count={5} width="80vw" />
          )}
        </>
      )}
      <div className="grid gap-6 lg:grid-cols-[repeat(auto-fill,_minmax(345px,_1fr))] md:grid-cols-2 grid-cols-1 mt-8">
        {isGroupsReady ? (
          groups?.map((group) => (
            <EntityCard
              key={group.id}
              id={group.id}
              name={group.name}
              short_description={group.short_description}
              link={group.link}
              download_url={group.logo?.meta.download_url}
            />
          ))
        ) : (
          <Skeleton count={5} width="80vw" />
        )}
      </div>
    </NavigationContentContainer>
  );
};

export default GroupsComponent;
