import React, { useMemo } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

const PreviewCallbackPage = () => {
  const [searchParams] = useSearchParams();
  const contentType = searchParams.get("content_type");
  const token = searchParams.get("token");

  const contentTypeModel = useMemo(
    () => contentType?.split(".").at(-1)?.toLowerCase(),
    [contentType]
  );

  const subpath = useMemo(() => {
    switch (contentTypeModel) {
      case "goal":
        return "goals/";
      case "project":
        return "projects/";
      case "custompage":
        return "custom/";
      case "post":
        return "post/";
      case "customcardpage":
        return "custom_card/";
      default:
        return "teams/";
    }
  }, [contentTypeModel]);

  return (
    <Navigate
      replace
      to={`/draft/preview/${subpath}?content_type=${contentType}&token=${token}`}
    />
  );
};

export default PreviewCallbackPage;
