import { useMemo } from "react";
import useAuthenticate from "../pages/PrivateRoute/useAuthenticate";
import { useGetMembersQuery } from "../services";

const useMemberInfo = () => {
  const user = useAuthenticate();
  const params = {
    filter: { email: user?.data?.email },
  };
  const { data } = useGetMembersQuery(
    params ?? user?.data?.email !== undefined
  );
  const memoize = useMemo(() => data, [data]);
  const memberProfile = memoize ? memoize[0] : null;
  return memberProfile;
};

export default useMemberInfo;
