import React, { ReactNode, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion, AnimatePresence } from "framer-motion";

interface SingleAccordionProps {
  header: ReactNode;
  children: ReactNode;
  className?: string;
  unexpandedHeaderClass?: string;
  expandedHeaderClass?: string;
  generalClass?: string;
}

const SingleAccordion = ({
  header,
  children,
  className,
  expandedHeaderClass,
  unexpandedHeaderClass,
  generalClass,
}: SingleAccordionProps) => {
  const [expand, setExpand] = useState(false);

  const toBeUsedHeaderCN = useMemo(
    () => (expand ? expandedHeaderClass : unexpandedHeaderClass),
    [expand, expandedHeaderClass, unexpandedHeaderClass]
  );

  return (
    <div className={`${className || ""}`}>
      <div
        className={`w-full px-8 py-5 rounded-md font-bold clickable flex justify-between ${
          generalClass || ""
        } ${toBeUsedHeaderCN || ""}`}
        onClick={() => {
          setExpand((prev) => !prev);
        }}
      >
        <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
          {header}
        </div>

        <motion.div
          className="flex items-center"
          key={expand ? "plus" : "minus"}
          initial={{
            rotate: expand ? -90 : 90,
          }}
          animate={{
            zIndex: 1,
            rotate: 0,
            transition: {
              type: "tween",
              duration: 0.2,
              ease: "circOut",
            },
          }}
          exit={{
            zIndex: 0,
            rotate: expand ? -90 : 90,
            transition: {
              type: "tween",
              duration: 0.2,
              ease: "circIn",
            },
          }}
        >
          {
            <FontAwesomeIcon
              className="text-[22px]"
              icon={expand ? ["fas", "minus"] : ["fas", "plus"]}
            />
          }
        </motion.div>
      </div>
      <AnimatePresence mode="wait">
        {expand && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{
              height: "auto",
              opacity: 1,
              transition: {
                height: {
                  duration: 0.4,
                },
                opacity: {
                  duration: 0.25,
                  delay: 0.15,
                },
              },
            }}
            exit={{
              height: 0,
              opacity: 0,
              transition: {
                height: {
                  duration: 0.4,
                },
                opacity: {
                  duration: 0.25,
                },
              },
            }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

SingleAccordion.defaultProps = {
  unexpandedHeaderClass: "bg-boxGray",
  expandedHeaderClass: "text-white bg-textPrimary",
};

export default SingleAccordion;
