import React, { FC } from "react";
import Card from "../../components/Card";
import { CustomCardType } from "../../types/customCardPage";

interface CustomCardComponentProps {
  card: CustomCardType;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const CustomCardComponent: FC<CustomCardComponentProps> = ({ card }) => {
  const { thumbnail, title, description } = card;

  return (
    <Card
      image={thumbnail.url}
      imageAlt={thumbnail.title}
      title={title}
      description={description}
      {...(card.link && {
        onClick: () => {
          window.location.href = card.link || "";
        },
      })}
    />
  );
};

export default CustomCardComponent;
