import React, { useEffect, useState } from "react";
import {
  useGetBugReportsQuery,
  useGetServiceRequestsQuery,
} from "../../services";
import ServiceRequestFilters from "./ServiceRequestFilters";
import {
  CustomFilterType,
  FiledRequest,
  FilterAssignee,
  FilterButtons,
  FilterStatus,
  MemberFilter,
  TotalFiledType,
} from "../../types/serviceRequest";
import ServiceRequestTable from "./ServiceRequestTable";
import moment from "moment";
import lowerFirst from "lodash/lowerFirst";
const dateFormat = "YYYY-MM-DD hh:mm:ss";

const ServiceRequestPage = () => {
  const { data: serviceRequestData } = useGetServiceRequestsQuery(null);
  const { data: bugReportData } = useGetBugReportsQuery(null);
  const [trimmedServiceRequests, setTrimmedServiceRequests] =
    useState<FiledRequest[]>();
  const [trimmedBugReports, setTrimmedBugReports] = useState<FiledRequest[]>();
  const [members, setMembers] = useState<MemberFilter[]>([]);
  const [allRequestData, setAllRequestData] = useState<FiledRequest[]>();
  const [filteredData, setFilteredData] = useState<FiledRequest[]>();
  const [customFilteredData, setCustomFilteredData] =
    useState<FiledRequest[]>();
  const [totalFiled, setTotalFiled] = useState<TotalFiledType>({
    all: 0,
    bugs: 0,
    requests: 0,
  });
  const [assignees, setAssigness] = useState<string[]>([]);

  const [customFiltered, setCustomFiltered] = useState<boolean>(false);
  const [mappedStatus, setMapStatus] = useState<FilterStatus>(
    FilterStatus.Open
  );

  /**
   * UseEffect for Transforming
   * SERVICE REQUEST data to fit
   * in Dashboard Column
   */
  useEffect(() => {
    const transformedServiceRequest = serviceRequestData?.map(
      ({
        id,
        idea,
        status,
        avail_service,
        integration_id,
        date_created,
        assigned_to,
        user,
      }) => ({
        title: idea,
        activity: avail_service,
        date_created: date_created
          ? moment(date_created).format(dateFormat)
          : "",
        filed_by: user ? `${user.first_name} ${user.last_name}` : "",
        type: FilterButtons.Requests,
        status,
        assigned_to,
        user: user ? user.id : undefined,
        id,
        integration_id,
      })
    );
    setTrimmedServiceRequests(transformedServiceRequest);
  }, [serviceRequestData]);

  /**
   * UseEffect for Transforming
   * BUG REPORT data to fit
   * in Dashboard Column
   */
  useEffect(() => {
    const transformedBugReport = bugReportData?.map(
      ({
        summary,
        status,
        integration_id,
        id,
        date_created,
        user,
        assigned_to,
      }) => ({
        title: summary,
        activity: "Bug Report",
        date_created: date_created
          ? moment(date_created).format(dateFormat)
          : "",
        filed_by: user ? `${user.first_name} ${user.last_name}` : "",
        type: FilterButtons.BugReports,
        status,
        assigned_to,
        id,
        integration_id,
        user: user ? user.id : undefined,
      })
    );
    setTrimmedBugReports(transformedBugReport);
  }, [bugReportData]);

  /**
   * UseEffect for combining
   * SERVICE REQUEST and BUG REPORT
   * into a DASHBOARD Data
   */
  useEffect(() => {
    if (
      Array.isArray(trimmedServiceRequests) &&
      Array.isArray(trimmedBugReports)
    ) {
      setAllRequestData([...trimmedServiceRequests, ...trimmedBugReports]);
      //Default filttered data by Status:Open
      let filterByOpenServiceRequests = filterByOpenOrClosed(mappedStatus, [
        ...trimmedServiceRequests,
      ])!;

      let filterByOpenBugRepoerts = filterByOpenOrClosed(mappedStatus, [
        ...trimmedBugReports,
      ])!;

      setFilteredData([
        ...filterByOpenServiceRequests,
        ...filterByOpenBugRepoerts,
      ]);

      setTotalFiled({
        all:
          filterByOpenServiceRequests.length + filterByOpenBugRepoerts.length,
        requests: filterByOpenServiceRequests.length,
        bugs: filterByOpenBugRepoerts.length,
      });

      const membersData = [...trimmedServiceRequests, ...trimmedBugReports].map(
        ({ filed_by, user }) => ({
          filed_by,
          id: user,
        })
      );
      const removeDuplicateMember = membersData.filter(
        (obj1, i, arr) => arr.findIndex((obj2) => obj2.id === obj1.id) === i
      );
      setMembers(removeDuplicateMember.sort());

      const assigneeData = [
        ...trimmedServiceRequests,
        ...trimmedBugReports,
      ].map(({ assigned_to }) => {
        if (assigned_to) {
          return formatAssignTo(assigned_to.replace(FilterAssignee.TBA, ""));
        }
        return [];
      });
      let assigneeDataFlat = assigneeData.flat();
      let removeAssigneeDuplicates = assigneeDataFlat.filter(
        (item, index, data) => data.indexOf(item) === index
      );

      setAssigness([
        FilterAssignee.All,
        FilterAssignee.TBA,
        ...removeAssigneeDuplicates.sort(),
      ]);
    }
  }, [trimmedServiceRequests, trimmedBugReports]);

  useEffect(() => {
    if (customFiltered) {
      if (Array.isArray(customFilteredData)) {
        const requests = customFilteredData.filter(
          (data) => data.type === FilterButtons.Requests
        );
        const bugs = customFilteredData.filter(
          (data) => data.type === FilterButtons.BugReports
        );
        setTotalFiled({
          all: customFilteredData.length,
          requests: requests.length,
          bugs: bugs.length,
        });
      }
    } else {
      let filterByMappedStatusServiceRequest =
        trimmedServiceRequests &&
        filterByOpenOrClosed(mappedStatus, [...trimmedServiceRequests]);

      let filterByMappStatusBugReports =
        trimmedBugReports &&
        filterByOpenOrClosed(mappedStatus, [...trimmedBugReports]);

      setTotalFiled({
        all:
          filterByMappedStatusServiceRequest && filterByMappStatusBugReports
            ? [
                ...filterByMappedStatusServiceRequest,
                ...filterByMappStatusBugReports,
              ].length
            : 0,
        requests: filterByMappedStatusServiceRequest
          ? filterByMappedStatusServiceRequest.length
          : 0,
        bugs: filterByMappStatusBugReports
          ? filterByMappStatusBugReports.length
          : 0,
      });
    }
  }, [customFiltered, customFilteredData]);

  const filterDataByUser = (
    user: number | undefined,
    data: FiledRequest[] | undefined
  ) => {
    if (user) return data?.filter((data) => data.user === user);
    return data;
  };

  const ButtonFilter = (serviceType: FilterButtons | undefined) => {
    if (customFiltered && Array.isArray(customFilteredData)) {
      switch (serviceType) {
        case "Requests":
          setFilteredData(
            customFilteredData.filter((data) => data.type === "Requests")
          );
          break;
        case "Bug Reports":
          setFilteredData(
            customFilteredData.filter((data) => data.type === "Bug Reports")
          );
          break;
        case "All":
          setFilteredData(customFilteredData);
          break;
      }
    } else {
      let filterByMappedStatusServiceRequest =
        trimmedServiceRequests &&
        filterByOpenOrClosed(mappedStatus, [...trimmedServiceRequests]);

      let filterByMappedStatusBugReport =
        trimmedBugReports &&
        filterByOpenOrClosed(mappedStatus, [...trimmedBugReports]);

      switch (serviceType) {
        case "Requests":
          setFilteredData(filterByMappedStatusServiceRequest);
          break;
        case "Bug Reports":
          setFilteredData(filterByMappedStatusBugReport);
          break;
        case "All":
          setFilteredData([
            ...(filterByMappedStatusServiceRequest ?? []),
            ...(filterByMappedStatusBugReport ?? []),
          ]);
          break;
      }
    }
  };

  const CustomFilter = (filter: CustomFilterType) => {
    const { user, filedBy, serviceType, assignedTo, status } = filter;
    setMapStatus(status);

    if (
      filedBy === "All" &&
      serviceType === FilterButtons.All &&
      assignedTo === FilterAssignee.All &&
      status === FilterStatus.All
    ) {
      let filterObj = filterDataByUser(user, allRequestData);

      status &&
        (filterObj = filterByOpenOrClosed(status, filterObj) || filterObj);

      setCustomFiltered(false);
      setFilteredData(filterObj);
    } else {
      const serviceTypeObj = {
        [FilterButtons.All]: allRequestData,
        [FilterButtons.Requests]: trimmedServiceRequests,
        [FilterButtons.BugReports]: trimmedBugReports,
      };
      setCustomFiltered(true);

      let filterObj = filterDataByUser(user, serviceTypeObj[serviceType]);

      status === FilterStatus.Open || status === FilterStatus.Closed
        ? (filterObj = filterByOpenOrClosed(status, filterObj) || filterObj)
        : (filterObj = filterByStatus(status, filterObj) || filterObj);

      assignedTo !== undefined &&
        assignedTo !== FilterAssignee.All &&
        (filterObj = filterByAssignee(assignedTo, filterObj) || filterObj);

      setFilteredData(filterObj);
      setCustomFilteredData(filterObj);
    }
  };

  const findRequestById = (
    id: number | undefined,
    type: FilterButtons | undefined
  ) => {
    if (id) {
      switch (type) {
        case FilterButtons.Requests:
          return serviceRequestData?.find((service) => service.id === id);
        case FilterButtons.BugReports:
          return bugReportData?.find((bug) => bug.id === id);
      }
    }
    return null;
  };

  const filterByStatus = (
    status: FilterStatus,
    data: FiledRequest[] | undefined
  ) => {
    if (data && status !== FilterStatus.All)
      if (status === FilterStatus.Done)
        return data.filter(
          (data) =>
            data.status === lowerFirst(FilterStatus.Done) ||
            data.status === lowerFirst(FilterStatus.Cancelled) ||
            data.status === lowerFirst(FilterStatus.Closed) ||
            data.status === lowerFirst(FilterStatus.Shipped)
        );
      else return data.filter((data) => data.status === lowerFirst(status));
    return data;
  };

  const filterByAssignee = (
    assignee: string,
    data: FiledRequest[] | undefined
  ) => {
    if (data)
      return data.filter((data) => {
        const assignees = formatAssignTo(data.assigned_to!);
        return assignees.includes(assignee);
      });
    return data;
  };

  const filterByOpenOrClosed = (
    status: FilterStatus,
    data: FiledRequest[] | undefined
  ) => {
    if (data && status !== FilterStatus.All)
      return data.filter((data) => {
        const statuses = [
          lowerFirst(FilterStatus.Done),
          lowerFirst(FilterStatus.Cancelled),
          lowerFirst(FilterStatus.Shipped),
          lowerFirst(FilterStatus.Closed),
        ].includes(data.status!);

        return status === FilterStatus.Open ? !statuses : statuses;
      });
    return data;
  };

  const formatAssignTo = (assignedTo: string): string[] => {
    const assigneesToArray = assignedTo.split(",");
    const trimmedAssignees = assigneesToArray.filter((e) => e.trim());

    return trimmedAssignees;
  };

  return (
    <div className="bg-boxGray pt-11 pb-[700px]">
      <div className="flex justify-between lg:mx-44 md:mx-28 mx-11 bg-white">
        <div className="p-5 p-b10 w-full h-full">
          <ServiceRequestFilters
            all={totalFiled.all}
            requests={totalFiled.requests}
            bug={totalFiled.bugs}
            mentions={0}
            membersFilter={members}
            onButtonFilter={(serviceType: FilterButtons) =>
              ButtonFilter(serviceType)
            }
            onCustomFilter={(filter: CustomFilterType) => CustomFilter(filter)}
            assigness={assignees}
          />
          <ServiceRequestTable
            filedRequests={filteredData}
            fetchRowData={findRequestById}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceRequestPage;
