import React from "react";
import { useSearchParams } from "react-router-dom";
import { PagePreviewQueryParams } from "../../types/shared";
import { useGetPreviewQuery } from "../../services";
import PostPageComponent from "./PostPageComponent";
import { Post } from "../../types/post";
import { isErrorNotFound, pageReady } from "../../helper";
import { ErrorCode, ErrorResponse } from "../../types/error";

const PostPagePreview = () => {
  const [searchParams] = useSearchParams();

  let queryParams: PagePreviewQueryParams = {
    content_type: searchParams.get("content_type"),
    token: searchParams.get("token"),
  };

  const { data, ...retrievedPost } = useGetPreviewQuery(queryParams);

  if (isErrorNotFound(retrievedPost)) {
    throw new ErrorResponse("Not Found", ErrorCode.NotFound, "Not Found");
  }

  return (
    <PostPageComponent
      isPageReady={pageReady(retrievedPost)}
      post={data as Post | undefined}
      isError={retrievedPost.isError}
    />
  );
};

export default PostPagePreview;
