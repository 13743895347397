import { IdeaDataType } from "./types/idea";
import { Member } from "./types/member";
import { ReportDataType } from "./types/report";
import { ServiceDataType } from "./types/service";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import NoProfile from "./assets/images/no_profile.png";

export const getMonthName = (
  monthNum: number,
  mode: "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined
) => {
  const date = new Date();

  date.setMonth(monthNum);

  return date.toLocaleString("en-US", { month: mode });
};

export const ValidateObjectProperties = (
  obj: Object,
  ignore: string[]
): boolean => {
  return Object.entries(obj).some(([k, v]) => {
    return (
      (v === undefined ||
        v === null ||
        v === "" ||
        (Array.isArray(v) && v.length === 0)) &&
      !ignore.includes(k)
    );
  });
};

type newObjType = { [key: string]: string | object };

export const parseForm = (
  obj: Partial<IdeaDataType | ServiceDataType | ReportDataType | boolean>
) => {
  const newObj: newObjType = {};
  let kV = Object.entries(obj);
  for (const [k, v] of kV) {
    let val = v;
    if (
      ((typeof val === "object" && val !== null) || val === undefined) &&
      k !== "files"
    ) {
      if (val.constructor === Array && val.length > 0) {
        val = val.join(", ");
      } else val = val.value;
    }
    if (val !== null && val !== undefined) newObj[k] = val;
  }
  return newObj;
};

export const getCookie = (name: string): string => {
  function escape(s: string) {
    return s.replace(/([.*+?^$(){}|[\]/\\])/g, "\\$1");
  }
  let match = document.cookie.match(
    RegExp("(?:^|;\\s*)" + escape(name) + "=([^;]*)")
  );
  return (match && match[1]) || "";
};
export const pageReady = ({
  isError,
  isLoading,
  isFetching,
  isSuccess,
}: {
  isError: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
}) => !isError && !isLoading && !isFetching && isSuccess;

export const isErrorNotFound = ({
  isError,
  error,
}: {
  isError?: boolean;
  error?: FetchBaseQueryError | SerializedError;
}) => {
  if (error && "status" in error) {
    return isError && error && error.status === 404;
  }
  return false;
};

export const generateId = () => crypto.randomUUID();

export const copyToClipboard = (text: string) => {
  const { clipboard } = navigator;
  if (clipboard && text) {
    clipboard.writeText(text);
  }
};

export const memberData = (member: Member | null) => {
  if (member)
    return {
      ...member,
      profile_pic: {
        ...member.profile_pic,
        url: member.profile_pic?.url ?? NoProfile,
        title: member.profile_pic?.title ?? "an image of this person",
      },
    };
};

export const isValidJSON = (str: string) => {
  if (/^\s*(\{|\[)/.test(str)) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }
};
