import React, { useMemo } from "react";
import { useGetNextPostQuery, useGetPostQuery } from "../../services";
import { useParams } from "react-router-dom";
import PostPageComponent from "./PostPageComponent";
import { ErrorCode, ErrorResponse } from "../../types/error";
import { isErrorNotFound, pageReady } from "../../helper";
import { PostCategory } from "../../types/post";

const PostPage = () => {
  const params = useParams();
  const { data, ...retrievedPostInfo } = useGetPostQuery(
    Number(params.postId || "")
  );

  const nextPostQueryParams = {
    post_id: data?.id || 0,
    post_category: data?.category || PostCategory.Announcement,
  };

  const { data: nextPost, ...nextPostQueryInfo } = useGetNextPostQuery(
    nextPostQueryParams,
    { skip: !data }
  );

  const nextLink = useMemo(() => {
    if (!nextPost || !data || nextPost?.id == null) {
      return "";
    }

    return `/posts/${nextPost.id}`;
  }, [nextPost, data]);

  if (
    isErrorNotFound(retrievedPostInfo) ||
    isErrorNotFound(nextPostQueryInfo)
  ) {
    throw new ErrorResponse("Not Found", ErrorCode.NotFound, "Not Found");
  }
  const isPageReady =
    pageReady(retrievedPostInfo) && pageReady(nextPostQueryInfo);

  return (
    <PostPageComponent
      isPageReady={isPageReady}
      post={data}
      isError={retrievedPostInfo.isError || nextPostQueryInfo.isError}
      nextLink={nextLink}
    />
  );
};

export default PostPage;
