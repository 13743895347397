import React from "react";

interface ProgressBarComponent {
  completionRate: number;
  className?: string;
  completedColorClass?: string;
  incompleteColorClass?: string;
}

const ProgressBar = ({
  completionRate,
  className,
  completedColorClass = "bg-primary",
  incompleteColorClass = "bg-secondary",
}: ProgressBarComponent) => (
  <div
    className={`mb-[1px] w-full h-4 rounded-full ${
      incompleteColorClass || ""
    } ${className || ""}`}
  >
    <div
      className={`h-4 rounded-full ${completedColorClass} ${
        completionRate > 92
          ? "rounded-full"
          : "rounded-tl-[20px] rounded-tr-[0px] rounded-br-[0px] rounded-bl-[20px]"
      }`}
      style={{
        width: `${completionRate}%`,
      }}
    />
  </div>
);

export default ProgressBar;
