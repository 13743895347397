import React from "react";
import { useGetPagesQuery } from "../../services";
import { Application } from "../../types/application";
import { PageResult, PagesQueryParams, PageTypes } from "../../types/shared";
import FaveAppsComponent from "./FaveAppsComponent";

export interface FaveAppsPageProps {
  scrollToAllApps: Function;
}

const FaveAppsPage = ({ scrollToAllApps }: FaveAppsPageProps) => {
  const params: PagesQueryParams = {
    url: "pages/",
    filter: {
      type: PageTypes.Application,
      status: "Active",
      order: "name",
    },
  };
  const { data, isLoading, isSuccess, isFetching, isError } =
    useGetPagesQuery(params);

  return (
    <FaveAppsComponent
      data={data as PageResult<Application>}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isFetching={isFetching}
      isError={isError}
      scrollToAllApps={scrollToAllApps}
    />
  );
};

export default FaveAppsPage;
