import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import EntityPageComponent from "./EntityPageComponent";
import { Entity } from "../../types/entity";
import { PagePreviewQueryParams } from "../../types/shared";
import { useGetPreviewQuery } from "../../services";
import { useEntityPage } from "./hooks";

const EntityPagePreview = () => {
  const [searchParams] = useSearchParams();
  const params = useParams();

  let queryParams: PagePreviewQueryParams = {
    content_type: searchParams.get("content_type"),
    token: searchParams.get("token"),
  };

  const { data, error, isFetching, isLoading, isSuccess, isError } =
    useGetPreviewQuery(queryParams);

  const { menuItems, CurrentActiveComponent, currentMenuPath } = useEntityPage({
    params,
    entity: data as Entity | undefined,
    queryParams,
    isPageReady: !isFetching && !isLoading,
  });

  return (
    <EntityPageComponent
      data={data as Entity | undefined}
      error={error}
      isFetching={isFetching}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      menu={menuItems}
      CurrentActiveComponent={CurrentActiveComponent}
      url_path={currentMenuPath}
    />
  );
};

export default EntityPagePreview;
