import React, { FunctionComponent, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate } from "react-router-dom";
import HeroSection from "../../components/HeroSection";
import { Helmet } from "react-helmet-async";
import { BreadCrumb, PageProps, QueryResult } from "../../types/shared";
import { Entity } from "../../types/entity";
import { ErrorCode, ErrorResponse } from "../../types/error";
import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { generateId } from "../../helper";
import { defaultHelmetTitle } from "../../constants";

export interface EntityPageComponentProps extends QueryResult<Entity> {
  CurrentActiveComponent: FunctionComponent<PageProps>;
  menu: {
    id: string;
    title: string;
    isActive: boolean;
    link: string;
  }[];
  activeMenuClasses?: string;
  inActiveMenuClasses?: string;
  contentType?: string;
  previewToken?: string;
  breadCrumbs?: { data: BreadCrumb[]; ready: boolean };
  url_path?: string;
}

const EntityPageComponent = ({
  data,
  isLoading,
  isFetching,
  isError,
  isSuccess,
  menu,
  activeMenuClasses,
  inActiveMenuClasses,
  CurrentActiveComponent,
  breadCrumbs,
  url_path,
}: EntityPageComponentProps) => {
  const retrieving = isLoading || isFetching;
  const isPageReady = !retrieving && !isError && isSuccess;
  const navigate = useNavigate();

  if (!retrieving && !data) {
    throw new ErrorResponse("", ErrorCode.NotFound, "");
  }

  const breadCrumbsData = useMemo(
    () =>
      breadCrumbs && (
        <div className="lg:mx-44 md:mx-28 mx-11 py-4">
          {!breadCrumbs.ready && <Skeleton width="30%" />}
          {breadCrumbs.ready && (
            <div className="flex gap-14">
              <div
                className="flex gap-2 items-center clickable"
                onClick={() => navigate(-1)}
              >
                <FontAwesomeIcon icon={["fas", "arrow-left"]} /> Back
              </div>
              <div className="flex gap-2">
                {breadCrumbs.data.map((elem) => (
                  <div className="flex gap-4" key={generateId()}>
                    <Link className="clickable last:select-none" to={elem.link}>
                      {elem.title}
                    </Link>
                    <div className="select-none">/</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ),
    [breadCrumbs, navigate]
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data?.title ?? defaultHelmetTitle}</title>
      </Helmet>
      {breadCrumbsData}
      <div>
        <HeroSection
          header={data?.name ?? <Skeleton />}
          body={data?.description ?? <Skeleton count={6} />}
          image={{
            src: data?.hero_image?.meta.download_url,
            title: data?.hero_image?.title,
          }}
        />

        {(isPageReady && (
          <div className="lg:mx-44 md:mx-28 mx-11 md:mt-48 mt-10 flex p-4 bg-secondary rounded-[10px] overflow-x-auto whitespace-nowrap scrollbar-thin scrollbar-thumb-textPrimary scrollbar-track-secondary scrollbar-thumb-rounded-[6px] space-x-4">
            {menu.map((item) => {
              return (
                <Link key={item.id} to={item.link}>
                  <Button
                    styleProp={
                      item.isActive ? activeMenuClasses : inActiveMenuClasses
                    }
                  >
                    {item.title}
                  </Button>
                </Link>
              );
            })}
          </div>
        )) || (
          <Skeleton
            className="lg:mx-44 md:mx-28 mx-11 md:mt-48 mt-10 flex p-4 bg-secondary rounded-[10px]"
            width="50%"
          />
        )}

        {(isPageReady && (
          <CurrentActiveComponent
            entityId={data?.id}
            alias={data?.alias}
            preContent={{
              header: data?.pre_teams_header,
              description: data?.pre_teams_description,
            }}
            url_path={url_path}
          />
        )) || (
          <Skeleton
            className="lg:mx-44 md:mx-28 mx-11 flex  bg-secondary rounded-[10px]"
            width="50%"
            count={10}
          />
        )}
      </div>
    </>
  );
};

EntityPageComponent.defaultProps = {
  activeMenuClasses: "bg-primary text-white",
  inActiveMenuClasses: "bg-white",
};

export default EntityPageComponent;
